import React, { useEffect, useState, useRef, CSSProperties } from "react";
import styles from "./backgroundpicker.module.scss";
import PickerSelection from "./PickerSelection";


export type PickerSelectionListProps = {
  onSelection?: (selection: string, type: SelectionType) => void;
  selectionList?: string[];
  type?: SelectionType;
  currentSelection?: string;
};

const PickerSelectionList = ({selectionList, type = "background", onSelection, currentSelection}: PickerSelectionListProps) => {

  return (
    <div className={styles['picker-selection-list']}>
        {selectionList && selectionList.map((selection, i) => {
            return <PickerSelection selection={selection} type={type} isSelected={selection === currentSelection} onSelection={onSelection}/>
        })}
    </div>
  );
};

export default PickerSelectionList;
