import React, { ReactNode } from "react";
import styles from "./organisationpublicpage.module.scss";

type TextSectionProps = {
  text?: string;
  title: string | ReactNode;
  children?: ReactNode;
};
const TextSection = React.forwardRef(
  (
    { text, title, children }: TextSectionProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <section
        className={styles["text-section"]}
        data-cy="text-section"
        ref={ref}
      >
        <h2>{title}</h2>
        {children ? children :
            <>
              {text && (
                  <p
                      dangerouslySetInnerHTML={{
                        __html: text
                            .replace(/\r\n\r\n/g, "</p><p>")
                            .replace(/\n\n/g, "</p><p>"),
                      }}
                      className={styles['text']}/>
                  )}
            </>
        }
      </section>
    );
  }
);

export default TextSection;
