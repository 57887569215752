import { HaiaCard, Spinner, ProfileImage } from "@haia/haia-components";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import tinycolor from "tinycolor2";
import { useGetUser } from "../../../../API/profile";
import styles from "../organisationpublicpage.module.scss";

type MemberProps = {
  member?: API_Organisation_Member;
  cookie: string;
};

const Member = ({ member, cookie }: MemberProps) => {

    const {user, fetchUser, userSocials, fetchUserSocials, userLoading} = useGetUser({cookie: cookie})

    const [showHaiaCard, setShowHaiaCard] = useState(false);


    useEffect(() => {
    if (member?.account_id) {
        fetchUser(member.account_id);
    }
    }, [member]);



    const hideCard = () => {
      setShowHaiaCard(false);
    };
    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if(user){
            setShowHaiaCard(true);
        }
    };

    const getMemberName = () => {
        if(user?.display_name){
            return user.display_name
        } else if(user?.first_name){
            return `${user.first_name} ${user.last_name}`
        }
    };

    const getMemberSubtitle = () => {
      if (user?.short_bio) {
        return user.short_bio
      }
    };

  return (
    <>
      {user && (
        <>
          <div
            className={`${styles["member"]} ${user ? styles["clickable"] : ""}`}
            onClick={handleClick}
          >
            {!userLoading ? (
              <>
                <ProfileImage image={user?.profile_pic} displayName={user.display_name} backgroundKey={user.account_id} />
                <span className={styles["member-name"]}>{getMemberName()}</span>
                <span className={styles["role-info"]}>
                  {getMemberSubtitle()}
                </span>
                {showHaiaCard && (
                  <HaiaCard
                    cardDetails={{
                      id: user.account_id?.toString(),
                      title: getMemberName(),
                      image: user.profile_pic,
                      subtitle: user.short_bio,
                      ...(user.bio && { about: user.bio }),
                      socials: {
                        facebook: userSocials?.facebook_url,
                        twitter: userSocials?.twitter_url,
                        instagram: userSocials?.instagram_url,
                        linkedIn: userSocials?.linkedin_url,
                      },
                      contacts: {
                        email: user.contact_email,
                        website: user.website,
                      },
                      backgroundColor: user.haia_card_colour
                        ? user.haia_card_colour
                        : "#FFF",
                      tags: user?.hobbies?.map((val) => {
                        return val.value;
                      }),
                      size: "small",
                    }}
                    hideCard={hideCard}
                  />
                )}
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Member;
