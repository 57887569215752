import { useRouteError } from "react-router-dom";
import React from "react";
import ErrorStyles from '../CSS-Modules/error.module.css';
import error from "../Images/error.png";
import facebook from "../Images/iconFacebook.png";
import twitter from "../Images/iconTwitter.png";
import linkedin from "../Images/iconLinkedIn.png";
import instagram from "../Images/iconInstagram.png";
import logo from "../Images/error-logo.png";


const ErrorPage = () => {

    return (
      <div className={ErrorStyles.body}>
        <div
          className={`${ErrorStyles["container"]} ${ErrorStyles["col-12"]}${ErrorStyles["col-s-12"]}`}
        >
          <div
            className={`${ErrorStyles["vertical-center"]} ${ErrorStyles["col-12"]} ${ErrorStyles["col-s-12"]}`}
          >
            <div className={ErrorStyles["row"]}>
              <div
                className={`${ErrorStyles["col-12"]} ${ErrorStyles["col-s-12"]}`}
              >
                <img
                  className={ErrorStyles["logoImg"]}
                  src={logo}
                  title="Haia Logo"
                ></img>
                <br></br>

                <img
                  className={ErrorStyles["errorImg"]}
                  src={error}
                  title="Error"
                ></img>

                <h1 className={ErrorStyles.h1}>Oops, sorry about that!</h1>
              </div>
              <div
                className={`${ErrorStyles["col-12"]} ${ErrorStyles["col-s-12"]}`}
              >
                <p>
                  Something went wrong. Go back to the{" "}
                  <a
                    href="https://www.haia.live/"
                    rel="noopener nofollow"
                    className={ErrorStyles.link}
                  >
                    homepage
                  </a>
                  ,<br></br>or have a look at our socials to keep up to data
                  with Haia's status...
                </p>
              </div>
              <div
                className={`${ErrorStyles["socials"]} ${ErrorStyles["col-12"]} ${ErrorStyles["col-s-12"]}`}
              >
                <ul className={ErrorStyles.list}>
                  <li>
                    <a
                      href="https://twitter.com/HaiaOfficial"
                      rel="noopener nofollow"
                      className={ErrorStyles.link}
                    >
                      <img src={twitter} title="Twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/haiaofficialuk"
                      rel="noopener nofollow"
                      className={ErrorStyles.link}
                    >
                      <img src={facebook} title="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/haiaofficialuk/"
                      rel="noopener nofollow"
                      className={ErrorStyles.link}
                    >
                      <img src={instagram} title="Instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/haiaofficial"
                      rel="noopener nofollow"
                      className={ErrorStyles.link}
                    >
                      <img src={linkedin} title="LinkedIn" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ErrorPage;
