import axios, { AxiosRequestConfig } from "axios";
import { useCallback, useEffect, useState } from "react";
import { formatURL } from "../hooks/actions";

//@ts-ignore
const { EVENT_API_URL } = window["runConfig"] ? window["runConfig"] : "https://organisations.dev.haia.live/api";

type EventProps = {
  cookie: string;
};

export const useEvent = ({ cookie }: EventProps) => {


  const [acceptInviteLoading, setAcceptInviteLoading] = useState<boolean>();
  const [acceptInviteError, setAcceptInviteError] = useState();

  const [event, setEvent] = useState<API_Event>()
  const [eventLoading, setEventLoading] = useState<boolean>()
  const [eventError, setEventError] = useState()

  const [declineInviteLoading, setDeclineInviteLoading] = useState<boolean>()
  const [declineInviteError, setDeclineInviteError] = useState()

  const [sponsorInviteLoading, setSponsorInviteLoading] = useState<boolean>();
  const [sponsorInviteError, setSponsorInviteError] = useState();
  const [sponsorInvite, setSponsorInvite] = useState<API_Sponsor_Invite>()

  const [exhibitorInviteLoading, setExhibitorInviteLoading] = useState<boolean>();
  const [exhibitorInviteError, setExhibitorInviteError] = useState();
  const [exhibitorInvite, setExhibitorInvite] = useState<API_Sponsor_Invite>();

  const [userEvents, setUserEvents] = useState<API_Event[]>()
  const [userEventsLoading, setUserEventsLoading] = useState<boolean>()
  const [userEventsError, setUserEventsError] = useState()


  const acceptSponsorInvite = useCallback(
    (event_id: string, org_id: string, invite_id: number) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

       const data = {
         event_id: parseInt(event_id),
         org_id,
         id: invite_id
       };

      setAcceptInviteLoading(true);
      axios
        .put(`${EVENT_API_URL}/event/${event_id}/invite/sponsor`, data, config)
        .catch((err) => {
          setAcceptInviteError(err);
        })
        .finally(() => {
          setAcceptInviteLoading(false);
        });
    },
    [cookie]
  );

  const declineSponsorInvite = useCallback((event_id: string, invite_id: number) => {
    
     const data = {
       event_id: parseInt(event_id),
       id: invite_id
     };
    
    let config: AxiosRequestConfig= {
       headers: {
         Authorization: `Bearer ${cookie}`,
       },
     };

     setDeclineInviteLoading(true);
     axios
       .put(`${EVENT_API_URL}/event/${event_id}/invite/sponsor/decline`, data, config)
       .catch((err) => {
         setDeclineInviteError(err);
       })
       .finally(() => {
         setDeclineInviteLoading(false);
       });
  }, [])

  const getSponsorInvite = useCallback(
    (invite_id: string, event_id: string) => {

      let config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      setSponsorInviteLoading(true);
      axios
        .get(`${EVENT_API_URL}/event/${event_id}/invites/sponsor/${invite_id}`, config)
        .then(({data}) => {
          setSponsorInvite(data);
        })
        .catch((err) => {
          setSponsorInviteError(err);
        })
        .finally(() => {
          setSponsorInviteLoading(false);
        });
    },
    []
  );

  const getExhibitorInvite = useCallback(
    (invite_id: string, event_id: string) => {
      let config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      setExhibitorInviteLoading(true);
      axios
        .get(
          `${EVENT_API_URL}/event/${event_id}/invites/exhibitor/${invite_id}`,
          config
        )
        .then(({ data }) => {
          setExhibitorInvite(data);
        })
        .catch((err) => {
          setExhibitorInviteError(err);
        })
        .finally(() => {
          setExhibitorInviteLoading(false);
        });
    },
    []
  );

  const acceptExhibitorInvite = useCallback(
    (event_id: string, org_id: string, invite_id: number) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      const data = {
        event_id: parseInt(event_id),
        org_id,
        id: invite_id
      };

      setAcceptInviteLoading(true);
      axios
        .put(`${EVENT_API_URL}/event/${event_id}/invite/exhibitor`, data, config)
        .catch((err) => {
          setAcceptInviteError(err);
        })
        .finally(() => {
          setAcceptInviteLoading(false);
        });
    },
    [cookie]
  );

  const declineExhibitorInvite = useCallback(
    (event_id: string, invite_id: number) => {
      const data = {
        event_id: parseInt(event_id),
        id: invite_id
      };

      let config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      setDeclineInviteLoading(true);
      axios
        .put(
          `${EVENT_API_URL}/event/${event_id}/invite/exhibitor/decline`,
          data,
          config
        )
        .catch((err) => {
          setDeclineInviteError(err);
        })
        .finally(() => {
          setDeclineInviteLoading(false);
        });
    },
    []
  );


  const getEvent = useCallback(
    (eventID: string) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      setEventLoading(true);
      axios
        .get(`${EVENT_API_URL}/public/event/${eventID}`, config)
        .then(({data}) => {
            setEvent(data)
        })
        .catch((err) => {
          setEventError(err);
        })
        .finally(() => {
          setEventLoading(false);
        });
    },
    [cookie]
  );

  const getUserEvents = useCallback(
    (filters: EventsFilter, jwt: string) => {
      var params = new URLSearchParams();
      if (filters?.time) {
        params.append("time", filters.time);
      }
      // if (filters?.offset) {
      //   params.append("offset", filters.offset.toString());
      // }
      var request = {
        params: params,
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      setUserEventsLoading(true);
      axios
        .get(`${EVENT_API_URL}/account/${jwt}/events`, request)
        .then(({ data, headers }) => {
          setUserEvents(data);
          // setTotalItems(parseInt(headers["x-total-items"]));
        })
        .catch((err) => {
          setUserEventsError(err);
        })
        .finally(() => {
          setUserEventsLoading(false);
        });
    },
    [cookie]
  );

  return {
    acceptInviteError,
    acceptInviteLoading,
    acceptSponsorInvite,

    getEvent,
    event,
    eventError,
    eventLoading,

    declineInviteError,
    declineInviteLoading,
    declineSponsorInvite,

    sponsorInvite,
    sponsorInviteError,
    sponsorInviteLoading,
    getSponsorInvite,

    exhibitorInvite,
    exhibitorInviteError,
    exhibitorInviteLoading,
    getExhibitorInvite,

    acceptExhibitorInvite,
    declineExhibitorInvite,

    getUserEvents,
    userEvents,
    userEventsError,
    userEventsLoading
  };
};
