export const hasContactDetails = (organisation?: API_Organisation) => {
  if (organisation) {
    if (organisation.contact_email) {
      return true;
    }
    if (organisation.contact_phone) {
      return true;
    }
    if (organisation.facebook_url) {
      return true;
    }
    if (organisation.linked_in_url) {
      return true;
    }
    if (organisation.twitter_handle) {
      return true;
    }
    if (organisation.website_url) {
      return true;
    }
  }
  return false;
};


export const getFriendlyDate = (time?: string, labels?: any) => {
  if(time){
    const date = new Date(time);
    if(labels && labels.months){

      let suffix = ""
      const day = date.getDate()


      if(labels.date_units){
        const ordinalSuffixes = {
          zero: "",
          one: labels.date_units.st,
          two: labels.date_units.nd,
          few: labels.date_units.rd,
          other: labels.date_units.th,
          many: "",
        };
        const plural = new Intl.PluralRules("en", { type: "ordinal" });
        const ordinal = plural.select(day);
        if (ordinal in ordinalSuffixes) {
          suffix = ordinalSuffixes[ordinal];
        }
      } else {
        if (day > 3 && day < 21) {
          suffix = "th";
        }
        switch (day % 10) {
          case 1:
            suffix = "st";
            break;
          case 2:
            suffix = "nd";
            break;
          case 3:
            suffix = "rd";
            break;
          default:
            suffix = "th";
            break;
        }
      }

      
      return `${date.getDate()}${suffix} ${labels.months[date.getMonth() + 1]}`
    } else {
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      return date.toLocaleDateString("en-EN", options);
    }
  }

  return ""
}

export const getDateCountdown = (time?: string, labels?: any) => {
  if (time) {
    let inDays = "in XXX days";
    let tomorrow = "Tomorrow";
    let today = "Today";
    let daysAgo = "days ago";

    if (labels) {
      inDays = labels.in_days;
      tomorrow = labels.tomorrow;
      today = labels.today;
      daysAgo = labels.daysAgo;
    }

    let isPast = false;

    const date = new Date(time);
    const now = new Date();
    const diff = date.getTime() - now.getTime();
    const days = Math.round(diff / (1000 * 60 * 60 * 24));
    if (days < 0) {
      isPast = true;
    }
    const absDiff = Math.abs(date.getTime() - now.getTime());
    const absDays = Math.round(absDiff / (1000 * 60 * 60 * 24));

    if(isPast){
      return { label: `${absDays} ${daysAgo}`, diff: absDays };
    } else {
      switch (absDays) {
        case 0:
          return { label: today, diff: absDays };
        case 1:
          return { label: tomorrow, diff: absDays };
        default:
          return { label: inDays.replace("XXX", absDays.toString()), diff: absDays };
      }
    }
  }

  return { label: "", diff: 0 };
};