import { Link, navigate } from "@reach/router";
import user_0 from "../Images/user0.jpg";
import user_1 from "../Images/user1.jpg";
import user_2 from "../Images/user2.jpg";
import user_3 from "../Images/user3.jpg";
import user_4 from "../Images/user4.jpg";
import user_5 from "../Images/user5.jpg";
import user_6 from "../Images/user6.jpg";
import user_7 from "../Images/user7.jpg";
import user_8 from "../Images/user8.jpg";
import user_9 from "../Images/user9.jpg";
import { generateRandomAvatar } from "../utils/utils";
import NavStyles from "../CSS-Modules/nav.module.css";
const languages = require("../languages.json");

const users = {
  0: user_0,
  1: user_1,
  2: user_2,
  3: user_3,
  4: user_4,
  5: user_5,
  6: user_6,
  7: user_7,
  8: user_8,
  9: user_9,
};
const Nav = (props) => {
  const { EVENT_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://event.dev.haia.live";

  const { ACCOUNT_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://accounts.dev.haia.live";

  const { PROFILE_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://profile.dev.haia.live";

  const profileLivePattern = /profile\.haia\.live/g;
  const language = languages[props.language];
  return (
    <nav
      className="navigation-container"
      style={{
        // marginTop:
        //   eventLivePattern.test(window.location.href) === false
        //     ? "10px"
        //     : "0px",
        zIndex:
          profileLivePattern.test(window.location.href) === false
            ? "999"
            : "1000",
      }}
    >
      <a
        className="navigation-logo"
        href={EVENT_URL}
        aria-label="events overview"
        title="Haia - Say hello to the world"
      ></a>

      <ul className="navigation-menu">
        {props.language !== undefined &&
          props.user !== undefined &&
          props.cookie !== undefined && (
            <li>
              <button
                onClick={() => props.updateLang("en")}
                className={
                  props.language === "en"
                    ? `${NavStyles["lang-button"]} ${NavStyles["selected"]}`
                    : `${NavStyles["lang-button"]}`
                }
              >
                English
              </button>
              &nbsp;&nbsp;
              <button
                onClick={() => props.updateLang("cy")}
                className={
                  props.language === "cy"
                    ? `${NavStyles["lang-button"]} ${NavStyles["selected"]}`
                    : `${NavStyles["lang-button"]}`
                }
              >
                Cymraeg
              </button>
            </li>
          )}

        <li>
          <i className="fas fa-ticket-alt" style={{ color: "darkgray" }}></i>
          &nbsp;&nbsp;
          <a href={`${EVENT_URL}/events`}>My Events</a>
        </li>

        <li id="feedback">
          <a
            style={{ color: "white" }}
            href="https://forms.gle/SUhuJui4vSDZK9aHA"
            target="_blank"
            rel="noreferrer"
          >
            Send Feedback
          </a>
        </li>
      </ul>
      <div className={NavStyles.avatar}>
        {props.cookie !== undefined && props.user === undefined && (
          //logged in, no profile creacted
          <a href={PROFILE_URL}>{props.language.create_profile}</a>
        )}
        {props.user !== undefined && props.user !== null && (
          <a href={PROFILE_URL} aria-label="profile management">
            {" "}
            {props.user.first_name}
          </a>
        )}
        {props.user !== undefined && props.user.profile_pic !== null && (
          <img
            onClick={() => navigate(PROFILE_URL)}
            src={props.user.profile_pic}
            alt=""
            className={NavStyles["avatar_img"]}
          ></img>
        )}
        {props.user !== undefined && props.user.profile_pic === null && (
          <img
            onClick={() => navigate(PROFILE_URL)}
            src={users[generateRandomAvatar(props.user.account_id, 10)]}
            alt=""
            className={NavStyles["avatar_img"]}
          ></img>
        )}

        <a href={`${ACCOUNT_URL}/logout`}>
          {" "}
          <i className="fas fa-sign-out-alt" aria-label="log out"></i>
        </a>
      </div>

      <div className="side-menu">
        <ul className="account-menu">
          <li className="hamburger-menu show-mobile hide-mobile-up">
            <i className="fas fa-bars hamburger"> </i>

            <div className="dropdown">
              <a href={`${EVENT_URL}/events`}>My Events</a>{" "}
              <span>
                {props.user !== undefined && props.user !== null && (
                  <a href={PROFILE_URL} className="dropdown-name">
                    {" "}
                    {props.user.first_name}
                  </a>
                )}
                <a href={`${ACCOUNT_URL}/logout`}>
                  {" "}
                  <i className="fas fa-sign-out-alt" aria-label="log out"></i>
                </a>
              </span>
              <a
                href="https://forms.gle/SUhuJui4vSDZK9aHA"
                target="_blank"
                rel="noreferrer"
              >
                Send Feedback
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
