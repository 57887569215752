import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "../organisationpublicpage.module.scss";
import Event from "./Event";

type EventListProps = {
  events?: API_Event[];
  cookie: string;
  organisation: API_Organisation;
};

const EventList = ({ events, cookie, organisation }: EventListProps) => {
  return (
    <div className={styles["events-list"]}>
      {events?.map((e, i) => {
        return (
          <Event
            event={e}
            organisation={organisation}
            key={`org-event-${e.public_id}-${i}`}
          />
        );
      })}
    </div>
  );
};

export default EventList;
