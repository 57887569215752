import LoaderStyles from "../CSS-Modules/loader.module.css";

const Loader = () => {
  return (
    <section className={LoaderStyles.loader}>
      <div
        className={LoaderStyles.spot}
        style={{ backgroundColor: "var(--purple)" }}
      ></div>
      <div
        className={LoaderStyles.spot}
        style={{ backgroundColor: "var(--green)" }}
      ></div>
      <div
        className={LoaderStyles.spot}
        style={{ backgroundColor: "var(--red)" }}
      ></div>
    </section>
  );
};

export default Loader;
