import { DismissModal, useLanguage } from '@haia/haia-components';
import React, { useRef } from 'react';
import AccountID from './AccountID';
import EventsList from './EventsList';
import SingleEvent from './SingleEvent';
import style from './websiteIntegration.module.scss';

type WebsiteIntegrationProps = {
  userID: string;
};
const WebsiteIntegration = ({ userID }: WebsiteIntegrationProps) => {

  const { getLanguageLabel } = useLanguage();
  
  return (
    <section className={style['index']} data-cy='website-integration'>
      <h2>{getLanguageLabel('website_integration')}</h2>
      <p>{getLanguageLabel('website_integration_intro')}</p>
      <AccountID userID={userID} />
      <EventsList userID={userID}/>
      <SingleEvent userID={userID}/>
    </section>
  );
};

export default WebsiteIntegration;
