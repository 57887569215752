import { ProfileImage, useLanguage } from '@haia/haia-components';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import tinycolor from 'tinycolor2';
import styles from './organisationpublicpage.module.scss';

type PublicPageHeaderProps = {
    organisation?: API_Organisation;
    isLight?: boolean;
    followCLick?: () => void;
    unFollowClick?: () => void;
    isFollowing?: boolean;
    followerCount?: number;
    eventCount?: number;
    allowFollowing?: boolean;
};

const PublicPageHeader = ({
    organisation,
    isLight = false,
    followCLick,
    isFollowing = false,
    unFollowClick,
    followerCount = 0,
    eventCount = 0,
    allowFollowing = false,
}: PublicPageHeaderProps) => {
    const { getLanguageLabel } = useLanguage();

    const [primaryDark, setPrimaryDark] = useState(true);
    const [secondaryDark, setSecondaryDark] = useState(true);

    const primary = tinycolor(organisation?.primary_colour);
    const secondary = tinycolor(organisation?.secondary_colour);

    useEffect(() => {
        if (primary) {
            setPrimaryDark(primary.isDark());
        }
    }, [primary]);

    useEffect(() => {
        if (secondary) {
            setSecondaryDark(secondary.isDark());
        }
    }, [secondary]);

    return (
        <div className={styles['header']}>
            <div className={styles['left-block']}>
                <h1>{organisation?.name}</h1>
                <div className={styles['metrics']}>
                    <div className={`${styles['metric']} ${!primaryDark ? styles['light'] : ''}`} style={{ backgroundColor: organisation?.primary_colour }}>
                        {eventCount} {getLanguageLabel('events')}
                    </div>
                    <div className={`${styles['metric']} ${!secondaryDark ? styles['light'] : ''}`} style={{ backgroundColor: organisation?.secondary_colour }}>
                        {followerCount} {getLanguageLabel('followers')}
                    </div>
                </div>
            </div>
            <div className={styles['right-block']}>
                <ProfileImage image={organisation?.logo_url} backgroundColour={organisation?.tertiary_colour} type={'square'} />
                {allowFollowing && (
                    <>
                        {isFollowing ? (
                            <div onClick={unFollowClick} className={`${styles['unfollow-button']}`}>
                                <i className="fa-solid fa-minus"></i> {getLanguageLabel('unfollow')}
                            </div>
                        ) : (
                            <div
                                onClick={followCLick}
                                className={`${styles['follow-button']} ${isLight ? styles['light'] : ''}`}
                                style={{ backgroundColor: organisation?.primary_colour }}
                            >
                                <i className="fa-solid fa-plus"></i> {getLanguageLabel('follow')}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default PublicPageHeader;
