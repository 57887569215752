import { isBlank } from "../../utils/utils";

export const validOrgData = (org?: API_Organisation, stage?: number) => {
    if(stage){
        if(stage === 1){
            if(org && org.name && !isBlank(org.name)){
                return true;
            }
        } else if(stage === 2){
            if(org?.contact_email){
                return true;
            }
        } else if(stage === 3){
            if (org && org.name && !isBlank(org.name) && org?.contact_email) {
              return true;
            }
        }
    } else {
        if(org && org.name && !isBlank(org.name)){
            return true;
        }
    }

    return false;
}