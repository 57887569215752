import { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import axios from "axios";
import info from "../Images/info.svg";
import id from "../Images/id.svg";
import briefcase from "../Images/briefcase.svg";
import SliderStyles from "../CSS-Modules/slider.module.css";
import { useParams, useNavigate } from "react-router-dom";
import { useExternalNavigate } from "../hooks/externalNavigate";
import { isBlank } from "../utils/utils";
import { useLanguage } from "@haia/haia-components";

const CreateUser = (props) => {
  const { tab, modal, plan } = useParams();

  const { getLanguageLabel } = useLanguage();

  const navigate = useNavigate();

  const { externalNavigate } = useExternalNavigate();



  const { API_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://profile-api.dev.haia.live/api";

  const { EVENT_API_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://event-api.dev.haia.live/api";

  const { EVENT_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://event.dev.haia.live";

  const { PROFILE_URL } = window['runConfig']
    ? window['runConfig']
    : 'https://profile.dev.haia.live';

  const [newUser, setNewUser] = useState({
    account_id: props.jwt,
    active: true,
    marketing_opt_in: false,
  });

  const [userDisplayName, setUserDisplayName] = useState()
  const [hasEditedDisplayName, setHasEditedDisplayName] = useState(false);

  let config = {
    headers: {
      Authorization: `Bearer ${props.cookie}`,
    },
  };
  const handleChange = (event) => {
    let val = event.target.value;
    let name = event.target.name;

    if (name === "opt_in_haia_marketing") {
      if (event.target.checked) {
        val = true;
      } else {
        val = false;
      }
    }

    let copyUser = { ...newUser };
    copyUser[name] = val;
    setNewUser({ ...copyUser });
  };

  useEffect(() => {
    if(newUser && (newUser.first_name || newUser.last_name) && !hasEditedDisplayName){
      setUserDisplayName(`${newUser.first_name ? newUser.first_name : ''} ${newUser.last_name ? newUser.last_name : ''}`)
    }
  }, [newUser])

  const handleDisplayNameChange = (event) =>{
    setHasEditedDisplayName(true)
    setUserDisplayName(event.target.value);
  }

  //profile deets-> -> hobbies tag -> languages tag -> sector tag -> social media - >redirect

  const checkRouteParams = () => {
    // let params = tab;
    // if(modal){
    //   params = `${params}/${modal}`;
    // }
    // if(plan){
    //   params = `${params}/${plan}`;
    // }
    // return params;
    if(window.location.pathname.length > 1){
      return true;
    } else {
      return false;
    }
  }

  const handleSubmit = (event, continueOrExit) => {
    event.preventDefault();

    axios
      .post(`${API_URL}/account/profile`, newUser, config)
      .then(() => {
        if (continueOrExit === "exit") {
            const routerParams = checkRouteParams();
            let params = new URLSearchParams(window.location.search);
            if (params.has("redirect_url")) {
                externalNavigate(params.get("redirect_url"));
            } else if(routerParams){
              // we have to break out of the react lifecycle so all components
              // are rerenderd
              window.location.href = window.location.href;
            }else {
                externalNavigate(`${EVENT_URL}/events`);
            }
        } else {
          props.resolveUserNotFound();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkKey = (display_name, lang) => {
    if (
      newUser.default_language && newUser.display_name &&
      newUser.default_language.length > 0 && newUser.display_name.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <main>
      <div className="content">
        <h1>{getLanguageLabel("welcome_to_haia")}</h1>
        <h2 id="create-user-h2">
          {getLanguageLabel("please_tell_us_about_yourself")}
        </h2>
        <section>
          <div className="vertical-modal">
            <div className="side-nav">
              <div className="nav-item nav-item-selected">
                <i className="fad fa-user-circle"></i>
                <span>{getLanguageLabel("basic_details")}</span>
              </div>
            </div>
            <form
              className="vertical-modal-content"
              onSubmit={(e) => handleSubmit(e, "exit")}
            >
              <section>
                {/* <div className="form-grid">
                  <div>
                    <label htmlFor="firstName">
                      {getLanguageLabel("first_name")} *
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="first_name"
                      value={
                        newUser.first_name === undefined
                          ? ""
                          : newUser.first_name
                      }
                      onChange={handleChange}
                      required
                    ></input>
                  </div>
                  <div>
                    <label htmlFor="lastName">
                      {getLanguageLabel("last_name")} *
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="last_name"
                      value={
                        newUser.last_name === undefined ? "" : newUser.last_name
                      }
                      onChange={handleChange}
                      required
                    ></input>
                  </div>
                  <div>
                    <label htmlFor="firstName">
                      {getLanguageLabel("display_name")}
                    </label>
                    <input
                      type="text"
                      id="displayName"
                      name="display_name"
                      value={userDisplayName ? userDisplayName : ""}
                      onChange={handleDisplayNameChange}
                      required
                    ></input>
                  </div>
                </div> */}
                <div className="form-grid">
                  <div>
                    <label htmlFor="firstName">
                      {getLanguageLabel("display_name")} *
                    </label>
                    <input
                      type="text"
                      id="displayName"
                      name="display_name"
                      value={newUser.display_name ? newUser.display_name : ""}
                      onChange={handleChange}
                      required
                    ></input>
                  </div>
                  <div>
                    <div>
                      <label htmlFor="language">
                        {getLanguageLabel("preferred_language")} *
                      </label>
                      {/* <p className="modal-prompt">
                        {getLanguageLabel(
                          "select_your_preferred_language_to_display_platform_content"
                        )}
                      </p> */}
                      <select
                        id="language"
                        name="default_language"
                        onChange={handleChange}
                        value={
                          newUser.default_language === undefined
                            ? ""
                            : newUser.default_language
                        }
                        required
                      >
                        <option value="">
                          {getLanguageLabel("please_select")}
                        </option>
                        <option value="en">
                          {getLanguageLabel("language_selection").en}
                        </option>
                        <option value="cy">
                          {getLanguageLabel("language_selection").cy}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <label htmlFor="opt_in_haia_marketing">
                    {getLanguageLabel("would_you_like_to_recieve_marketing")}
                  </label>
                </div>
                <div>
                  <label className={SliderStyles.switch}>
                    <input
                      type="checkbox"
                      id="opt_in_haia_marketing"
                      name="opt_in_haia_marketing"
                      onChange={handleChange}
                    />
                    <span className={SliderStyles.slider}></span>
                  </label>
                </div>

                <div className="button-container">
                  <i className="fad fa-lightbulb-on"></i>
                  <p>
                    {getLanguageLabel(
                      "dont_worry_you_can_change_all_these_details_later_too"
                    )}
                  </p>
                  <div style={{ margin: "20px" }}>
                    <button
                      disabled={checkKey("display_name", "default_language")}
                      className={
                        checkKey("display_name", "default_language")
                          ? "button-save disabled"
                          : "button-save"
                      }
                      style={{ margin: "0px auto" }}
                    >
                      {getLanguageLabel("save")}
                    </button>
                    <button
                      type="button"
                      className={
                        checkKey("display_name", "default_language")
                          ? "button-continue-disabled"
                          : "button-continue"
                      }
                      disabled={checkKey("display_name", "default_language")}
                      onClick={(e) => {
                        handleSubmit(e, "continue");
                      }}
                    >
                      {getLanguageLabel("continue_to_create_your_profile")}
                    </button>
                  </div>
                </div>
              </section>
            </form>
          </div>
        </section>
      </div>
    </main>
  );
};
export default CreateUser;
