import {
    CategorisedDropDown,
  HaiaLoader,
  ProfileImage,
  useLanguage,
  Warning,
} from "@haia/haia-components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styles from "./userprofilepage.module.scss";
import { useGetUser } from "../../API/profile";
import { useConnections } from "../../API/connections";
import { useOrganisation } from "../../API/organisation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faHand, faHandWave, faHourglass } from "@fortawesome/pro-regular-svg-icons";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { useEvent } from "../../API/event";

type LeadContainerProps = {
  choices: StatusChoice[];
  cookie: string;
  currentUser: API_Public_Profile;
  onConnect: (status: number) => void;
  onFollow: (id: string | number, data: API_Follow_Additional_Data, eventID: number) => void;
  connectionStatus?: number;
  targetUser?: API_Public_Profile;
  eventID?: string | null;
};

const LeadContainer = ({ choices, cookie, currentUser, onConnect, onFollow, connectionStatus, targetUser, eventID }: LeadContainerProps) => {

    const {getLanguageLabel} = useLanguage();

    const {getUserOrganisations, userOrganisations, userEventOrganisations, getUserEventOrganisations, organisationEvents, organisationEventsError, organisationEventsLoading, getOrganisationPartneredEvents} = useOrganisation({cookie: cookie});
    const {fetchedFollow, fetchedFollowLoading, fetchedFollowError, getUserFollow} = useConnections({cookie: cookie});
    const {getEvent, event, eventError, eventLoading} = useEvent({cookie: cookie})


    const [selectedItem, setSelectedItem] = useState<SuggestionDropDownItem>()
    const [selectedOrganisation, setSelectedOrganisation] = useState<API_Organisation>()
    const [filteredOrganisations, setFilteredOrganisations] = useState<API_Organisation[]>()
    const [error, setError] = useState<string>()
    const [selectedEvent, setSelectedEvent] = useState<SuggestionDropDownItem>()
    const [filteredEvents, setFilteredEvents] = useState<API_Event[]>()

    const [selectedStatus, setSelectedStatus] = useState<StatusChoice | undefined>(choices && choices[1] ? choices[1] : undefined)
    const [categories, setCategories] = useState <CategorisedDropDown[]>();

    const [connectType, setConnectType] = useState<ConnectType>("personal")

    const [notes, setNotes] = useState("")

    const [showButtonMenu, setShowButtonMenu] = useState(false)

    const buttonMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if(eventID){
        getUserEventOrganisations(eventID);
        getEvent(eventID);
      } else {
        getUserOrganisations();
        // if(currentUser && currentUser.account_id){
        //   getUserEvents({ time: "live" }, currentUser.account_id);
        // }
      }
    }, [])

    useEffect(() => {
      if(event){
        setSelectedEvent({
          id: event.public_id,
          text: event.name,
          image: event.banner_url,
        });
      }
    }, [event])

    useEffect(() => {
        if(!filteredOrganisations && (userOrganisations || userEventOrganisations)){
            if(userOrganisations){
              const filtered = userOrganisations.member_of;
              setFilteredOrganisations(filtered);
            } else if(userEventOrganisations){
              const filtered = userEventOrganisations;
              setFilteredOrganisations(filtered);
            }
        }
    }, [userOrganisations, userEventOrganisations])

    // useEffect(() => {
    //     let categories: CategorisedDropDown[] = []
    //     if(currentUser && currentUser.display_name){
    //         const personal: CategorisedDropDown = {};
    //         personal.label = getLanguageLabel('personal_account')
    //         personal.items = [{
    //             id: currentUser.account_id,
    //             text: currentUser.display_name,
    //             image: currentUser.profile_pic,
    //         }]
    //         categories.push(personal)
    //     }


    //     if(filteredOrganisations){
    //         const cat: CategorisedDropDown = {}
    //         cat.label = getLanguageLabel('organisations')
    //         cat.items = filteredOrganisations.map((org) => {
    //             return {
    //               id: org.id,
    //               text: org.name,
    //               image: org.logo_url,
    //               background: org.tertiary_colour,
    //             };
    //         })
    //         categories.push(cat)
    //     }
    //     setCategories(categories)
    // }, [filteredOrganisations])

    useEffect(() => {
      if(filteredOrganisations && event){
        if(filteredOrganisations.length === 1){
          setSelectedItem({
            id: filteredOrganisations[0].id,
            text: filteredOrganisations[0].name,
            image: filteredOrganisations[0].logo_url,
            background: filteredOrganisations[0].tertiary_colour,
          });
          setConnectType("lead")
          if(filteredOrganisations[0] && filteredOrganisations[0].id && targetUser && targetUser.account_id && eventID){
            getUserFollow(filteredOrganisations[0].id, targetUser.account_id, eventID);
          }
        }
      }
    }, [filteredOrganisations, event])

    useEffect(() => {
      if(eventID){
        if (eventLoading === false) {
          let categories: CategorisedDropDown[] = [];
          if (!event) {
            if (currentUser && currentUser.display_name) {
              const personal: CategorisedDropDown = {};
              personal.label = getLanguageLabel("personal_account");
              personal.items = [
                {
                  id: currentUser.account_id,
                  text: currentUser.display_name,
                  image: currentUser.profile_pic,
                },
              ];
              categories.push(personal);
            }
          }

          if (filteredOrganisations) {
            const cat: CategorisedDropDown = {};
            cat.label = getLanguageLabel("organisations");
            cat.items = filteredOrganisations.map((org) => {
              return {
                id: org.id,
                text: org.name,
                image: org.logo_url,
                background: org.tertiary_colour,
              };
            });
            categories.push(cat);
          }
          setCategories(categories);
        }
      } else {
        let categories: CategorisedDropDown[] = [];
        if (currentUser && currentUser.display_name) {
          const personal: CategorisedDropDown = {};
          personal.label = getLanguageLabel("personal_account");
          personal.items = [
            {
              id: currentUser.account_id,
              text: currentUser.display_name,
              image: currentUser.profile_pic,
            },
          ];
          categories.push(personal);
        }

        if (filteredOrganisations) {
          const cat: CategorisedDropDown = {};
          cat.label = getLanguageLabel("organisations");
          cat.items = filteredOrganisations.map((org) => {
            return {
              id: org.id,
              text: org.name,
              image: org.logo_url,
              background: org.tertiary_colour,
            };
          });
          categories.push(cat);
        }
        setCategories(categories);
      }
    }, [eventLoading, filteredOrganisations, eventID])

    useEffect(() => {
      if (buttonMenuRef.current) {
        buttonMenuRef.current?.focus();
      }
    }, [buttonMenuRef.current, showButtonMenu]);

    useEffect(() => {
      if(fetchedFollow && selectedItem && fetchedFollow.organisation_id === selectedItem.id){
        const data = fetchedFollow.additional_data;
        if(data){
          if (data.notes) {
            setNotes(data.notes);
          }
          if(data.rag && choices){
            const choice = choices.filter((c) => {return c.colour === data.rag})
            if(choice && choice.length > 0){
              setSelectedStatus(choice[0]);
            }
          }
        }
      }
    }, [fetchedFollow])

    const onOrganisationSelected = (value?: string) => {
        setError(undefined);
        if(!eventID){
          setSelectedEvent(undefined)
        }
        if(currentUser && currentUser.display_name && currentUser.account_id === value){
            setSelectedItem({
              id: currentUser.account_id,
              text: currentUser.display_name,
              image: currentUser.profile_pic,
            });
            setConnectType("personal")
        } else {
            if ((userOrganisations && userOrganisations.member_of) || (userEventOrganisations)) {
              let filter: API_Organisation[] = []
              if(eventID){
                if(userEventOrganisations){
                  filter = userEventOrganisations.filter((org) => {
                    return org.id === value;
                  });
                }
              } else {
                if(userOrganisations){
                  filter = userOrganisations.member_of.filter((org) => {
                    return org.id === value;
                  });
                }
              }
            setError(undefined);
            setNotes("")
            if(filter[0]){
              if(filter[0].id && targetUser && targetUser.account_id){
                if(selectedEvent || eventID){
                  if(selectedEvent){
                    getUserFollow(filter[0].id, targetUser.account_id, selectedEvent.id);
                  } else if(eventID){
                    getUserFollow(filter[0].id, targetUser.account_id, eventID);
                  }
                }
              }
              if(filter[0].id){
                getOrganisationPartneredEvents(filter[0].id);
              }
              setSelectedItem({
                id: filter[0].id,
                text: filter[0].name,
                image: filter[0].logo_url,
                background: filter[0].tertiary_colour,
              });
            }
            setConnectType("lead");
            }
        }
    };

    const onOrganisationSearch = (value?: string) => {
      if (value && value.length > 1) {
        if (userOrganisations && userOrganisations?.member_of) {
          const filtered = userOrganisations.member_of.filter((org) => {
            return org.name.toLowerCase().includes(value.toLowerCase());
          });
          setFilteredOrganisations(filtered);
        }
      } else {
        setFilteredOrganisations(userOrganisations?.member_of);
      }
    };

    const statusClick = (s: StatusChoice) => () => {
        setSelectedStatus(s)
    }

    const onConnectHandler = (status: number) => () => {
      if(selectedItem === undefined){
          setError("Please select a method to connect")
          return;
      }
      setShowButtonMenu(false);
      onConnect(status);
    }

    const onFollowHandler = () => {
      if (selectedItem === undefined) {
        setError("Please select a method to connect");
        return;
      }
      if(selectedEvent === undefined){
        setError("Please select an Event")
        return;
      }

      const d: API_Follow_Additional_Data = {
        rag: selectedStatus ? selectedStatus.colour : "",
        notes: notes,
      };
      if(selectedItem && selectedItem.id && selectedEvent){
        //@ts-ignore
        onFollow(selectedItem.id, d, selectedEvent.id);
      }
    }

    const onNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNotes(e.currentTarget.value);
    }

    const handleButtonMenuOpen = () => {
      if(selectedItem !== undefined){
        setShowButtonMenu(true);
      }
    };

    const onButtonMenuBlur = (e: React.FocusEvent<HTMLDivElement>) => {
      setShowButtonMenu(false);
    };

    const onEventSearch = (value?: string) => {
      if (value && value.length > 1) {
        if (organisationEvents) {
          const filtered = organisationEvents.filter((e) => {
            return e.name.toLowerCase().includes(value.toLowerCase());
          });
          setFilteredEvents(filtered);
        }
      } else {
        setFilteredEvents(organisationEvents);
      }
    };

    const onEventSelected = (value?: string | number) => {
      if (organisationEvents) {
        const filter = organisationEvents.filter((e) => {
          return e.public_id === value;
        });
        setNotes("");
        setSelectedStatus(choices[1]);
        if (filter[0]) {
          if (
            filter[0].id &&
            targetUser &&
            targetUser.account_id &&
            selectedItem &&
            selectedItem.id
          ) {
            getUserFollow(selectedItem.id, targetUser.account_id, filter[0].id);
          }
          setSelectedEvent({
            id: filter[0].id,
            text: filter[0].name,
            image: filter[0].banner_url,
          });
        }
      }
    };

    // console.log(filteredOrganisations, eventID)

  return (
    <div className={`${styles["lead-container"]} ${styles["show"]}`}>
      {error && <Warning>{error}</Warning>}
      {((filteredOrganisations && filteredOrganisations.length > 1) ||
        !event) && (
        <>
          <p>{getLanguageLabel("choose_how_to_interact")}:</p>
          <CategorisedDropDown
            suggestions={categories}
            labels={{
              start_typing: getLanguageLabel("start_typing"),
              no_suggestion_please_try_another_entry: getLanguageLabel(
                "no_suggestion_please_try_another_entry"
              ),
            }}
            placeholder={"e.g. Haia Technologies Ltd"}
            // warning={
            //   language.if_you_see_your_organisation_below_please_contact_your_team_admin_to_request_an_invite
            // }
            valueChange={onOrganisationSearch}
            onSuggestionClick={onOrganisationSelected}
            selected={
              selectedItem && {
                id: selectedItem?.id,
                text: selectedItem.text,
                image: selectedItem.image,
                background: selectedItem.background,
              }
            }
            zIndex={11}
          />
        </>
      )}
      {filteredOrganisations &&
        filteredOrganisations.length === 1 &&
        event !== undefined && (
          <>
            <p>{getLanguageLabel("organisation")}:</p>
            <p className={styles["organisation"]}>
              <ProfileImage
                image={filteredOrganisations[0].logo_url}
                type={"square"}
                backgroundKey={filteredOrganisations[0].tertiary_colour}
              />
              {filteredOrganisations[0].name}
            </p>
          </>
        )}
      {(filteredOrganisations === undefined ||
        filteredOrganisations.length === 0) && (
        <>
          <p>{getLanguageLabel("organisation")}:</p>
          <p className={styles["organisation"]}>
            {getLanguageLabel("no_organisations_associated_with_event")}
          </p>
          <br />
        </>
      )}
      {connectType === "lead" && (
        <>
          {fetchedFollowLoading === true ? (
            <HaiaLoader freeform={true} zIndex={10} />
          ) : (
            <>
              <br />
              {!eventID && (
                <>
                  <p>{getLanguageLabel("event")}:</p>
                  {organisationEvents ? (
                    <CategorisedDropDown
                      suggestions={[
                        {
                          label: "Events",
                          items:
                            organisationEvents &&
                            organisationEvents.map((e) => {
                              return {
                                id: e.public_id,
                                text: e.name,
                                image: e.banner_url,
                              };
                            }),
                        },
                      ]}
                      labels={{
                        start_typing: getLanguageLabel("start_typing"),
                        no_suggestion_please_try_another_entry:
                          getLanguageLabel(
                            "no_suggestion_please_try_another_entry"
                          ),
                      }}
                      placeholder={"e.g. Event"}
                      // warning={
                      //   language.if_you_see_your_organisation_below_please_contact_your_team_admin_to_request_an_invite
                      // }
                      valueChange={onEventSearch}
                      onSuggestionClick={onEventSelected}
                      selected={
                        selectedEvent && {
                          id: selectedEvent?.id,
                          text: selectedEvent.text,
                          image: selectedEvent.image,
                          background: selectedEvent.background,
                        }
                      }
                    />
                  ) : (
                    <p>You have no live events</p>
                  )}
                  <br />
                </>
              )}
              {event && (
                <>
                  <p>{getLanguageLabel("event")}:</p>
                  <p className={styles["event"]}>
                    <ProfileImage image={event.banner_url} type={"square"} />
                    {event.name}
                  </p>
                  <br />
                </>
              )}
              {selectedEvent && (
                <>
                  <p>{getLanguageLabel("set_status")}:</p>
                  {choices && (
                    <div className={styles["status-list"]}>
                      {choices.map((s, i) => {
                        return (
                          <button
                            className={`${styles[s.colour]} ${
                              selectedStatus === s ? styles["selected"] : ""
                            }`}
                            onClick={statusClick(s)}
                            key={`status-${i}`}
                          >
                            <span>
                              {getLanguageLabel(s.label)}{" "}
                              {selectedStatus === s && (
                                <FontAwesomeIcon icon={faCheckCircle} />
                              )}
                            </span>
                          </button>
                        );
                      })}
                    </div>
                  )}
                  <br />
                  <p>{getLanguageLabel("add_notes")}:</p>
                  <textarea
                    placeholder={`${getLanguageLabel("start_typing")}...`}
                    onChange={onNotesChange}
                    value={notes}
                  ></textarea>
                </>
              )}
            </>
          )}
        </>
      )}
      <br />
      <div className={styles["footer"]}>
        {connectType === "personal" && !eventID && (
          <>
            {connectionStatus === 0 && (
              <button
                className={`${
                  selectedItem == undefined ? styles["disabled"] : ""
                } ${styles["green"]}`}
                onClick={onConnectHandler(connectionStatus)}
              >
                <FontAwesomeIcon icon={faHandWave} />
                {getLanguageLabel("connect")}
              </button>
            )}
            {connectionStatus === 1 && (
              <button
                className={`${
                  selectedItem == undefined ? styles["disabled"] : ""
                } ${styles["orange"]}`}
                onClick={handleButtonMenuOpen}
              >
                <FontAwesomeIcon icon={faHourglass} />{" "}
                {getLanguageLabel("pending")}{" "}
                <FontAwesomeIcon icon={faEllipsis} />
                {showButtonMenu && (
                  <div
                    className={`${styles["menu"]} ${styles["red"]}`}
                    onClick={onConnectHandler(2)}
                    ref={buttonMenuRef}
                    tabIndex={2}
                    onBlur={onButtonMenuBlur}
                  >
                    <FontAwesomeIcon icon={faHand} />
                    {getLanguageLabel("cancel")}
                  </div>
                )}
              </button>
            )}
            {connectionStatus === 2 && (
              <button
                className={`${
                  selectedItem == undefined ? styles["disabled"] : ""
                } ${styles["purple"]}`}
                onClick={handleButtonMenuOpen}
              >
                <FontAwesomeIcon icon={faHandWave} />
                {getLanguageLabel("connected")}{" "}
                <FontAwesomeIcon icon={faEllipsis} />
                {showButtonMenu && (
                  <div
                    className={`${styles["menu"]} ${styles["red"]}`}
                    onClick={onConnectHandler(2)}
                    ref={buttonMenuRef}
                    tabIndex={2}
                    onBlur={onButtonMenuBlur}
                  >
                    <FontAwesomeIcon icon={faHand} />
                    {getLanguageLabel("disconnect")}
                  </div>
                )}
              </button>
            )}
            {connectionStatus === 3 && (
              <button
                className={`${
                  selectedItem == undefined ? styles["disabled"] : ""
                } ${styles["green"]}`}
                onClick={onConnectHandler(connectionStatus)}
              >
                {getLanguageLabel("accept")}
              </button>
            )}
          </>
        )}
        {connectType === "lead" && (
          <button
            className={`${
              selectedItem == undefined || selectedEvent === undefined
                ? styles["disabled"]
                : ""
            }`}
            onClick={onFollowHandler}
          >
            {getLanguageLabel("save_details")}
          </button>
        )}
      </div>
      <br />
    </div>
  );
};

export default LeadContainer;
