import React, { useEffect, useState } from 'react';
import InfoPopup from '../InfoPopup';
import styles from './style.module.scss';

type ValidateInputProps = {
  /* optional value for the input */
  value?: string | number;
  /* optional title for the component */
  title: string;
  /* optional className to add to the component */
  className?: string;
  /* the id for the input component */
  id: string;
  /* the type of the input */
  type?: 'text' | 'number' | 'email' | 'tel';
  /* callback for valud NB: validation does not occur here */
  setValue: (val: string | number, valid: boolean, name: string) => void;
  /* validator object with validation function and callback to
     pass back the validity of the value */
  validator: {
    isValid: (value: string | number) => boolean;
    setValid?: (valid: boolean, name: string) => void;
    validatorTimeout?: number
  };

  /* required flag for input */
  required?: boolean;
  /* message shown when the valuie fails the validation */
  message: string;
};
/**
 * Validated input component this renders an input of type text, email or number
 * and also runs a supplied validator to check if the input entered is valid or not
 * this is then depicted in the compnoent and passed to a supplied function
 * @param param0
 * @returns
 */
const ValidatedInput = ({
  value = '',
  type = 'text',
  title,
  id,
  className,
  setValue,
  validator,
  required = false,
  message,
}: ValidateInputProps) => {
  const [stateValue, setStateValue] = useState(value);
  const [isValid, setIsValid] = useState(!required);
  const [typeTimeout, setTypeTimeout] = useState<NodeJS.Timeout>();
  const {validatorTimeout = 500} = validator;

  // use timeout to make sure a user has stopped typing before we process
  // the supplied value and check validity
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = e;
    setStateValue(value);
    if (typeTimeout) {
      clearTimeout(typeTimeout);
    }
    setTypeTimeout(
      setTimeout(() => {
        console.count();
        handleValue(value);
      }, validatorTimeout)
    );
  };

  useEffect(() => {
    return () => {
      clearTimeout(typeTimeout);
    };
  }, [typeTimeout]);

  const handleValue = (value: string) => {
    setValue(value, isValid, id);
    const validValid = validator.isValid(value);
    setIsValid(validValid);
    if (validator.setValid) {
      validator.setValid(validValid, id);
    }
  };
  const buildClass = () => {
    let classNameString = styles['validated-input'];
    if (className) classNameString = `${classNameString} ${className}`;
    if (!isValid) return `${classNameString} ${styles.invalid}`;
    return classNameString;
  };
  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = e;
    setStateValue(value);
    handleValue(value);
  };

  return (
    <div className={buildClass()} data-cy='validate-input'>
      <label htmlFor={id}>
        {title}{' '}
        {!isValid && (
          <InfoPopup
            description={message}
            type={'warning'}
            style='none'
            children={undefined}
          ></InfoPopup>
        )}
      </label>
      <input
        type={type}
        value={stateValue}
        onChange={handleChange}
        id={id}
        name={id}
        onBlur={handleOnBlur}
      />
    </div>
  );
};

export default ValidatedInput;
