export const generateRandomAvatar = (accountId, avatarCount) => {
  if (accountId === undefined || avatarCount === undefined) {
    return 0;
  }
  let sum = [];
  for (let i = 0; i < accountId.length; i++) {
    sum.push(accountId.charCodeAt(i));
  }
  const reduced = sum.reduce((a, b) => a + b);
  return reduced % avatarCount;
};

export const order = (arr, key) => {
  arr.sort((a, b) => {
    return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
  });
  return arr;
};
export const generateRenewalDate = (date, renewalType) => {
  //renewalType as 'month' or 'annual'
  //date passed in as new Date(blah)
  if (date === undefined || renewalType === undefined) {
    return "";
  }
  if (renewalType === "month") {
    const months = [
      { January: 31 },
      { February: 28 },
      { March: 31 },
      { April: 30 },
      { May: 31 },
      { June: 30 },
      { July: 31 },
      { August: 31 },
      { September: 30 },
      { October: 31 },
      { November: 30 },
      { December: 31 },
    ];
    const d = date.getDate();
    const m = date.getMonth();
    const res = new Date();
    res.setMonth(m + 1);
    if (Object.values(months[res.getMonth()]) < d) {
      const diff = d - Object.values(months[res.getMonth()]);
      res.setDate(d - diff);
    } else {
      res.setDate(d);
    }
    let suffix;
    const newDate = res.getDate();
    if (newDate > 3 && newDate < 21) {
      suffix = "th";
    } else if (newDate % 10 === 1) {
      suffix = "st";
    } else if (newDate % 10 === 2) {
      suffix = "nd";
    } else if (newDate % 10 === 3) {
      suffix = "rd";
    } else {
      suffix = "th";
    }

    return `${res.getDate()}${suffix} ${Object.keys(
      months[res.getMonth()]
    )}, ${res.getFullYear()}`;
  }
};

export const generateRenewalDateToPost = (date, renewalType) => {
  //renewalType as 'month' or 'annual'
  //date passed in as new Date(blah)
  if (date === undefined || renewalType === undefined) {
    return "";
  }
  if (renewalType === "month") {
    const months = [
      { January: 31 },
      { February: 28 },
      { March: 31 },
      { April: 30 },
      { May: 31 },
      { June: 30 },
      { July: 31 },
      { August: 31 },
      { September: 30 },
      { October: 31 },
      { November: 30 },
      { December: 31 },
    ];
    const d = date.getDate();
    const m = date.getMonth();
    const res = new Date();
    res.setMonth(m + 1);
    if (Object.values(months[res.getMonth()]) < d) {
      const diff = d - Object.values(months[res.getMonth()]);
      res.setDate(d - diff);
    } else {
      res.setDate(d);
    }

    return res.toISOString();
  }
};

export const formatDate = (date) => {
  if (date === undefined || date === null) {
    return "";
  }
  const months = [
    { January: 31 },
    { February: 28 },
    { March: 31 },
    { April: 30 },
    { May: 31 },
    { June: 30 },
    { July: 31 },
    { August: 31 },
    { September: 30 },
    { October: 31 },
    { November: 30 },
    { December: 31 },
  ];
  let suffix;
  const d = date.getDate();
  if (d > 3 && d < 21) {
    suffix = "th";
  } else if (d % 10 === 1) {
    suffix = "st";
  } else if (d % 10 === 2) {
    suffix = "nd";
  } else if (d % 10 === 3) {
    suffix = "rd";
  } else {
    suffix = "th";
  }
  return `${date.getDate()}${suffix} ${Object.keys(
    months[date.getMonth()]
  )}, ${date.getFullYear()}`;
};

export const getTimezoneAbbreviationFromName = (timezone) => {
  if(timezone){
    const shortTimezone = new Date().toLocaleDateString(undefined, { timeZoneName: 'short', timeZone: timezone });
    if(shortTimezone && shortTimezone.includes(',')) {
      return shortTimezone.split(',')[1].trim()
    }
  }
}

export const getBrowserTimezone = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tz;
}


export const checkValidTag = (tag) => {
  if (tag.length === 0) {
    return false;
  } else {
    const pattern = /[^a-zA-Z]/g;

    return pattern.test(tag) ? false : true;
  }
};

export const isBlank = (str) => {
  return !str || /^\s*$/.test(str);
};

export const displayDate = (startDate) => {
  const dateString = new Date(startDate).toDateString().split(" ");
  const day = dateString[0];
  const dateAndMonth = dateString[1] + " " + dateString[2];
  const year = dateString[3];
  return (
    `${day}, ${dateAndMonth}, ${year}`
  
  );
};

export const getFriendlyEventDuration = (event) => {
  if(event){
    const start = new Date(event.start_date);
    return `(${displayDate(start)})`
  }

  return "";
}