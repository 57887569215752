import {
  BackgroundPicker,
  ConfirmModal,
  ImageCrop,
  ProfileImage,
  StagedModal,
  useLanguage,
  Warning,
} from "@haia/haia-components";
import React, {
  CSSProperties,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useGetUser } from "../../../API/profile";
import OrganiserContext from "../../../Context/Organiser/OrganiserContext";
import {
  changeAddMemberCallback,
  changeImageCropCallback,
} from "../../../Context/Organiser/reducer";
import { validOrgData } from "../../../Pages/Tabs/utils";
import { checkValidTag } from "../../../utils/utils";
import AvatarSection from "../../AvatarSection";
import CoverSection from "../../CoverSection";
import AddMemberModal from "../AddMemberModal";
import OrganisationMembersList from "../OrganisationMembersModal/Members/MembersList";
import styles from "./organisationmodal.module.scss";

type OrganisationModalProps = {
  organisation: API_Organisation;
  onClose: () => void;
  uploadOrgFile?: (blob: any, filename: string, key?: string) => void;
  fileUploadLoading?: boolean;
  fileUploadResponse?: API_File_Response;
  openCropModal?: (image: string) => void;
  cookie?: string;
  userID?: string;
  handleUpdateOrg?: (org: API_Organisation) => void;
  errorOccured?: boolean;
};

const EditOrganisationModal = ({
  organisation,
  onClose,
  uploadOrgFile,
  fileUploadLoading,
  fileUploadResponse,
  cookie,
  userID,
  handleUpdateOrg,
  errorOccured = false
}: OrganisationModalProps) => {

  const { getLanguageLabel } = useLanguage();

  const [stage, setStage] = useState(0);
  const [localOrg, setLocalOrg] = useState<API_Organisation>();
  const [canSave, setCanSave] = useState(false);

  const [currentUploadKey, setCurrentUploadKey] = useState<string>();
  const [selectedProfile, setSelectedProfile] = useState<string>();
  const [selectedCover, setSelectedCover] = useState<string>();

  const [orgTag, setOrgTag] = useState<string>("");

  const [showPrimaryColourSelection, setShowPrimaryColourSelection] =
    useState(false);
  const [showSecondaryColourSelection, setShowSecondaryColourSelection] =
    useState(false);
  const [showTertiaryColourSelection, setShowTertiaryColourSelection] =
    useState(false);

  const [showCropModal, setShowCropModal] = useState(false);
  const [currentSelectedImage, setCurrentSelectedImage] = useState<string>();
  const [idealCropSize, setIdealCropSize] = useState<Size>();
  const [cropAspect, setCropAspect] = useState<number>();

  const [showInviteModal, setShowInviteModal] = useState(false);

  const primaryColourRef = useRef<HTMLDivElement>(null);
  const secondaryColourRef = useRef<HTMLDivElement>(null);
  const tertiaryColourRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (organisation) {
      setLocalOrg(organisation);
    }
  }, [organisation]);

  useEffect(() => {
    switch (stage) {
      case 0:
        setCanSave(validOrgData(localOrg, 1));
        break;
      case 1:
        setCanSave(validOrgData(localOrg, 2));
        break;
      default:
        setCanSave(true);
        break;
    }
  }, [stage, localOrg]);

  useEffect(() => {
    // if(currentUploadKey){
    //   console.log(currentUploadKey)
    //   switch(currentUploadKey){
    //     case 'logo_url':
    //       //  setIdealCropSize({width: 1000, height: 1000});
    //        setCropAspect(undefined);
    //       break;
    //     case 'banner_url':
    //       setIdealCropSize({width: 500, height: 500});
    //       setCropAspect(16/9);
    //       break;
    //   }
    // } else {
    //   setIdealCropSize(undefined);
    //   setCropAspect(undefined);
    // }
  }, [currentUploadKey]);

  const onSave = () => {
    if (handleUpdateOrg && localOrg) {
      handleUpdateOrg(localOrg);
    }
  };

  const navigateToTab = (tab: number) => () => {
    if (validOrgData(localOrg, tab)) {
      setStage(tab);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const name = e.target.name;
    const copy = Object.assign({}, localOrg);
    //@ts-ignore
    copy[name] = val;
    setLocalOrg(copy);
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrgTag(e.target.value);
  };

  const addOrgTag = () => {
    const copy = Object.assign({}, localOrg);
    if (copy && orgTag) {
      if (copy.tags) {
        copy.tags.push({ value: orgTag });
      } else {
        copy.tags = [{ value: orgTag }];
      }
      setOrgTag("");
    }
    setLocalOrg(copy);
  };

  const removeOrgTag = (tag: API_Organisation_Tag) => () => {
    const copy = Object.assign({}, localOrg);
    if (copy && copy.tags) {
      copy.tags = copy.tags.filter((item) => {
        return item.value !== tag.value;
      });
    }
    setLocalOrg(copy);
  };

  const onCropCompletion = (blob?: Blob) => {
    if (!blob) {
      setSelectedProfile(undefined);
      setSelectedCover(undefined);
    } else {
      if (localOrg && localOrg.name && uploadOrgFile) {
        uploadOrgFile(blob, localOrg.name);
      }
    }
  };

  const closeCropModal = () => {
    setShowCropModal(false);
  };

  const onImageSelection = (image?: string, key?: string, needCrop = true) => {
    if (needCrop) {
      switch (key) {
        case "logo_url":
          setIdealCropSize(undefined);
          setCropAspect(undefined);
          break;
        case "banner_url":
          // setIdealCropSize({ width: 1200, height: 450 });
          setCropAspect(8 / 3);
          break;
      }
      setCurrentSelectedImage(image);
      setCurrentUploadKey(key);
      setShowCropModal(true);
    } else {
      const copy = Object.assign({}, localOrg);
      if (copy && key) {
        //@ts-ignore
        copy[key] = image;
        setLocalOrg(copy);
      }
    }
  };

  //--- get response from file upload ---
  useEffect(() => {
    if (fileUploadResponse) {
      const copy = Object.assign({}, localOrg);
      if (copy && currentUploadKey) {
        //@ts-ignore
        copy[currentUploadKey] = fileUploadResponse.url;
        setLocalOrg(copy);
      }
      setSelectedProfile(undefined);
      setSelectedCover(undefined);
    }
  }, [fileUploadResponse, currentUploadKey]);

  //--- when file is done uploading ---
  useEffect(() => {
    if (!fileUploadLoading) {
      setSelectedProfile(undefined);
      setSelectedCover(undefined);
      setShowCropModal(false);
      setCurrentUploadKey(undefined);
    }
  }, [fileUploadLoading]);

  //--- on primary colour selection ---
  const onPrimaryBackgroundSelection = (selection: string) => {
    const copy = Object.assign({}, localOrg);
    if (copy) {
      copy.primary_colour = selection;
      setLocalOrg(copy);
      onBackgroundSelectionClose();
    }
  };

  //--- on secondary colour selection ---
  const onSecondaryBackgroundSelection = (selection: string) => {
    const copy = Object.assign({}, localOrg);
    if (copy) {
      copy.secondary_colour = selection;
      setLocalOrg(copy);
      onBackgroundSelectionClose();
    }
  };

  //--- on tertiary colour selection ---
  const onTertiaryBackgroundSelection = (selection: string) => {
    const copy = Object.assign({}, localOrg);
    if (copy) {
      copy.tertiary_colour = selection;
      setLocalOrg(copy);
      onBackgroundSelectionClose();
    }
  };

  //--- unified function to close picker modals ---
  const onBackgroundSelectionClose = () => {
    setShowPrimaryColourSelection(false);
    setShowSecondaryColourSelection(false);
    setShowTertiaryColourSelection(false);
  };

  //--- show picker selection modal depending on supplied type ---
  const showSelectionModal = (type: string) => () => {
    switch (type) {
      case "primary":
        setShowPrimaryColourSelection(true);
        setShowSecondaryColourSelection(false);
        setShowTertiaryColourSelection(false);
        if (primaryColourRef.current) {
          primaryColourRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case "secondary":
        setShowPrimaryColourSelection(false);
        setShowSecondaryColourSelection(true);
        setShowTertiaryColourSelection(false);
        if (secondaryColourRef.current) {
          secondaryColourRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case "tertiary":
        setShowPrimaryColourSelection(false);
        setShowSecondaryColourSelection(false);
        setShowTertiaryColourSelection(true);
        if (tertiaryColourRef.current) {
          tertiaryColourRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
    }
  };

  //--- add member button is clicked ---
  const onAddMemberClick = () => {
    setShowInviteModal(true);
  };

  const onAddMemberModalClose = () => {
    setShowInviteModal(false);
  };

  const onAddMemberComplete = (invitee: string) => {
    const copy = Object.assign({}, localOrg);
    if (copy) {
      if (copy.members) {
        copy.members?.push({ invited_email: invitee });
      } else {
        copy.members = [{ invited_email: invitee }];
      }
      setLocalOrg(copy);
    }
    setShowInviteModal(false);
  };

  return (
    <>
      <ConfirmModal
        title={""}
        onConfiirm={onSave}
        onClose={onClose}
        showHeader={false}
        scrollable={false}
        cancelLabel={getLanguageLabel("cancel")}
        confiirmLabel={getLanguageLabel("save")}
        tabbed={true}
        canSave={canSave}
      >
        <>
          {stage >= 0 && (
            <section
              className={`${styles["organisation-modal-content"]} ${styles["tabbed"]}`}
            >
              <h2>{getLanguageLabel("edit_organisation")}</h2>
              <nav>
                <ul className={styles["tabs"]}>
                  <li
                    className={`${styles["tab-item"]} ${
                      stage === 0 ? styles["selected"] : ""
                    }`}
                    onClick={navigateToTab(0)}
                  >
                    <i className="far fa-info-circle"></i>
                    <span>{getLanguageLabel("general_info")}</span>
                  </li>
                  <li
                    className={`${styles["tab-item"]} ${
                      stage === 1 ? styles["selected"] : ""
                    }`}
                    onClick={navigateToTab(1)}
                  >
                    <i className="far fa-share-nodes"></i>
                    <span>{getLanguageLabel("contact_and_social")}</span>
                  </li>
                  <li
                    className={`${styles["tab-item"]} ${
                      stage === 2 ? styles["selected"] : ""
                    }`}
                    onClick={navigateToTab(2)}
                  >
                    <i className="far fa-palette"></i>
                    <span>{getLanguageLabel("appearance")}</span>
                  </li>
                  {/* <li
                    className={`${styles["tab-item"]} ${
                      stage === 3 ? styles["selected"] : ""
                    }`}
                    onClick={navigateToTab(3)}
                  >
                    <i className="far fa-users"></i>
                    <span>{getLanguageLabel('members}</span>
                  </li> */}
                </ul>
              </nav>
              {errorOccured && (
                <Warning>
                  <span>
                    {getLanguageLabel(
                      "error_while_processing_data_please_try_again"
                    )}
                  </span>
                </Warning>
              )}
              {stage === 0 && (
                <div className="modal-form">
                  <section className={styles["padded"]}>
                    <div className={"form-grid"}>
                      <div>
                        <label htmlFor="title">
                          {getLanguageLabel("organisation_name")}
                          <span className={"label-sub"}>
                            ({getLanguageLabel("required")})
                          </span>
                        </label>
                        <input
                          id="title"
                          type="text"
                          required
                          aria-required="true"
                          onChange={handleChange}
                          name="name"
                          value={localOrg?.name ?? ""}
                        ></input>
                      </div>
                      <div>
                        <label htmlFor="location">
                          {getLanguageLabel("location")}
                        </label>
                        <input
                          id="location"
                          type="text"
                          required
                          aria-required="true"
                          onChange={handleChange}
                          name="location"
                          value={localOrg ? localOrg.location : ""}
                        ></input>
                      </div>
                    </div>
                    <div>
                      <label htmlFor="description">
                        {getLanguageLabel("short_bio")}
                      </label>
                      <textarea
                        id="description"
                        //@ts-ignore
                        onChange={handleChange}
                        name="bio"
                        value={localOrg ? localOrg.bio : ""}
                        rows={6}
                      ></textarea>
                    </div>
                    <div>
                      <div>
                        <label htmlFor="tags">{getLanguageLabel("tags")}</label>
                        <p className={styles["subtitle"]}>
                          {getLanguageLabel(
                            "enter_some_keywords_and_press_enter"
                          )}
                        </p>
                      </div>
                      <div>
                        <input
                          style={{
                            border:
                              orgTag.length === 0
                                ? "#ccc 1px solid"
                                : !checkValidTag(orgTag)
                                ? "red 1px solid"
                                : "#ccc 1px solid",
                          }}
                          id="tags"
                          value={orgTag}
                          name="tags"
                          onChange={handleTagChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && checkValidTag(orgTag)) {
                              e.preventDefault();
                              addOrgTag();
                            } else if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                          // placeholder={`+ ${language.add_a_tag_and_press_enter}`}
                        ></input>
                        {/* {suggestedInterests !== undefined && (
                      <ul className="event-tag-container">
                        {suggestedInterests.map((tag, i) => {
                          return (
                            <li
                              tabIndex="0"
                              key={i}
                              className="suggested-tag"
                              onClick={() => {
                                let copyTags = [...confirmedInterests];
                                copyTags.push(tag);
                                setConfirmedInterests(copyTags);
                                setSuggestedInterests();
                                setInterestTag("");
                              }}
                            >
                              {tag.value}
                            </li>
                          );
                        })}
                      </ul>
                      )} */}
                        <ul className="event-tag-container">
                          {localOrg &&
                            localOrg.tags &&
                            localOrg.tags.map((tag, i) => {
                              return (
                                <li key={i} className="event-tag">
                                  {tag.value}
                                  <button
                                    type="button"
                                    onClick={removeOrgTag(tag)}
                                  >
                                    x
                                  </button>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              )}
              {stage === 1 && (
                <div className="modal-form">
                  <section className={styles["padded"]}>
                    <div className={"form-grid"}>
                      <div>
                        <label htmlFor="email">
                          {getLanguageLabel("contact_email")}
                          <span className={"label-sub"}>
                            ({getLanguageLabel("required")})
                          </span>
                        </label>
                        <input
                          id="email"
                          type="text"
                          required
                          aria-required="true"
                          onChange={handleChange}
                          name="contact_email"
                          value={localOrg?.contact_email ?? ""}
                        ></input>
                      </div>
                      <div>
                        <label htmlFor="contact_phone">
                          {getLanguageLabel("contact_telephone")}
                        </label>
                        <input
                          id="contact_phone"
                          type="tel"
                          onChange={handleChange}
                          name="contact_phone"
                          value={localOrg?.contact_phone ?? ""}
                        ></input>
                      </div>
                    </div>
                    <div className={"form-grid"}>
                      <div>
                        <label htmlFor="website">
                          {getLanguageLabel("website_url")}
                        </label>
                        <input
                          id="website"
                          type="text"
                          onChange={handleChange}
                          name="website_url"
                          value={localOrg?.website_url ?? ""}
                        ></input>
                      </div>
                      <div>
                        <label htmlFor="facebook">
                          {getLanguageLabel("facebook_url")}
                        </label>
                        <input
                          id="facebook"
                          type="text"
                          onChange={handleChange}
                          name="facebook_url"
                          value={localOrg?.facebook_url ?? ""}
                        ></input>
                      </div>
                    </div>
                    <div className={"form-grid"}>
                      <div>
                        <label htmlFor="twitter">
                          {getLanguageLabel("twitter_handle")}
                        </label>
                        <input
                          id="twitter"
                          type="text"
                          onChange={handleChange}
                          name="twitter_handle"
                          value={localOrg?.twitter_handle ?? ""}
                        ></input>
                      </div>
                      <div>
                        <label htmlFor="linkedin">
                          {getLanguageLabel("linked_in_url")}
                        </label>
                        <input
                          id="linkedin"
                          type="text"
                          onChange={handleChange}
                          name="linked_in_url"
                          value={localOrg?.linked_in_url ?? ""}
                        ></input>
                      </div>
                    </div>
                  </section>
                </div>
              )}
              {stage === 2 && (
                <div className="modal-form">
                  <section className={styles["padded"]}>
                    <div className={"form-grid"}>
                      <div>
                        <label>{getLanguageLabel("profile_image")}</label>
                        <AvatarSection
                          currentUser={{
                            profile_pic: localOrg?.logo_url,
                            display_name: localOrg?.name,
                          }}
                          loading={fileUploadLoading}
                          type="square"
                          onImageSelection={onImageSelection}
                          imageKey={"logo_url"}
                          selectedImage={selectedProfile}
                          setSelectedImage={setSelectedProfile}
                          backgroundColour={localOrg?.tertiary_colour}
                        />
                      </div>
                      <div>
                        <div ref={primaryColourRef}>
                          <label>{getLanguageLabel("primary_colour")}</label>
                          <div className={"form-grid"}>
                            <p className={styles["subtitle"]}>
                              {getLanguageLabel(
                                "this_will_be_used_on_buttons_and_areas_of_your_organisations_profile"
                              )}
                            </p>
                            <div
                              className={
                                styles["primary-colour-picker-container"]
                              }
                            >
                              <div className={styles["choose-background"]}>
                                <div
                                  className={
                                    styles["choose-background-indicator"]
                                  }
                                  style={{
                                    background: localOrg?.primary_colour,
                                  }}
                                  onClick={showSelectionModal("primary")}
                                ></div>
                              </div>
                              {showPrimaryColourSelection && (
                                <BackgroundPicker
                                  onSelection={onPrimaryBackgroundSelection}
                                  onClose={onBackgroundSelectionClose}
                                  labels={{
                                    image: getLanguageLabel("image"),
                                    colour: getLanguageLabel("colour"),
                                    cancel: getLanguageLabel("cancel"),
                                    apply: getLanguageLabel("apply"),
                                    custom: getLanguageLabel("custom"),
                                    enter_a_hex_value:
                                      getLanguageLabel("enter_a_hex_value"),
                                  }}
                                  tooltipSide={"top"}
                                  allowImages={false}
                                  allowCustom={true}
                                  additionalStyles={{ top: "50%" }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div ref={secondaryColourRef}>
                          <label>{getLanguageLabel("secondary_colour")}</label>
                          <div className={"form-grid"}>
                            <p className={styles["subtitle"]}>
                              {getLanguageLabel(
                                "this_will_be_used_on_buttons_and_areas_of_your_organisations_profile"
                              )}
                            </p>
                            <div
                              className={
                                styles["secondary-colour-picker-container"]
                              }
                            >
                              <div className={styles["choose-background"]}>
                                <div
                                  className={
                                    styles["choose-background-indicator"]
                                  }
                                  style={{
                                    background: localOrg?.secondary_colour,
                                  }}
                                  onClick={showSelectionModal("secondary")}
                                ></div>
                              </div>
                              {showSecondaryColourSelection && (
                                <BackgroundPicker
                                  onSelection={onSecondaryBackgroundSelection}
                                  onClose={onBackgroundSelectionClose}
                                  labels={{
                                    image: getLanguageLabel("image"),
                                    colour: getLanguageLabel("colour"),
                                    cancel: getLanguageLabel("cancel"),
                                    apply: getLanguageLabel("apply"),
                                    custom: getLanguageLabel("custom"),
                                    enter_a_hex_value:
                                      getLanguageLabel("enter_a_hex_value"),
                                  }}
                                  tooltipSide={"top"}
                                  allowImages={false}
                                  allowCustom={true}
                                  additionalStyles={{ top: "50%" }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div ref={tertiaryColourRef}>
                          <label>{getLanguageLabel("tertiary_colour")}</label>
                          <div className={"form-grid"}>
                            <p className={styles["subtitle"]}>
                              {getLanguageLabel(
                                "this_colour_will_be_used_as_a_background_on_your_organisation_logo"
                              )}
                            </p>
                            <div
                              className={
                                styles["tertiary-colour-picker-container"]
                              }
                            >
                              <div className={styles["choose-background"]}>
                                <div
                                  className={
                                    styles["choose-background-indicator"]
                                  }
                                  style={{
                                    background: localOrg?.tertiary_colour,
                                  }}
                                  onClick={showSelectionModal("tertiary")}
                                ></div>
                              </div>
                              {showTertiaryColourSelection && (
                                <BackgroundPicker
                                  onSelection={onTertiaryBackgroundSelection}
                                  onClose={onBackgroundSelectionClose}
                                  labels={{
                                    image: getLanguageLabel("image"),
                                    colour: getLanguageLabel("colour"),
                                    cancel: getLanguageLabel("cancel"),
                                    apply: getLanguageLabel("apply"),
                                    custom: getLanguageLabel("custom"),
                                    enter_a_hex_value:
                                      getLanguageLabel("enter_a_hex_value"),
                                  }}
                                  tooltipSide={"top"}
                                  allowImages={false}
                                  allowCustom={true}
                                  additionalStyles={{ top: "50%" }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label>{getLanguageLabel("cover_image")}</label>
                        <CoverSection
                          image={localOrg?.banner_url}
                          type={"square"}
                          loading={fileUploadLoading}
                          selectedImage={selectedCover}
                          setSelectedImage={setSelectedCover}
                          imageKey={"banner_url"}
                          onImageSelection={onImageSelection}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              )}
              {/* {stage === 3 && (
                <div className="modal-form">
                  <section className={styles["padded"]}>
                    <label>
                      {getLanguageLabel('members}{" "}
                      <span className={"label-sub"}>
                        (
                        {organisation.members_count !== undefined
                          ? organisation.members_count
                          : 0}
                        )
                      </span>
                    </label>
                    {cookie && userID && (
                      <OrganisationMembersList
                        cookie={cookie}
                        organisationID={organisation.id}
                        labels={labels}
                        accountID={userID}
                        ownerID={organisation.owner_id}
                      />
                    )}
                    <div className={styles["add-member-container"]}>
                      <div
                        className={`${styles["button"]} ${styles["green"]}`}
                        onClick={onAddMemberClick}
                      >
                        <i className="fa-duotone fa-paper-plane"></i>{" "}
                        {getLanguageLabel('invite_more_members}
                      </div>
                    </div>
                  </section>
                </div>
              )} */}
            </section>
          )}
        </>
      </ConfirmModal>
      {showCropModal && (
        <ImageCrop
          selectedImage={currentSelectedImage}
          onModalClose={closeCropModal}
          onCropCompletion={onCropCompletion}
          idealSize={idealCropSize}
          aspect={cropAspect}
          imageType={cropAspect ? "cover" : "profile"}
          labels={{
            crop_image: getLanguageLabel("crop_image"),
            continue: getLanguageLabel("continue"),
            cancel: getLanguageLabel("cancel"),
          }}
          uploadLoading={fileUploadLoading}
        />
      )}
      {showInviteModal && (
        <AddMemberModal
          onClose={onAddMemberModalClose}
          onComplete={onAddMemberComplete}
        />
      )}
    </>
  );
};

export default EditOrganisationModal;
