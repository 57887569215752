import axios, { AxiosRequestConfig } from "axios";
import { useCallback, useEffect, useState } from "react";

//@ts-ignore
const { API_URL } = window["runConfig"] ? window["runConfig"] : { PROFILE_API_URL: "https://profile-api.dev.haia.live/api" };
//@ts-ignore
const { EVENT_API_URL } = window["runConfig"] ? window["runConfig"] : { API_URL: "https://event-api.dev.haia.live/api" };

type GetUserProps = {
  cookie?: string;
  jwt?: string;
};

export const useGetUser = ({ cookie }: GetUserProps) => {
  const [user, setUser] = useState<API_Public_Profile>();
  const [userError, setUserError] = useState();
  const [userLoading, setUserLoading] = useState(true);
  const [userSocials, setUserSocials] = useState<API_Socials>();
  const [userSocialsError, setuserSocialsError] = useState();
  const [userSocialsLoading, setuserSocialsLoading] = useState(true);
  const [profile, setProfile] = useState<API_Public_Profile>();

  const [publicProfiles, setPublicProfiles] = useState<API_Public_Profile[]>();
  const [publicProfilesError, setPublicProfilesError] = useState();
  const [publicProfilesLoading, setPublicProfilesLoading] = useState(true);

  const fetchUser = useCallback(
    (userID: string) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      setUserLoading(true);
      axios
        .get(`${API_URL}/account/profile/${userID}`, config)
        .then(({ data }) => {
          setProfile(data);
          fetchUserSocials(userID);
        })
        .catch((err) => {
          setUserError(err);
        })
        .finally(() => {
          setUserLoading(false);
        });
    },
    [cookie, API_URL]
  );

  const fetchUserSocials = useCallback(
    (userID: string) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      setuserSocialsLoading(true);
      axios
        .get(`${EVENT_API_URL}/social/id/${userID}`, config)
        .then(({ data }) => {
          setUserSocials(data);
        })
        .catch((err) => {
          setuserSocialsError(err);
        })
        .finally(() => {
          setuserSocialsLoading(false);
        });
    },
    [cookie, EVENT_API_URL]
  );

  useEffect(() => {
    if (profile && userSocials) {
      const copy = profile;
      copy.socials = userSocials;
      setUser(copy);
    }
  }, [profile, userSocials]);

  const fetchProfilesByIDs = useCallback(
    (IDs: string[]) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      setPublicProfilesLoading(true);
      axios
        .post(`${API_URL}/account/profiles`, IDs, config)
        .then(({ data }) => {
          setPublicProfiles(data);
        })
        .catch((err) => {
          setPublicProfilesError(err);
        })
        .finally(() => {
          setPublicProfilesLoading(false);
        });
    },
    [cookie, API_URL]
  );

  return {
    user,
    userError,
    userLoading,
    fetchUser,
    fetchProfilesByIDs,
    publicProfiles,
    publicProfilesLoading,
    fetchUserSocials,
    userSocials,
  };
};
