import { ProfileImage, useLanguage, Warning } from "@haia/haia-components";
import React, {
  CSSProperties,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useOrganisation } from "../../../../API/organisation";
import { useGetUser } from "../../../../API/profile";
import OrganiserContext from "../../../../Context/Organiser/OrganiserContext";
import styles from "../organisationmembersmodal.module.scss";
import Member from "./Member";


type OrganisationMembersListProps = {
  cookie: string;
  organisationID?: string;
  accountID?: string;
  ownerID?: string;
  scrollable?: boolean;
  handleTooltipClick: (type: string, member: API_Organisation_Member) => void;
};

const OrganisationMembersList = ({
  cookie,
  organisationID,
  accountID,
  ownerID,
  scrollable = false,
  handleTooltipClick
}: OrganisationMembersListProps) => {

    const { getLanguageLabel } = useLanguage();

    const { state, dispatch } = useContext(OrganiserContext);
    const {getOrganisationMembers, organisationMembers, organisationMembersLoading, organisationMembersError} = useOrganisation({cookie: cookie})
    const { fetchProfilesByIDs, publicProfiles } = useGetUser({cookie: cookie});
    
    const [members, setMembers] = useState<API_Organisation_Member[]>()

    useMemo(() => {
      if (state.membersChanged && organisationID) {
        getOrganisationMembers(organisationID);
      }
    }, [state.membersChanged]);


    // useEffect(() => {
    //     if(accountID){
    //         fetchUser(accountID)
    //     }
    // }, [])

    // useEffect(() => {
    //     if(user){
    //         const m: API_Organisation_Member = {
    //             account_id: user.account_id,
    //             org_id: organisationID,
    //             profile: user
    //         }
    //         if(members){
    //             setMembers([...members, m]);
    //         } else {
    //             setMembers([m]);
    //         }
    //     }
    // }, [user])

    useEffect(() => {
        if(organisationID){
            getOrganisationMembers(organisationID)
        }
    }, [organisationID])

    useEffect(() => {
        if(organisationMembers){
            
        }
    }, [organisationMembers])

    useEffect(() => {
    if (organisationMembers && organisationMembers.members) {
        const IDs = organisationMembers.members.map((member) => {
          if (member.account_id) {
              return member.account_id;
          }
        });
        if (IDs) {
          //@ts-ignore
          fetchProfilesByIDs(IDs);
        }
    }
    }, [organisationMembers]);

    useEffect(() => {
        const copy = Object.assign({}, organisationMembers)
        if(copy && ((copy.members && Array.isArray(copy.members)) || (copy.pending && Array.isArray(copy.pending)))){
          let members: API_Organisation_Member[] | undefined;
          if(copy.members){
            copy?.members?.forEach((member) => {
              const profile = publicProfiles?.filter((p) => {
                return p.account_id == member.account_id;
              });
              if (profile) {
                member.profile = profile[0];
              }
            });
            members = copy.members;
          }
          if(copy.pending){
            if(members){
              members = [...members, ...copy.pending];
            } else {
              members = copy.pending;
            }
          }
          if(members){
            setMembers(members);
          }
        }
    }, [organisationMembers, publicProfiles]);

  return (
    <div>
      {members && (
        <div className={`${styles["members-list"]} ${scrollable ? styles['scrollable'] : ''}`}>
          {members.map((member, i) => {
            return (
              <Member member={member} ownerID={ownerID} key={`member-list-${i}`} isOwner={accountID == ownerID} handleTooltipClick={handleTooltipClick} accountID={accountID}/>
            );
          })}
        </div>
      )}
      {organisationMembersError && (
        <Warning><span>{getLanguageLabel('error_loading_data_try_again_later')}</span></Warning>
      )}
      {(!members || members.length === 0) && (
        <div className={styles['no-members']}>
            <p>{getLanguageLabel('no_members')}</p>
        </div>
      )}
    </div>
  );
};

export default OrganisationMembersList;
