import React, { CSSProperties, useEffect, useRef, useState } from "react";
import tinycolor from "tinycolor2";
import Member from "./Member";
import styles from "../organisationpublicpage.module.scss";

type MembersListProps = {
  members?: API_Organisation_Member[];
  cookie: string;
};

const MembersList = ({ members, cookie }: MembersListProps) => {
  return (
    <div className={styles['members-list']}>
        {members?.map((member, i) => {
            return <Member member={member} key={`member-${i}-${member.account_id}`} cookie={cookie}/>
        })}
    </div>
  )
};

export default MembersList;
