import React, {
  CSSProperties,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./selectedorganisationmodal.module.scss";
import tinycolor from "tinycolor2";
import {
  ConfirmModal,
  DeleteModal,
  DismissModal,
  EmailInput,
  HaiaLoader,
  ProfileImage,
  useLanguage,
  Warning,
} from "@haia/haia-components";
import { useOrganisation } from "../../../API/organisation";

type SelectedOrganisationModalLabels = {
  error_occurred_while_fetching_organisation: string;
  not_your_organisation: string;
  create_new_organisation: string;
  this_organisation_is_invite_only_please_contact_team_admin: string;
};

type SelectedOrganisationModalProps = {
  organisation: string;
  onClose: () => void;
  cookie: string;
  navigateToCreateOrg: () => void;
};

const SelectedOrganisationModal = ({
  organisation,
  onClose,
  cookie,
  navigateToCreateOrg
}: SelectedOrganisationModalProps) => {

  const { getLanguageLabel } = useLanguage();

    const {fetchOrganisation, fetchedOrganisation, fetchedOrganisationLoading, fetchedOrganisationError} = useOrganisation({cookie: cookie})

    useEffect(() => {
        fetchOrganisation(organisation)
    }, [])

  return (
    <DismissModal title={""} onClose={onClose} showHeader={false}>
      <section className={`${styles["modal-content"]} ${styles["padded"]}`}>
        {fetchedOrganisationLoading ? (
          <HaiaLoader />
        ) : (
          <>
            {fetchedOrganisationError && (
              <Warning>
                <span>
                  {getLanguageLabel('error_occurred_while_fetching_organisation')}
                </span>
              </Warning>
            )}
            {!fetchedOrganisationError && (
              <>
                <div className={styles['header']}>
                  <h2>{fetchedOrganisation?.name}</h2>
                  <ProfileImage image={fetchedOrganisation?.logo_url} type={'square'} backgroundColour={fetchedOrganisation?.tertiary_colour}/>
                </div>
                <p>{getLanguageLabel('this_organisation_is_invite_only_please_contact_team_admin')}</p>
                <hr/>
                <p>{getLanguageLabel('not_your_organisation')} <a onClick={navigateToCreateOrg}>{getLanguageLabel('create_new_organisation')}</a></p>
              </>
            )}
          </>
        )}
      </section>
    </DismissModal>
  );
};

export default SelectedOrganisationModal;
