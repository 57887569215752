import { useLanguage } from "@haia/haia-components";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import tinycolor from "tinycolor2";
import styles from "./organisationpublicpage.module.scss";
import { hasContactDetails } from "./utils";

type OrganisationPublicPageNavBarProps = {
  organisation?: API_Organisation;
  upcomingEvents?: API_Event[];
  members?: API_Organisation_Member[];
  isLight?: boolean;
  onClick?: (section: PublicPageSections) => void;
};

const OrganisationPublicPageNavBar =
  ({organisation, upcomingEvents, members, isLight = false, onClick}: OrganisationPublicPageNavBarProps) => {

     const { getLanguageLabel } = useLanguage();

    const [style, setStyle] = useState<CSSProperties>()

    useEffect(() => {
        if(organisation){
            const s: CSSProperties = {}
            if(organisation.primary_colour){
                s.backgroundColor = organisation.primary_colour
            }
            setStyle(s);
        }
    }, [organisation])

    const handleClick = (section: PublicPageSections) => (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if(onClick){
        onClick(section);
      }
    }


    return (
      <nav
        className={`${styles["nav-bar"]} ${isLight ? styles["light"] : ""}`}
        style={style}
      >
        <ul>
          {organisation?.bio && (
            <li onClick={handleClick("about")} title={getLanguageLabel('about')} aria-label={getLanguageLabel('about')}>
              <i className="fa-regular fa-circle-info"></i> <span>{getLanguageLabel('about')}</span>
            </li>
          )}
          {upcomingEvents && upcomingEvents.length > 0 && (
            <li onClick={handleClick("events")} title={getLanguageLabel('upcoming_events')} aria-label={getLanguageLabel('upcoming_events')}>
              <i className="fa-light fa-ticket"></i>
              <span>{getLanguageLabel('upcoming_events')}</span>
            </li>
          )}
          {members && members.length > 0 && (
            <li onClick={handleClick("members")} title={getLanguageLabel('members')} aria-label={getLanguageLabel('members')}>
              <i className="fa-light fa-users"></i> <span>{getLanguageLabel('members')}</span>
            </li>
          )}
          {hasContactDetails(organisation) && (
            <li onClick={handleClick("contact")} title={getLanguageLabel('contact')} aria-label={getLanguageLabel('contact')}>
              <i className="fa-light fa-megaphone"></i> <span>{getLanguageLabel('contact')}</span>
            </li>
          )}
        </ul>
      </nav>
    );
  };

export default OrganisationPublicPageNavBar;
