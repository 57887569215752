import { NavBar, NavLink, PrivateRoute, ThemeContext, useLanguage } from "@haia/haia-components";
import React, { useContext, useState } from "react";
import CreateUser from "../createUser";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "../route-error-page";
import {
  Route,
  Routes,
} from "react-router-dom";
import { faTicket } from "@fortawesome/pro-duotone-svg-icons";
import { faUserHeadset, faUsers } from "@fortawesome/pro-solid-svg-icons";
import Profile from "../profile";
import OrganisationInvitePage from "../Organisation/OrganisationInvitePage";
import OrganisationMemberInvitePage from "../Organisation/OrganisationMemberInvitePage";
import UserProfilePage from "../UserProfile/UserProfilePage";
import OrganisationPublicPage from "../Organisation/OrganisationPublicPage";

//@ts-ignore
const { PROFILE_URL } = window["runConfig"] ? window["runConfig"]
  : "https://profile-api.dev.haia.live/api";
//@ts-ignore
const { PROFILE_API_URL } = window["runConfig"] ? window["runConfig"]
  : "https://event-api.dev.haia.live/api";
  //@ts-ignore
const { ACCOUNT_URL } = window["runConfig"] ? window["runConfig"]
  : "https://accounts.dev.haia.live";


type ContentContainerProps = {
  noUser?: boolean;
  user: API_Public_Profile;
  profileProps: any;
  cookie: string;
  jwt: string;
  propsLoaded?: boolean;
  isAuthenticated?: boolean;
  resolveUserNotFound: Function;
  updateLang: (lang: string) => void;
  languages: any;
  language: string
  availableLanguages: any;
  profileError?: boolean;
};

const ContentContainer = ({
  noUser = false,
  user,
  profileProps,
  cookie,
  jwt,
  propsLoaded = false,
  isAuthenticated = false,
  resolveUserNotFound,
  updateLang,
  languages,
  language,
  availableLanguages,
  profileError = false
}: ContentContainerProps) => {
  const { getLanguageLabel } = useLanguage();
  const { state: themeState } = useContext(ThemeContext);

    const [appSlide, setAppSlide] = useState<AppSlide>();

    const onAppSlideChange = (slide?: AppSlide) => {
      setAppSlide(slide);
    };

    const handleNavButtonClick = (action: NavMenuActions) => {
      
    };

  const NavBarLinks = () => {
    return (
      <>
        {themeState.currentTheme &&
          themeState.currentTheme.nav_items &&
          themeState.currentTheme.nav_items.map((i, index) => {
            return (
              <NavLink
                url={i.url ? i.url : ""}
                icon={i.icon}
                label={getLanguageLabel(`${i.label}`)}
                themed={true}
                key={`nav-bar-link-${index}`}
              />
            );
          })}
        <NavLink
          url="/events"
          icon={faTicket}
          label={getLanguageLabel("my_events")}
        />
        <NavLink
          url="/meets"
          icon={faUsers}
          label={getLanguageLabel("start_quick_session")}
        />
        <NavLink
          url="https://www.haia.live/support"
          icon={faUserHeadset}
          label={getLanguageLabel("help_and_support")}
        />
      </>
    );
  };

  return (
    <div>
      <NavBar
        logo={themeState.currentTheme?.logo}
        user={user}
        setLanguage={updateLang}
        language={languages[language] || languages["en"]}
        languages={availableLanguages}
        currentLanguage={language || "en"}
        accountURL={ACCOUNT_URL}
        profileURL={PROFILE_URL}
        url={''}
        backLabel={"back"}
        logoLink={
          themeState.currentTheme && themeState.currentTheme.logo_url
            ? themeState.currentTheme?.logo_url
            : "/events"
        }
        profileError={profileError}
        isLoggedIn={isAuthenticated}
        appSlide={appSlide}
        changeAppSlide={onAppSlideChange}
        profileAPI={PROFILE_API_URL}
        handleNavButtonClick={handleNavButtonClick}
      >
        <NavBarLinks />
      </NavBar>
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        {noUser ? (
          <CreateUser
            resolveUserNotFound={resolveUserNotFound}
            cookie={cookie}
            jwt={jwt}
          />
        ) : (
          <>
            <Routes>
              {propsLoaded && (
                <>
                  <Route
                    path="/"
                    element={
                      //@ts-ignore
                      <PrivateRoute
                        isAuthenticated={isAuthenticated}
                        redirectURL={`${ACCOUNT_URL}/authenticate?redirect_url=${window.location.href}`}
                      >
                        <Profile {...profileProps} />
                      </PrivateRoute>
                    }
                  >
                    <Route path=":tab" element={<Profile {...profileProps} />}>
                      <Route
                        path=":modal"
                        element={<Profile {...profileProps} />}
                      >
                        <Route
                          path=":plan"
                          element={<Profile {...profileProps} />}
                        />
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    path={"/invite/sponsor"}
                    element={
                      //@ts-ignore
                      <PrivateRoute
                        isAuthenticated={isAuthenticated}
                        redirectURL={`${ACCOUNT_URL}/authenticate?redirect_url=${encodeURIComponent(
                          window.location.href
                        )}`}
                      >
                        <OrganisationInvitePage
                          cookie={cookie}
                          user={user}
                          type={"sponsor"}
                        />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={"/invite/exhibitor"}
                    element={
                      //@ts-ignore
                      <PrivateRoute
                        isAuthenticated={isAuthenticated}
                        redirectURL={`${ACCOUNT_URL}/authenticate?redirect_url=${encodeURIComponent(
                          window.location.href
                        )}`}
                      >
                        <OrganisationInvitePage
                          cookie={cookie}
                          user={user}
                          type={"exhibitor"}
                        />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={"/invite/member"}
                    element={
                      //@ts-ignore
                      <PrivateRoute
                        isAuthenticated={isAuthenticated}
                        redirectURL={`${ACCOUNT_URL}/authenticate?redirect_url=${window.location.href}`}
                      >
                        <OrganisationMemberInvitePage
                          cookie={cookie}
                          user={user}
                        />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={"/profile/:id"}
                    element={
                      //@ts-ignore
                      <PrivateRoute
                        isAuthenticated={isAuthenticated}
                        redirectURL={`${ACCOUNT_URL}/authenticate?redirect_url=${window.location.href}`}
                      >
                        <UserProfilePage cookie={cookie} currentUser={user} />
                      </PrivateRoute>
                    }
                  />
                </>
              )}
              <Route
                path="/organisation/:organisationID"
                element={<OrganisationPublicPage cookie={cookie} />}
              />
              {propsLoaded && <Route path="*" element={<ErrorPage />} />}
            </Routes>
          </>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default ContentContainer