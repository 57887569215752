import React, { useEffect, useState, useRef, CSSProperties } from "react";
import styles from "./backgroundpicker.module.scss";

export type PickerSelectionProps = {
    isSelected?: boolean;
    type?: SelectionType;
    selection: string;
    onSelection?: (selection: string, type: SelectionType) => void;
}

const PickerSelection = ({
  isSelected = false,
  type = "background",
  selection,
  onSelection
}: PickerSelectionProps) => {
  const clickHandler = () => {
    if (onSelection) {
      onSelection(selection, type);
    }
  };

  return (
    <div className={styles['picker-selection-container']} onClick={clickHandler}>
        {type === "background" && (
            <img className={`${styles['selection']} ${styles[`${type}`]} ${isSelected ? styles['selected'] : ''}`} key={`selection-${selection}-${type}`} src={selection}/>
        )}
        {type === 'colour' && (
            <div className={`${styles['selection']} ${styles[`${type}`]} ${isSelected ? styles['selected'] : ''}`} key={`selection-${selection}-${type}`} style={{background: `${selection}`}} data-colour={selection}></div>
        )}
        {isSelected && (
            <div className={`${styles['isSelected']} ${styles[type]}`} data-colour={selection}>
                <i className="fa-solid fa-circle-check"/>
            </div>
        )}
    </div>
  );
};

export default PickerSelection;
