import React, { useEffect, useRef, useState } from 'react';
import PopUp from './PopUp';
import './style.scss';

type InfoPopUpProps = PopUpProps & {
  type: PopupType;
};

const InfoPopup = (props: InfoPopUpProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const { type } = props;
  const divRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    
  }, []);
  const iconString = () => {
    switch (type) {
      case 'warning':
        return '!';
      case 'information':
        return '?';
      default:
        return '!';
    }
  };
  const togglePopup = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    divRef.current?.focus();
    setShowPopup(!showPopup);
  };
  const handleOnBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowPopup(false);
    }
  };
  return (
    <div
      className={`info-popup ${type}`}
      onClick={togglePopup}
      data-cy='info-popup'
      ref={divRef}
      tabIndex={0}
      onBlur={handleOnBlur}
    >
      {type === 'information' ? <i className='fa-duotone fa-circle-info' /> : <span className='icon' data-cy='icon'>
        {iconString()}
      </span>}
      {showPopup && <PopUp {...props} />}
    </div>
  );
};

export default InfoPopup;
