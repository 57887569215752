import { ErrorPage, HaiaLoader, Spinner, SelectionDropDown, useLanguage, Warning } from "@haia/haia-components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEvent } from "../../../API/event";
import { useOrganisation } from "../../../API/organisation";
import { useGetUser } from "../../../API/profile";
import LanguageContext from "../../../Context/Language/LanguageContext";
import { getFriendlyEventDuration } from "../../../utils/utils";
import styles from './organisationinvitepage.module.scss'

//@ts-ignore
const { ORGANISATIONS_API_URL } = window["runConfig"] ? window["runConfig"] : "https://event.dev.haia.live";
//@ts-ignore
const { EVENT_URL } = window["runConfig"] ? window["runConfig"] : "https://event.dev.haia.live";

type InviteType = "sponsor" | "exhibitor"


type OrganisationInvitePageProps = {
  cookie: string;
  user: API_Public_Profile;
  type: InviteType
};

const OrganisationInvitePage = ({ cookie, user, type }: OrganisationInvitePageProps) => {
  
    const { getLanguageLabel } = useLanguage();

    const [searchParams, setSearchParams] = useSearchParams();
    const inviteID = searchParams.get("invite_id");
    const eventID = searchParams.get("event_id");

    const navigate = useNavigate();

    const {
      getEvent,
      event, 
      eventLoading, 
      declineInviteError,
      declineInviteLoading, 
      declineSponsorInvite, 
      acceptInviteError, 
      acceptInviteLoading, 
      acceptSponsorInvite, 
      getSponsorInvite, 
      sponsorInvite, 
      sponsorInviteLoading, 
      sponsorInviteError, 
      getExhibitorInvite,
      exhibitorInvite, 
      exhibitorInviteError, 
      exhibitorInviteLoading,
      acceptExhibitorInvite,
      declineExhibitorInvite
    } = useEvent({cookie: cookie})
    const {getUserOrganisations, userOrganisations, userOrganisationsLoading} = useOrganisation({cookie: cookie})

    const [filteredOrganisations, setFilteredOrganisations] = useState<API_Organisation[]>();
    const [selectedOrganisation, setSelectedOrganisation] = useState<API_Organisation>();
    const [error, setError] = useState<string>()
    const [paramError, setParamError] = useState(false)
    const [accessError, setAccessError] = useState(false)
    const [requestError, setRequestError] = useState(false)

    useEffect(() => {
      if(sponsorInvite && user && eventID){
        if (eventID !== sponsorInvite.event_id.toString()) {
          setAccessError(true);
        } else {
          setAccessError(false);
        }
      }
      if(exhibitorInvite && user && eventID){
        if(eventID !== exhibitorInvite.event_id.toString()) {
          setAccessError(true);
        } else {
          setAccessError(false);
        }
      }
    }, [sponsorInvite, exhibitorInvite, user, eventID])

    useEffect(() => {
      if(userOrganisationsLoading === false){
        setFilteredOrganisations(userOrganisations?.member_of)
      }
    }, [userOrganisationsLoading])


    //--- getting sponsor or exhibitor invite to check existence ---
    useEffect(() => {
      if (inviteID && eventID) {
        switch(type){
          case 'exhibitor':
            getExhibitorInvite(inviteID, eventID);
            break;
          case 'sponsor':
            getSponsorInvite(inviteID, eventID);
            break;
        }
      }
    }, [inviteID, eventID]);

    useEffect(() => {
      if(!inviteID || !eventID || (type !== "sponsor" && type !== "exhibitor")){
        setParamError(true)
      } else {
        setParamError(false)
      }
    }, [inviteID, eventID, type])


    //--- if sponsor or exhibitor exists, get event and user orgs ---
    useEffect(() => {
      if (eventID) {
        switch(type){
          case 'exhibitor':
            if(exhibitorInvite){
              getEvent(eventID);
              getUserOrganisations();
            }
            break;
          case 'sponsor':
            if(sponsorInvite){
              getEvent(eventID);
              getUserOrganisations();
            }
            break;
        }
      }
    }, [sponsorInvite, exhibitorInvite, type])


    useEffect(() => {
      if(acceptInviteLoading === false || declineInviteLoading === false){
        if(acceptInviteError || declineInviteError){
          setRequestError(true);
        } else {
          setRequestError(false);
          navigate("/organisation");
        }
      }
    }, [acceptInviteLoading, declineInviteLoading])

    const navigateToCreateOrg = () => {
      navigate(`/organisation?stage=new&redirect_url=${encodeURIComponent(window.location.href)}`);
    };

    const onOrganisationSelected = (value: string) => {
      if (userOrganisations && userOrganisations.member_of) {
        const filter = userOrganisations.member_of.filter((org) => {
          return org.id === value;
        });
        setError(undefined);
        setSelectedOrganisation(filter[0]);
      }
    };

    const onOrganisationSearch = (value?: string) => {
      if (value && value.length > 1) {
        if (userOrganisations && userOrganisations?.member_of) {
          const filtered = userOrganisations.member_of.filter((org) => {
            return org.name.toLowerCase().includes(value.toLowerCase());
          });
          setFilteredOrganisations(filtered);
        }
      } else {
       setFilteredOrganisations(userOrganisations?.member_of);
      }
    };

    const onCancelHandler = () => {
      if(event && eventID && inviteID){
        switch(type){
          case 'exhibitor':
            declineExhibitorInvite(eventID, parseInt(inviteID));
            break;
          case 'sponsor':
            declineSponsorInvite(eventID, parseInt(inviteID));
            break;
        }
      }
    }

    const onContinueHandler = () => {
      if (event && eventID && selectedOrganisation && selectedOrganisation.id && inviteID) {
        switch (type) {
          case "exhibitor":
            acceptExhibitorInvite(eventID, selectedOrganisation.id, parseInt(inviteID));
            break;
          case "sponsor":
            acceptSponsorInvite(eventID, selectedOrganisation.id, parseInt(inviteID));
            break;
        }
      } else if(!selectedOrganisation){
        setError(getLanguageLabel('please_select_organisation'))
      }
    }

  return (
    <div className={styles["content"]}>
      <main>
        {paramError && (
          <div className={styles["error"]}>
            <ErrorPage
              firstImage={
                "https://haia-icons.s3.eu-west-2.amazonaws.com/error/error-oops.svg"
              }
              title={getLanguageLabel("missing_url_parameters")}
            />
          </div>
        )}
        {accessError && (
          <div className={styles["error"]}>
            <ErrorPage
              firstImage={
                "https://haia-icons.s3.eu-west-2.amazonaws.com/error/error-permission.svg"
              }
              title={getLanguageLabel(
                "you_do_not_have_permission_to_access_this_invite"
              )}
            />
          </div>
        )}
        {event !== undefined &&
          accessError === false &&
          paramError === false && (
            <>
              {((exhibitorInvite && !exhibitorInvite.accepted) ||
                (sponsorInvite && !sponsorInvite.accepted)) && (
                <>
                  <div className={styles["inner"]}>
                    {type === "sponsor" && (
                      <h2>
                        {getLanguageLabel(
                          "you_have_been_invited_to_sponsor_an_event"
                        )}
                        :
                      </h2>
                    )}
                    {type === "exhibitor" && (
                      <h2>
                        {getLanguageLabel(
                          "you_have_been_invited_to_exhibit_an_event"
                        )}
                        :
                      </h2>
                    )}
                    <h3>
                      <a
                        href={`${EVENT_URL}/event/${event.public_id}`}
                        target={"_blank"}
                      >
                        {event.name}
                      </a>
                    </h3>
                    {type === "sponsor" && (
                      <p>
                        {getLanguageLabel(
                          "please_choose_from_the_list_of_organisations_you_are_a_member_of_to_assign_as_a_sponsor_for_this_event"
                        )}
                      </p>
                    )}
                    {type === "exhibitor" && (
                      <p>
                        {getLanguageLabel(
                          "please_choose_from_the_list_of_organisations_you_are_a_member_of_to_assign_as_an_exhibitor_for_this_event"
                        )}
                      </p>
                    )}
                    {error && (
                      <Warning>
                        <span>{error}</span>
                      </Warning>
                    )}
                    {requestError && (
                      <Warning>
                        <span>
                          {getLanguageLabel(
                            "error_while_processing_data_please_try_again"
                          )}
                        </span>
                      </Warning>
                    )}
                    {userOrganisationsLoading === true ? (
                      <Spinner />
                    ) : (
                      <div className={styles["dropdown-container"]}>
                        <SelectionDropDown
                          suggestions={
                            filteredOrganisations &&
                            filteredOrganisations.map((org) => {
                              return {
                                id: org.id,
                                text: org.name,
                                image: org.logo_url,
                                background: org.tertiary_colour
                              };
                            })
                          }
                          labels={{
                            start_typing: getLanguageLabel('start_typing'),
                            no_suggestion_please_try_another_entry: getLanguageLabel('no_suggestion_please_try_another_entry'),
                          }}
                          placeholder={"e.g. Haia Technologies Ltd"}
                          // warning={
                          //   language.if_you_see_your_organisation_below_please_contact_your_team_admin_to_request_an_invite
                          // }
                          noResultsClick={navigateToCreateOrg}
                          noResultsClickLabel={
                            getLanguageLabel('create_new_organisation')
                          }
                          noResultsLabel={
                            getLanguageLabel('not_your_organisation')
                          }
                          valueChange={onOrganisationSearch}
                          onSuggestionClick={onOrganisationSelected}
                          selected={
                            selectedOrganisation && {
                              id: selectedOrganisation?.id,
                              text: selectedOrganisation?.name,
                              image: selectedOrganisation?.logo_url,
                              background: selectedOrganisation.tertiary_colour,
                            }
                          }
                        />
                      </div>
                    )}
                    <p>
                      {getLanguageLabel("organisation_not_listed")}?{" "}
                      <a
                        href={`/organisation?stage=new&redirect_url=${encodeURIComponent(
                          window.location.href
                        )}`}
                        onClick={navigateToCreateOrg}
                      >
                        {getLanguageLabel("create_new_organisation")}
                      </a>
                    </p>
                  </div>
                  <footer>
                    <button onClick={onCancelHandler} className={styles["red"]}>
                      {getLanguageLabel("decline_invite")}
                    </button>
                    <button
                      className={styles["green"]}
                      onClick={onContinueHandler}
                    >
                      {getLanguageLabel("confirm")}
                    </button>
                  </footer>
                </>
              )}
            </>
          )}
        {((!sponsorInvite && sponsorInviteLoading === false) ||
          (!exhibitorInvite && exhibitorInviteLoading === false)) &&
          !paramError &&
          !accessError && (
            <div className={styles["error"]}>
              <ErrorPage
                firstImage={
                  "https://haia-icons.s3.eu-west-2.amazonaws.com/error/error-404.svg"
                }
                title={
                  getLanguageLabel("unable_to_find_invite_are_you_logged_in_to_the_correct_account")
                   
                }
              />
            </div>
          )}
        {sponsorInvite &&
          sponsorInvite.accepted &&
          !paramError &&
          !accessError && (
            <div className={`${styles["inner"]} ${styles["center"]}`}>
              <h2>{getLanguageLabel("invite_has_been_accepted")}</h2>
              <footer>
                <button onClick={onCancelHandler} className={styles["red"]}>
                  {getLanguageLabel("decline_invite")}
                </button>
              </footer>
            </div>
          )}
        {exhibitorInvite &&
          exhibitorInvite.accepted &&
          !paramError &&
          !accessError && (
            <div className={`${styles["inner"]} ${styles["center"]}`}>
              <h2>{getLanguageLabel("invite_has_been_accepted")}</h2>
              <footer>
                <button onClick={onCancelHandler} className={styles["red"]}>
                  {getLanguageLabel("decline_invite")}
                </button>
              </footer>
            </div>
          )}
        {(eventLoading === true ||
          sponsorInviteLoading === true ||
          userOrganisationsLoading === true ||
          exhibitorInviteLoading === true) && <HaiaLoader />}
      </main>
    </div>
  );
};

export default OrganisationInvitePage;
