//--- STATE ---

export type OrganiserContextState = {
  currentImageCropCallback?: (blob?: Blob) => void;
  currentAddMemberCallback?: (member: string) => void;
  membersChanged?: number;
};

//--- ACTIONS ---

export enum ActionType {
  ChangeImageCropCallback,
  ChangeAddMemberCallback,
  ChangeMembersChanged,
}

export interface ChangeImageCropCallback {
  type: ActionType.ChangeImageCropCallback;
  payload: (blob?: Blob) => void;
}

export interface ChangeAddMemberCallback {
  type: ActionType.ChangeAddMemberCallback;
  payload?: (member: string) => void;
}

export interface ChangeMembersChanged {
  type: ActionType.ChangeMembersChanged;
  payload?: number;
}

export type Actions =
  | ChangeImageCropCallback
  | ChangeAddMemberCallback
  | ChangeMembersChanged;

//--- reducer ---
export const organiserContextReducer = (
  state: OrganiserContextState,
  action: Actions
): OrganiserContextState => {
  switch (action.type) {
    case ActionType.ChangeImageCropCallback:
      return { ...state, currentImageCropCallback: action.payload };
    case ActionType.ChangeAddMemberCallback:
      return {...state, currentAddMemberCallback: action.payload};
    case ActionType.ChangeMembersChanged:
      return {...state, membersChanged: action.payload};
    default:
      return state;
  }
};

//--- initial state of the reducer ---
export const initialOrganiserContextState: OrganiserContextState = {};

//--- helper functions to craft action ---
export const changeImageCropCallback = (callback: (blob?: Blob) => void): ChangeImageCropCallback => ({
  type: ActionType.ChangeImageCropCallback,
  payload: callback,
});

export const changeAddMemberCallback = (callback?: (member: string) => void): ChangeAddMemberCallback => ({
  type: ActionType.ChangeAddMemberCallback,
  payload: callback,
});

export const changeMembersChanged = (change: number): ChangeMembersChanged => ({
  type: ActionType.ChangeMembersChanged,
  payload: change,
});