import { useLanguage } from '@haia/haia-components';
import React from 'react';
import { Loader } from 'semantic-ui-react';

type SubscriptionDetailsProps = {
  className: string;
  subscription?: Subscription;
  callBack: () => void;
  nextSubscription?: Subscription;
  renewal?: boolean
};

const SubscriptionDetails = ({
  className,
  subscription,
  callBack,
  nextSubscription,
  renewal = false
}: SubscriptionDetailsProps) => {

  const { getLanguageLabel } = useLanguage();

  if(subscription){
  const { name, cost, endDate } = subscription;
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    callBack();
  };
  return (
    <div className={className}>
      <div className="flex-row between">
        <p className="subscription_subscription-type-container-plan__-tint">
          {name}
        </p>
        <p className="subscription_subscription-type-container-plan__-tint">
          {cost > 0 && <span>&pound;{cost} pcm</span>}
        </p>
      </div>
      <p>
        {renewal
          ? getLanguageLabel("renewing_on")
          : getLanguageLabel("ending_on")}
        : {endDate}.
      </p>
      <p>
        <a href="#" onClick={handleClick}>
          {getLanguageLabel('see_full_features_and_benefits')}
        </a>
      </p>
      {nextSubscription && (
        <p>
          <strong>Next Subscription:</strong> {nextSubscription.name} &pound;
          {nextSubscription.cost} pcm
        </p>
      )}
    </div>
  );
      }
  return <Loader />
};

export default SubscriptionDetails;
