import React, { useEffect, useState, useRef } from "react";
import sectionStyles from "./style.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import { BackgroundPicker, ImageCrop, useLanguage } from "@haia/haia-components";
import { generateRandomAvatar } from "../../utils/utils";
import { ProfileImage } from "@haia/haia-components";
import Loader from "../loader";

type AvatarType = "circle" | "square";

type CoverSectionLabels = {
  tip: string;
  avoid_using_images_containing_text: string;
};

type CoverSectionProps = {
  image?: string;
  onImageSelection?: (selectedImage?: string, key?: string, needCrop?: boolean) => void;
  loading?: boolean;
  type?: AvatarType;
  imageKey?: string;
  selectedImage?: string;
  setSelectedImage: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const CoverSection = ({loading = false, type = "circle", imageKey, selectedImage, setSelectedImage, image, onImageSelection}: CoverSectionProps) => {

    const { getLanguageLabel } = useLanguage();

    const profilePictureInput = useRef<HTMLInputElement>(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const [isDark, setIsDark] = useState(true);

    const [backgroundTab, setBackgroundTab] = useState("colour");
    const [showBackgroundPicker, setShowBackgroundPicker] = useState(false);

    const pickerRef = useRef<HTMLDivElement>(null);

    const [img, setImg] = useState('https://storage.googleapis.com/haia-live/org-cover.png')

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.files){
             let profileFile = e.target.files[0];
             if (profileFile) {
               const reader = new FileReader();
               reader.addEventListener("load", () =>
                 setSelectedImage(reader.result?.toString() || "")
               );
               reader.readAsDataURL(profileFile);
               setShowCropModal(true);
             }
        }
    };
    

    useEffect(() => {
      if (selectedImage) {
        if (onImageSelection) {
          onImageSelection(selectedImage, imageKey);
        }
      } else {
        if (profilePictureInput.current) {
          //@ts-ignore
          profilePictureInput.current.value = null;
        }
      }
    }, [selectedImage]);

    useEffect(() => {
      if(image){
        setImg(image);
      }
    }, [image])

    const onBackgroundTabClick = (tab: string) => () => {
      setBackgroundTab(tab);
    }

    const toggleBackgroundPickerClick = () =>{
      setShowBackgroundPicker(!showBackgroundPicker);
    }

    useEffect(() => {
      if(showBackgroundPicker && pickerRef && pickerRef.current){
        // pickerRef.current?.scrollIntoView({ behavior: "smooth" });
        const {bottom, left} = pickerRef.current.getBoundingClientRect();
        window.scrollTo(left, bottom)
      }
    }, [showBackgroundPicker, pickerRef])

    const onBackgroundPickerClose = () => {
      setShowBackgroundPicker(false);
    };
    const onBackgroundSelection = (selection: string) => {
     if(onImageSelection){
      onImageSelection(selection, imageKey, false);
      setShowBackgroundPicker(false);
     }
    };

  


  return (
    <div className={sectionStyles["cover-container"]}>
      <div className={sectionStyles["background-toggle-container"]}>
        <div className={sectionStyles["background-toggle"]}>
          <div
            className={`${sectionStyles["tab"]} ${
              backgroundTab === "colour" ? sectionStyles["selected"] : ""
            }`}
            onClick={onBackgroundTabClick("colour")}
          >
            {getLanguageLabel('colour')}
          </div>
          <div
            className={`${sectionStyles["tab"]} ${
              backgroundTab === "image" ? sectionStyles["selected"] : ""
            }`}
            onClick={onBackgroundTabClick("image")}
          >
            {getLanguageLabel('image')}
          </div>
        </div>
        <br />
        <div className={sectionStyles["tab-content"]}>
          {backgroundTab === "colour" && (
            <div className={sectionStyles["tab-inner"]}>
              <p>{getLanguageLabel('change_colour')}</p>
              <div className={sectionStyles["choose-background"]}>
                {img ? (
                  <>
                    {img.includes("http") && img.includes("haia-cards") ? (
                      <>
                        <img
                          src={img}
                          className={
                            sectionStyles["choose-background-indicator"]
                          }
                          onClick={toggleBackgroundPickerClick}
                        />
                      </>
                    ) : (
                      <>
                        <div
                          className={
                            sectionStyles["choose-background-indicator"]
                          }
                          style={{
                            background: img,
                          }}
                          onClick={toggleBackgroundPickerClick}
                        ></div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      className={sectionStyles["choose-background-indicator"]}
                      onClick={toggleBackgroundPickerClick}
                    ></div>
                  </>
                )}
              </div>
              {showBackgroundPicker && (
                <BackgroundPicker
                  labels={{
                    image: getLanguageLabel("image"),
                    colour: getLanguageLabel("colour"),
                    cancel: getLanguageLabel("cancel"),
                    apply: getLanguageLabel("apply"),
                    custom: getLanguageLabel("custom"),
                    enter_a_hex_value: getLanguageLabel("enter_a_hex_value"),
                  }}
                  onSelection={onBackgroundSelection}
                  onClose={onBackgroundPickerClose}
                  additionalStyles={{
                    top: `50%`,
                    left: `100%`,
                    transform: `translate(0, -50%)`,
                  }}
                  tooltipSide={"left"}
                  allowCustom={true}
                />
              )}
            </div>
          )}
          {backgroundTab === "image" && (
            <div className={sectionStyles["image-upload-container"]}>
              <p>{getLanguageLabel('change_image')}</p>
              <label
                htmlFor="background_file"
                className={sectionStyles["upload-icon"]}
              >
                <i className="fa-solid fa-upload"></i>
              </label>

              <input
                id="background_file"
                type="file"
                name="background_url"
                accept="image/png,image/jpeg"
                onChange={handleFileChange}
              ></input>
            </div>
          )}
        </div>
      </div>
      <p className={sectionStyles["warning"]}>
        {getLanguageLabel('tip')}: {getLanguageLabel('avoid_using_images_containing_text')}
      </p>
      <div className={sectionStyles["image-selector-container"]}>
        {img.includes("http") ? (
          <img className={sectionStyles["cover-image"]} src={img}></img>
        ) : (
          <div
            className={sectionStyles["cover-image"]}
            style={{ background: img }}
          ></div>
        )}
        {/* {!loading && (
          <label
            className={`${sectionStyles["avatar-label"]} ${
              sectionStyles[type]
            } ${isDark ? sectionStyles["dark"] : ""}`}
          >
            <FontAwesomeIcon
              icon={faCamera}
              className={sectionStyles["avatar-label-icon"]}
            />
            <p>Change</p>
            <input
              type="file"
              id="profile_picture"
              onChange={handleFileChange}
              ref={profilePictureInput}
              className={sectionStyles["hidden"]}
              accept={"image/*"}
            ></input>
          </label>
        )} */}
        {loading && (
          <span className={sectionStyles["loader-container"]}>
            <Loader />
          </span>
        )}
      </div>
    </div>
  );
};

export default CoverSection;
