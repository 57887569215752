import { useLanguage } from "@haia/haia-components";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "../organisationpublicpage.module.scss";
import { getDateCountdown, getFriendlyDate } from "../utils";

//@ts-ignore
const { EVENT_URL } = window["runConfig"] ? window["runConfig"] : "https://event.dev.haia.live";

type EventProps = {
    event: API_Event;
    organisation: API_Organisation;
};

const Event = ({event, organisation}: EventProps) => {

  const { getLanguageLabel } = useLanguage();

  const [dateCountdownLabel, setDateCountdownLabel] = useState("")
  const [dateCountdown, setDateCountdown] = useState(0)

  useEffect(() => {
    if(event.start_date){
      const {label, diff} = getDateCountdown(event.start_date, {in_days: getLanguageLabel('in_days'), tomorrow: getLanguageLabel('tomrrow'), today: getLanguageLabel('today'), daysAgo: getLanguageLabel("days_ago")});
      setDateCountdownLabel(label);
      setDateCountdown(diff);
    }
  }, [event.start_date])



  return (
    <a
      target="_blank"
      className={styles["event"]}
      href={`${EVENT_URL}/event/${event.public_id}`}
    >
      <div className={styles["event-header"]}>
        {event.banner_url ? (
          <img style={{background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5)), url(${event.banner_url})`}} />
        ) : (
          <div
            className={styles["gradient-inner"]}
            style={{ backgroundColor: organisation.primary_colour }}
          >
            <div className={styles["gradient-container"]}></div>
          </div>
        )}
    
        <p>{event.name}</p>
      </div>
      <div
        className={`${styles["event-footer"]} ${
          dateCountdown === 1 ? styles["green"] : ""
        } ${dateCountdown === 0 ? styles["red"] : ""}`}
      >
        <div className={styles["left"]}>
          <p>
            <i className="fa-duotone fa-calendar"></i>{" "}
            {getFriendlyDate(event.start_date, {months: getLanguageLabel("months"), date_units: getLanguageLabel("date_units")})}
          </p>
        </div>
        <div className={styles["right"]}>
          <p>{dateCountdownLabel}</p>
        </div>
      </div>
    </a>
  );
};

export default Event;
