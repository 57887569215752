import React, { CSSProperties, useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./organisationmembersmodal.module.scss";
import tinycolor from "tinycolor2";
import { ConfirmModal, DeleteModal, DismissModal, EmailInput, ProfileImage, useLanguage } from "@haia/haia-components";
import OrganiserContext from "../../../Context/Organiser/OrganiserContext";
import { changeAddMemberCallback, changeMembersChanged } from "../../../Context/Organiser/reducer";
import { useGetUser } from "../../../API/profile";
import OrganisationMembersList from "./Members/MembersList";
import AddMemberModal from "../AddMemberModal";

type OrganisationMembersModalProps = {
  onClose: () => void;
  organisation: API_Organisation;
  cookie: string;
  accountID?: string;
  addMemberToOrg?: (org: API_Organisation, callback?: () => void) => void;
  removeMember?: (orgID: string, userID: string) => void;
  removeInvite?: (orgID: string, invited_email: string) => void;
};

const OrganisationMembersModal = ({
  onClose,
  organisation,
  cookie,
  accountID,
  addMemberToOrg,
  removeMember,
  removeInvite
}: OrganisationMembersModalProps) => {

   const { getLanguageLabel } = useLanguage();

  // const { state, dispatch } = useContext(OrganiserContext);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [memberToDelete, setMemberToDelete] = useState<API_Organisation_Member>()
  const [tooltipType, setTooltipType] = useState<string>()

  const [isOwner, setIsOwner] = useState(false)

  useEffect(() => {
    if(accountID && organisation){
      if(organisation.owner_id === accountID){
        setIsOwner(true);
      }
    } 
  }, [accountID, organisation])


  const onMemberInvitee = (invitee: string) => {
    const copy = Object.assign({}, organisation);
    const data: API_Organisation_Members = {members: [{invited_email: invitee}]}
    copy.members_data = data;
    if(addMemberToOrg && copy){
      addMemberToOrg(copy);
      setShowInviteModal(false);
    }
  }
  
  const onInviteModalClose = () => {
    setShowInviteModal(false);
  }

  const onInviteMembersClicked = () => {
    setShowInviteModal(true);
  }

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  }

  const onDeleteMember = () => {
    if(tooltipType && memberToDelete){
      if(tooltipType === 'remove_member'){
        if(removeMember && organisation && organisation.id && memberToDelete.account_id){
          removeMember(organisation.id, memberToDelete.account_id)
        }
      } else if(tooltipType === "remove_invite"){
        if(removeInvite && organisation && organisation.id && memberToDelete.invited_email){
          removeInvite(organisation.id, memberToDelete.invited_email)
        }
      }
    }
    setShowDeleteModal(false);
  }

  const handleTooltipClick = (type: string, member: API_Organisation_Member) => {
    switch (type) {
      case 'remove_member':
        setMemberToDelete(member);
        setTooltipType(type);
        setShowDeleteModal(true);
        break;
      case 'remove_invite':
        setMemberToDelete(member);
        setTooltipType(type);
        setShowDeleteModal(true);
        break;
    }
  };

  return (
    <>
      {isOwner ? (
        <ConfirmModal
          showHeader={false}
          title={""}
          onConfiirm={onInviteMembersClicked}
          onClose={onClose}
          confiirmLabel={getLanguageLabel("invite_more_members")}
          cancelLabel={getLanguageLabel("cancel")}
          shouldCloseOnAction={false}
        >
          <section className={`${styles["modal-content"]} ${styles["padded"]}`}>
            <div className={styles["header"]}>
              <ProfileImage
                type={"square"}
                image={organisation.logo_url}
                backgroundColour={organisation.tertiary_colour}
              />{" "}
              <h2>
                {getLanguageLabel("members")}{" "}
                <span className={styles["subtitle"]}>
                  (
                  {organisation.members_count !== undefined
                    ? organisation.members_count
                    : 0}
                  )
                </span>
              </h2>
            </div>
            <section className={styles["list-content"]}>
              <hr />
              <OrganisationMembersList
                cookie={cookie}
                organisationID={organisation.id}
                accountID={accountID}
                ownerID={organisation.owner_id}
                scrollable={true}
                handleTooltipClick={handleTooltipClick}
              />
            </section>
          </section>
        </ConfirmModal>
      ) : (
        <DismissModal
          showHeader={false}
          title={""}
          onClose={onClose}
          buttonLabel={getLanguageLabel("cancel")}
        >
          <section className={`${styles["modal-content"]} ${styles["padded"]}`}>
            <div className={styles["header"]}>
              <ProfileImage type={"square"} image={organisation.logo_url} />{" "}
              <h2>
                {getLanguageLabel("members")}{" "}
                <span className={styles["subtitle"]}>
                  (
                  {organisation.members_count !== undefined
                    ? organisation.members_count
                    : 0}
                  )
                </span>
              </h2>
            </div>
            <section className={styles["list-content"]}>
              <hr />
              <OrganisationMembersList
                cookie={cookie}
                organisationID={organisation.id}
                accountID={accountID}
                ownerID={organisation.owner_id}
                scrollable={true}
                handleTooltipClick={handleTooltipClick}
              />
            </section>
          </section>
        </DismissModal>
      )}

      {showInviteModal && (
        <AddMemberModal
          onClose={onInviteModalClose}
          onComplete={onMemberInvitee}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          title={getLanguageLabel("are_you_sure")}
          onDelete={onDeleteMember}
          onClose={onDeleteModalClose}
        >
          <div className={styles["delete-modal-content"]}>
            {tooltipType === "remove_member" && (
              <>
                {memberToDelete &&
                memberToDelete.profile &&
                memberToDelete.profile.display_name ? (
                  <p>
                    {getLanguageLabel(
                      "are_you_sure_you_wish_to_remove_XXX_as_a_member"
                    ).replace("XXX", memberToDelete.profile.display_name)}
                  </p>
                ) : (
                  <p>
                    {getLanguageLabel(
                      "are_you_sure_you_wish_to_remove_XXX_as_a_member"
                    ).replace("XXX", getLanguageLabel("this_user"))}
                  </p>
                )}
              </>
            )}
            {tooltipType === "remove_invite" && (
              <>
                {memberToDelete && memberToDelete.invited_email && (
                  <p>
                    {getLanguageLabel(
                      "are_you_sure_you_wish_to_remove_XXX_s_invite"
                    ).replace("XXX", memberToDelete.invited_email)}
                  </p>
                )}
              </>
            )}
          </div>
        </DeleteModal>
      )}
    </>
  );
};

export default OrganisationMembersModal;
