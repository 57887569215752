import axios, { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useState } from "react";
import { formatURL } from '../hooks/actions';

//@ts-ignore
const { ORGANISATIONS_API_URL } = window["runConfig"] ? window["runConfig"] : "https://organisations.dev.haia.live/api";
//@ts-ignore
const { CONNECTIONS_API } = window["runConfig"] ? window["runConfig"] : "https://connections.dev.haia.live/api";
//@ts-ignore
const { EVENT_API_URL } = window["runConfig"] ? window["runConfig"] : "https://organisations.dev.haia.live/api";

type OrganisationProps = {
  cookie: string;
};

export const useOrganisation = ({ cookie }: OrganisationProps) => {
  const [userOrganisations, setUserOrganisations] =
    useState<API_User_Organisations>();
  const [userOrganisationsLoading, setUserOrganisationsLoading] = useState<boolean>();
  const [userOrganisationsError, setUserOrganisationsError] = useState();

  const [createOrganisationError, setCreateOrganisationError] = useState()
  const [createOrganisationLoading, setCreateOrganisationLoading] = useState<boolean>();

  const [updateOrganisationError, setUpdateOrganisationError] = useState();
  const [updateOrganisationLoading, setUpdateOrganisationLoading] = useState<boolean>();

  const [addMemberError, setAddMembernError] = useState();
  const [addMemberLoading, setAddMemberLoading] = useState<boolean>();
  const [removeMemberError, setRemoveMembernError] = useState();
  const [removeMemberLoading, setRemoveMemberLoading] = useState<boolean>();


  const [fileUploadResponse, setFileUploadResponse] = useState<API_File_Response>()
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);

  const [organisationTagsLoading, setOrganisationTagsLoading] = useState<boolean>();
  const [organisationTagsError, setOrganisationTagsError] = useState();

  const [createdTag, setCreatedTag] = useState<API_Organisation_Tag>()
  const [createTagLoading, setCreateTagLoading] = useState<boolean>();
  const [createTagError, setCreateTagError] = useState();

  const [deleteOrganisationLoading, setDeleteOrganisationLoading] = useState<boolean>();
  const [deleteOrganisationError, setDeleteOrganisationError] = useState();

  const [organisationMembers, setOrganisationMembers] = useState<API_Organisation_Members>()
  const [organisationMembersLoading, setOrganisationMembersLoading] = useState<boolean>();
  const [organisationMembersError, setOrganisationMembersError] = useState();

  const [organisationSearch, setOrganisationSearch] = useState<API_Organisation[]>()
  const [organisationSearchLoading, setOrganisationSearchLoading] = useState<boolean>();
  const [organisationSearchError, setOrganisationSearchError] = useState();


  const [fetchedOrganisation, setFetchedOrganisation] =
    useState<API_Organisation>();
  const [fetchedOrganisationLoading, setFetchedOrganisationLoading] =
    useState<boolean>();
  const [fetchedOrganisationError, setFetchedOrganisationError] = useState();

  const [organisationUpcomingEvents, setOrganisationUpcomingEvents] = useState<API_Event[]>()
  const [organisationUpcomingEventsLoading, setOrganisationUpcomingEventsLoading] =
    useState<boolean>();
  const [organisationUpcomingEventsError, setOrganisationUpcomingEventsError] =
    useState();

  const [organisationEventsCount, setOrganisationEventsCount] = useState<number>()
  const [organisationEventsCountLoading, setOrganisationEventsCountLoading] =
    useState<boolean>();
  const [organisationEventsCountError, setOrganisationEventsCountError] =
    useState();

  const [acceptMemberInviteLoading, setAcceptMemberInviteLoading] = useState<boolean>()
  const [acceptMemberInviteError, setAcceptMemberInviteError] = useState()

  const [memberInvite, setMemberInvite] = useState<API_Organisation_Member>()
  const [memberInviteLoading, setMemberInviteLoading] = useState<boolean>()
  const [memberInviteError, setMemberInviteError] = useState()

  const [userEventOrganisations, setUserEventOrganisations] = useState<API_Organisation[]>()
  const [userEventOrganisationsLoading, setUserEventOrganisationsLoading] = useState<boolean>();
  const [userEventOrganisationsError, setUserEventOrganisationsError] = useState();

  const [organisationEvents, setOrganisationEvents] = useState<API_Event[]>()
  const [organisationEventsLoading, setOrganisationEventsLoading] = useState<boolean>();
  const [organisationEventsError, setOrganisationEventsError] = useState();



  const createOrganisation = useCallback(
    (org: API_Organisation) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      org.website_url = formatURL(org.website_url);
      org.facebook_url = formatURL(org.facebook_url);
      org.linked_in_url = formatURL(org.linked_in_url);


      setCreateOrganisationLoading(true);
      axios
        .post(`${ORGANISATIONS_API_URL}/organisation`, org, config)
        .catch((err) => {
          setCreateOrganisationError(err);
        })
        .finally(() => {
          setCreateOrganisationLoading(false);
        });
    },
    [cookie]
  );

  const orgCreated = () => {
    setCreateOrganisationLoading(false);
  }

  const getUserOrganisations = useCallback(
    () => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      setUserOrganisationsLoading(true);
      axios
        .get(`${ORGANISATIONS_API_URL}/organisations`, config)
        .then(({ data }) => {
          setUserOrganisations(data);
        })
        .catch((err) => {
          setUserOrganisationsError(err);
        })
        .finally(() => {
          setUserOrganisationsLoading(false);
        });
    },
    [cookie]
  );

  const addMemberToOrganisation = useCallback((org: API_Organisation, callback?: () => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    };

    if(!org.members_data || org.members_data.members?.length === 0){
      return;
    }

    setAddMemberLoading(true);
    axios
      .post(`${ORGANISATIONS_API_URL}/organisation/${org.id}/invite`, org.members_data, config)
      .catch((err) => {
        setAddMembernError(err);
      })
      .finally(() => {
        setAddMemberLoading(false);
        if(callback){
          callback();
        }
      });
  }, [cookie])

  const removeMemberFromOrganisation = useCallback(
    (orgID: string, userID: string) => {
      if (!orgID || !userID) {
        return;
      }

      const data: API_Organisation_Members = {
        members: [{ account_id: userID }],
      };

      let config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
        data: data,
      };

      setRemoveMemberLoading(true);
      axios
        .delete(
          `${ORGANISATIONS_API_URL}/organisation/${orgID}/uninvite`,
          config
        )
        .catch((err) => {
          setRemoveMembernError(err);
        })
        .finally(() => {
          setRemoveMemberLoading(false);
        });
    },
    [cookie]
  );

  const removeInviteFromOrganisation = useCallback(
    (orgID: string, invited_email: string) => {

       if (!orgID || !invited_email) {
         return;
       }

       const data: API_Organisation_Members = {
          pending: [{invited_email: invited_email}]
       }

      let config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
        data: data 
      };

      setRemoveMemberLoading(true);
      axios
        .delete(
          `${ORGANISATIONS_API_URL}/organisation/${orgID}/uninvite`,
          config
        )
        .catch((err) => {
          setRemoveMembernError(err);
        })
        .finally(() => {
          setRemoveMemberLoading(false);
        });
    },
    [cookie]
  );

  const deleteOrganisation = useCallback(
    (orgID: string) => {
      let config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
        data: {id: orgID}
      };

      if (!orgID) {
        return;
      }

      setDeleteOrganisationLoading(true);
      axios
        .delete(
          `${ORGANISATIONS_API_URL}/organisation`,
          config
        )
        .catch((err) => {
          setDeleteOrganisationError(err);
        })
        .finally(() => {
          setDeleteOrganisationLoading(false);
        });
    },
    [cookie]
  );

  const uploadOrgFile = useCallback(
    (blob: any, filename: string, key?: string) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      const file = new File([blob], `${filename}.png`);
      let formData = new FormData();
      formData.append("file", file);

      setFileUploadLoading(true);
      axios
        .post(
          `${ORGANISATIONS_API_URL}/file`,
          formData,
          config
        )
        .then(({data}) => {
          const resp: API_File_Response = {
            key: key,
            url: data.url
          }
          setFileUploadResponse(resp);
        })
        .catch((err) => {
          setFileUploadError(err);
        })
        .finally(() => {
          setFileUploadLoading(false);
        });
    },
    [cookie]
  );

  const createOrgTag = useCallback((tagValue: string) => {
     let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      const data: API_Organisation_Tag = {
        value: tagValue
      }

      setCreateTagLoading(true);
      axios
        .post(
          `${ORGANISATIONS_API_URL}/tag`,
          data,
          config
        )
        .then(({data}) => {
          if(data){
            setCreatedTag(data);
          }
        })
        .catch((err) => {
          setCreateTagError(err);
        })
        .finally(() => {
          setCreateTagLoading(false)
        });
  }, [cookie])

  const setOrgTags = useCallback(
    (org: API_Organisation) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      if (!org.tags || org.tags.length === 0) {
        return;
      }

      setOrganisationTagsLoading(true);
      axios
        .post(
          `${ORGANISATIONS_API_URL}/organisation/${org.id}/tag`,
          org.tags,
          config
        )
        .catch((err) => {
          setOrganisationTagsError(err);
        })
        .finally(() => {
          setOrganisationTagsLoading(false)
        });
    },[cookie]
  );

  const updateOrg = useCallback(
    (org: API_Organisation) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      if (!org) {
        return;
      }

      org.website_url = formatURL(org.website_url);
      org.facebook_url = formatURL(org.facebook_url);
      org.linked_in_url = formatURL(org.linked_in_url);
      

      setUpdateOrganisationLoading(true);
      axios
        .put(
          `${ORGANISATIONS_API_URL}/organisation`,
          org,
          config
        )
        .catch((err) => {
          setUpdateOrganisationError(err);
        })
        .finally(() => {
          setUpdateOrganisationLoading(false);
        });
    },
    [cookie]
  );

  const getOrganisationMembers = useCallback(
    (orgID: string) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      if (!orgID) {
        return;
      }

      setOrganisationMembersLoading(true);
      axios
        .get(`${ORGANISATIONS_API_URL}/organisation/${orgID}/members`, config)
        .then(({data}) => {
          setOrganisationMembers(data);
        })
        .catch((err) => {
          setOrganisationMembersError(err);
        })
        .finally(() => {
          setOrganisationMembersLoading(false);
        });
    },
    [cookie]
  );

  const getOrganisationsBySearch = useCallback(
    (search: string) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      if(search === ""){
        setOrganisationSearch(undefined);
        return
      }

      setOrganisationSearchLoading(true);
      axios
        .get(`${ORGANISATIONS_API_URL}/search?q=${encodeURIComponent(search)}`, config)
        .then(({ data }) => {
          setOrganisationSearch(data);
        })
        .catch((err) => {
          setOrganisationSearchError(err);
          setOrganisationSearch(undefined)
        })
        .finally(() => {
          setOrganisationSearchLoading(false);
        });
    },
    [cookie]
  );

  const fetchOrganisation = useCallback(
    (orgID: string) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      if (!orgID) {
        return;
      }

      setFetchedOrganisationLoading(true);
      axios
        .get(`${ORGANISATIONS_API_URL}/organisation/${orgID}`, config)
        .then(({ data }) => {
          setFetchedOrganisation(data);
        })
        .catch((err) => {
          setFetchedOrganisationError(err);
        })
        .finally(() => {
          setFetchedOrganisationLoading(false);
        });
    },
    [cookie]
  );

  const fetchOrganisationUpcomingEvents = useCallback(
    (orgID: string) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      if (!orgID) {
        return;
      }


      setOrganisationUpcomingEventsLoading(true);
      axios
        .get(`${ORGANISATIONS_API_URL}/organisation/${orgID}/events?filter=upcoming`, config)
        .then(({ data }) => {
          setOrganisationUpcomingEvents(data);
        })
        .catch((err) => {
          setOrganisationUpcomingEventsError(err);
        })
        .finally(() => {
          setOrganisationUpcomingEventsLoading(false);
        });
    },
    [cookie]
  );

  const fetchOrganisationEventsCount = useCallback(
    (orgID: string) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      if (!orgID) {
        return;
      }

      setOrganisationEventsCountLoading(true);
      axios
        .get(`${ORGANISATIONS_API_URL}/organisation/${orgID}/events/count`, config)
        .then(({ data }) => {
          setOrganisationEventsCount(data);
        })
        .catch((err) => {
          setOrganisationEventsCountError(err);
        })
        .finally(() => {
          setOrganisationEventsCountLoading(false);
        });
    },
    [cookie]
  );

  const acceptMemberInvite = useCallback(
    (orgID: string) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      if (!orgID) {
        return;
      }

      setAcceptMemberInviteLoading(true);
      axios
        .get(
          `${ORGANISATIONS_API_URL}/organisation/${orgID}/accept-invite`,
          config
        )
        .catch((err) => {
          setAcceptMemberInviteError(err);
        })
        .finally(() => {
          setAcceptMemberInviteLoading(false);
        });
    },
    [cookie]
  );

  const fetchMemberInvite = useCallback(
    (orgID: string) => {

      let config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        }
      };

      if (!orgID) {
        return;
      }

      setMemberInviteLoading(true);
      axios
        .get(
          `${ORGANISATIONS_API_URL}/organisation/${orgID}/invite`,
          config
        )
        .then(({data}) => {
          setMemberInvite(data);
        })
        .catch((err) => {
          setMemberInviteError(err);
        })
        .finally(() => {
          setMemberInviteLoading(false);
        });
    },
    [cookie]
  );

   const getUserEventOrganisations = useCallback(
     (eventID: string | number) => {
       let config = {
         headers: {
           Authorization: `Bearer ${cookie}`,
         },
       };

       setUserEventOrganisationsLoading(true);
       axios
         .get(`${EVENT_API_URL}/event/${eventID}/organisations/user`, config)
         .then(({ data }) => {
           setUserEventOrganisations(data);
         })
         .catch((err) => {
           setUserEventOrganisationsError(err);
         })
         .finally(() => {
           setUserEventOrganisationsLoading(false);
         });
     },
     [cookie]
   );

   const getOrganisationEvents = useCallback(
     (organisationID: string) => {
       let config = {
         headers: {
           Authorization: `Bearer ${cookie}`,
         },
       };

       setOrganisationEventsLoading(true);
       axios
         .get(`${ORGANISATIONS_API_URL}/organisation/${organisationID}/events?filter=published`, config)
         .then(({ data }) => {
           setOrganisationEvents(data);
         })
         .catch((err) => {
           setOrganisationEventsError(err);
         })
         .finally(() => {
           setOrganisationEventsLoading(false);
         });
     },
     [cookie]
   );

   const getOrganisationPartneredEvents = useCallback(
     (organisationID: string) => {
       let config = {
         headers: {
           Authorization: `Bearer ${cookie}`,
         },
       };

       setOrganisationEventsLoading(true);
       axios
         .get(
           `${EVENT_API_URL}/public/organisation/${organisationID}/events-partner`,
           config
         )
         .then(({ data }) => {
           setOrganisationEvents(data);
         })
         .catch((err) => {
           setOrganisationEventsError(err);
         })
         .finally(() => {
           setOrganisationEventsLoading(false);
         });
     },
     [cookie]
   );


  return {
    getUserOrganisations,
    userOrganisations,
    userOrganisationsError,
    userOrganisationsLoading,

    createOrganisation,
    createOrganisationError,
    createOrganisationLoading,

    updateOrg,
    updateOrganisationError,
    updateOrganisationLoading,

    addMemberToOrganisation,
    addMemberError,
    addMemberLoading,

    removeMemberFromOrganisation,
    removeMemberError,
    removeMemberLoading,
    removeInviteFromOrganisation,

    uploadOrgFile,
    fileUploadError,
    fileUploadLoading,
    setFileUploadResponse,
    fileUploadResponse,

    createOrgTag,
    createTagError,
    createTagLoading,
    createdTag,

    deleteOrganisation,
    deleteOrganisationLoading,

    getOrganisationMembers,
    organisationMembers,
    organisationMembersLoading,
    organisationMembersError,

    getOrganisationsBySearch,
    organisationSearch,

    fetchOrganisation,
    fetchedOrganisation,
    fetchedOrganisationLoading,
    fetchedOrganisationError,

    fetchOrganisationUpcomingEvents,
    organisationUpcomingEvents,
    organisationUpcomingEventsLoading,

    fetchOrganisationEventsCount,
    organisationEventsCount,

    acceptMemberInvite,
    acceptMemberInviteLoading,
    acceptMemberInviteError,

    fetchMemberInvite,
    memberInvite,
    memberInviteLoading,
    memberInviteError,

    getUserEventOrganisations,
    userEventOrganisations,
    userEventOrganisationsError,
    userEventOrganisationsLoading,

    getOrganisationEvents,
    organisationEvents,
    organisationEventsError,
    organisationEventsLoading,
    getOrganisationPartneredEvents
  };
};

export const useOrganisationFollow = ({ cookie }: OrganisationProps) => {

  const [followOrganisationLoading, setFollowOrganisationLoading] = useState<boolean>();
  const [followOrganisationError, setFollowOrganisationError] = useState()

  const [unfollowOrganisationLoading, setUnfollowOrganisationLoading] = useState<boolean>();
  const [unfollowOrganisationError, setUnfollowOrganisationError] = useState()

  const [fetchFollowing, setFetchFollowing] = useState<boolean>(false)
  const [fetchFollowingLoading, setFetchFollowingLoading] = useState<boolean>()
  const [fetchFollowingError, setFetchFollowingError] = useState();

  const [organisationFollowers, setOrganisationFollowers] = useState<number>(0)
  const [organisationFollowersLoading, setOrganisationFollowersLoading] = useState<boolean>()
  const [organisationFollowersError, setOrganisationFollowersError] = useState()


  const followOrganisation = useCallback(
    (organisationID: string, payload?: API_OrganisationFollowPayload) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      setFollowOrganisationLoading(true);
      setFollowOrganisationError(undefined);
      axios
        .post(`${CONNECTIONS_API}/organisation/${organisationID}/follow`, payload, config)
        .catch((err) => {
          setFollowOrganisationError(err);
        })
        .finally(() => {
          setFollowOrganisationLoading(false);
        });
    },
    [cookie]
  );

   const unfollowOrganisation = useCallback(
     (organisationID: string) => {
       let config = {
         headers: {
           Authorization: `Bearer ${cookie}`,
         },
       };

       setUnfollowOrganisationLoading(true);
       setUnfollowOrganisationError(undefined);
       axios
         .delete(
           `${CONNECTIONS_API}/organisation/${organisationID}/follow`,
           config
         )
         .catch((err) => {
           setUnfollowOrganisationError(err);
         })
         .finally(() => {
           setUnfollowOrganisationLoading(false);
         });
     },
     [cookie]
   );

  const fetchIsFollowing = useCallback((organisationID: string) => {
    let config = {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    };

    setFollowOrganisationLoading(true);
    setFollowOrganisationError(undefined);
    axios
      .get(
        `${CONNECTIONS_API}/follow/organisation/${organisationID}`,
        config
      )
      .then(({status}) => {
        if(status === 200){
          setFetchFollowing(true);
        } else {
          setFetchFollowing(false);
        }
      })
      .catch((err) => {
        setFetchFollowingError(err);
        setFetchFollowing(false);
      })
      .finally(() => {
        setFetchFollowingLoading(false);
      });
  }, [cookie])

  const fetchOrganisationFollowers = useCallback(
    (organisationID: string) => {
      let config = {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      };

      setOrganisationFollowersLoading(true);
      setOrganisationFollowersError(undefined);
      axios
        .get(`${CONNECTIONS_API}/organisation/${organisationID}/follows/count`, config)
        .then(({ data }) => {
          if(data.count){
            setOrganisationFollowers(data.count);
          } else {
            setOrganisationFollowers(0);
          }
        })
        .catch((err) => {
          setOrganisationFollowersError(err);
        })
        .finally(() => {
          setOrganisationFollowersLoading(false);
        });
    },
    [cookie]
  );

  

  return {
    followOrganisation,
    followOrganisationLoading,
    followOrganisationError,

    unfollowOrganisation,
    unfollowOrganisationError,
    unfollowOrganisationLoading,

    fetchIsFollowing,
    fetchFollowing,
    fetchFollowingError,
    fetchFollowingLoading,

    fetchOrganisationFollowers,
    organisationFollowers,
    organisationFollowersLoading,
    organisationFollowersError
  };
};