import React, { useEffect, useRef, useState } from 'react';
import { HaiaLoader, QRCodeModal, Sharer, useLanguage } from '@haia/haia-components';
import { useNavigate, useParams } from 'react-router';
import tinycolor from 'tinycolor2';

import { useOrganisation, useOrganisationFollow } from '../../../API/organisation';
import EventList from './Event/EventList';
import PublicPageHeader from './Header';
import PublicPageHeaderImage from './HeaderImage';
import MembersList from './Member/MembersList';
import OrganisationPublicPageNavBar from './Nav';
import TextSection from './TextSection';
import { hasContactDetails } from './utils';

import styles from './organisationpublicpage.module.scss';

//@ts-ignore
const { ORGANISATIONS_API_URL } = window['runConfig'] ? window['runConfig'] : 'https://event.dev.haia.live';

type OrganisationPublicPageProps = {
    cookie: string;
};

const OrganisationPublicPage = ({ cookie }: OrganisationPublicPageProps) => {
    const { getLanguageLabel } = useLanguage();

    const { organisationID } = useParams();

    const {
        fetchOrganisation,
        fetchedOrganisation,
        fetchOrganisationEventsCount,
        organisationEventsCount,
        getOrganisationMembers,
        organisationMembers,
        fetchedOrganisationLoading,
        fetchedOrganisationError,
        fetchOrganisationUpcomingEvents,
        organisationUpcomingEvents,
    } = useOrganisation({ cookie: cookie });
    const {
        followOrganisation,
        followOrganisationError,
        followOrganisationLoading,
        fetchFollowing,
        fetchIsFollowing,
        unfollowOrganisation,
        unfollowOrganisationLoading,
        fetchOrganisationFollowers,
        organisationFollowers,
    } = useOrganisationFollow({ cookie: cookie });

    const navigate = useNavigate();

    const [isLight, setIsLight] = useState(false);
    const [showShareBox, setShowShareBox] = useState();

    const [showQRCode, setShowQRCode] = useState(false);

    const background = tinycolor(fetchedOrganisation?.primary_colour);

    //--- section refs ---
    const aboutRef = useRef<HTMLDivElement>(null);
    const eventsRef = useRef<HTMLDivElement>(null);
    const membersRef = useRef<HTMLDivElement>(null);
    const contactRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (background) {
            setIsLight(background.isLight());
        }
    }, [background]);

    //--- public endpoints that dont require valid JWT ---
    useEffect(() => {
        if (organisationID) {
            fetchOrganisation(organisationID);
            getOrganisationMembers(organisationID);
            fetchOrganisationEventsCount(organisationID);
            fetchOrganisationFollowers(organisationID);
            fetchOrganisationUpcomingEvents(organisationID);
        }
    }, [organisationID]);

    //--- endpoints that require valid JWT ---
    useEffect(() => {
        if (cookie && organisationID) {
            fetchIsFollowing(organisationID);
        }
    }, [cookie, organisationID]);

    useEffect(() => {
        if (followOrganisationLoading === false && organisationID) {
            fetchIsFollowing(organisationID);
            fetchOrganisationFollowers(organisationID);
        }
    }, [followOrganisationLoading]);

    useEffect(() => {
        if (unfollowOrganisationLoading === false && organisationID) {
            fetchIsFollowing(organisationID);
            fetchOrganisationFollowers(organisationID);
        }
    }, [unfollowOrganisationLoading]);

    const onNavClick = (section: PublicPageSections) => {
        switch (section) {
            case 'about':
                if (aboutRef.current) {
                    aboutRef.current.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case 'contact':
                if (contactRef.current) {
                    contactRef.current.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case 'events':
                if (eventsRef.current) {
                    eventsRef.current.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case 'members':
                if (membersRef.current) {
                    membersRef.current.scrollIntoView({ behavior: 'smooth' });
                }
                break;
        }
    };

    const onFollowClick = () => {
        if (fetchedOrganisation && fetchedOrganisation.id) {
            followOrganisation(fetchedOrganisation.id);
        }
    };

    const onUnFollowClick = () => {
        if (fetchedOrganisation && fetchedOrganisation.id) {
            unfollowOrganisation(fetchedOrganisation.id);
        }
    };

    const onQRCodeModalClose = () => {
        setShowQRCode(false);
    };

    const onQRCodeModalClick = () => {
        setShowQRCode(true);
    };

    return (
        <div className={styles['content']}>
            {fetchedOrganisationError ? (
                <>
                    <h1>404: This page does not exist</h1>
                </>
            ) : (
                <>
                    {fetchedOrganisationLoading ? (
                        <HaiaLoader />
                    ) : (
                        <>
                            <OrganisationPublicPageNavBar
                                organisation={fetchedOrganisation}
                                upcomingEvents={organisationUpcomingEvents}
                                members={organisationMembers?.members}
                                isLight={isLight}
                                onClick={onNavClick}
                            />
                            <PublicPageHeaderImage image={fetchedOrganisation?.banner_url} />
                            <main>
                                <div className={styles['main-content-container']}>
                                    <PublicPageHeader
                                        organisation={fetchedOrganisation}
                                        isLight={isLight}
                                        followCLick={onFollowClick}
                                        unFollowClick={onUnFollowClick}
                                        eventCount={organisationEventsCount}
                                        followerCount={organisationFollowers}
                                        isFollowing={fetchFollowing}
                                        allowFollowing={cookie ? true : false}
                                    />
                                    {fetchedOrganisation && (
                                        <>
                                            {fetchedOrganisation.bio && (
                                                <TextSection title={getLanguageLabel('about')} text={fetchedOrganisation.bio} ref={aboutRef} />
                                            )}
                                            {organisationUpcomingEvents && (
                                                <TextSection title={getLanguageLabel('upcoming_events')} ref={eventsRef}>
                                                    <EventList cookie={cookie} events={organisationUpcomingEvents} organisation={fetchedOrganisation} />
                                                </TextSection>
                                            )}
                                            {organisationMembers?.members && (
                                                <TextSection title={getLanguageLabel('members')} ref={membersRef}>
                                                    <MembersList members={organisationMembers.members} cookie={cookie} />
                                                </TextSection>
                                            )}
                                            {hasContactDetails(fetchedOrganisation) && (
                                                <TextSection title={getLanguageLabel('contact')} ref={contactRef}>
                                                    <div className={styles['contact-list']}>
                                                        {fetchedOrganisation.facebook_url && (
                                                            <a
                                                                href={fetchedOrganisation.facebook_url}
                                                                target="_blank"
                                                                title={`Facebook URL`}
                                                                aria-label={'Facebook URL'}
                                                                rel="noreferrer"
                                                            >
                                                                <i className={'fa-brands fa-facebook-f'}></i>
                                                            </a>
                                                        )}
                                                        {fetchedOrganisation.linked_in_url && (
                                                            <a
                                                                href={fetchedOrganisation.linked_in_url}
                                                                target="_blank"
                                                                title={`Linked In URL`}
                                                                aria-label={'Linked In URL'}
                                                                rel="noreferrer"
                                                            >
                                                                <i className={'fa-brands fa-linkedin-in'}></i>
                                                            </a>
                                                        )}
                                                        {fetchedOrganisation.twitter_handle && (
                                                            <a
                                                                href={`https://www.twitter.com/${fetchedOrganisation.twitter_handle}`}
                                                                target="_blank"
                                                                title={`Twitter URL`}
                                                                aria-label={'Twitter URL'}
                                                                rel="noreferrer"
                                                            >
                                                                <i className={'fa-brands fa-twitter'}></i>
                                                            </a>
                                                        )}
                                                        {fetchedOrganisation.contact_phone && (
                                                            <a
                                                                href={`tel:${fetchedOrganisation.contact_phone}`}
                                                                title={`Contact Phone`}
                                                                aria-label={'Contact Phone'}
                                                            >
                                                                <i className={'fa-solid fa-phone'}></i>
                                                            </a>
                                                        )}
                                                        {fetchedOrganisation.contact_email && (
                                                            <a
                                                                href={`mailto:${fetchedOrganisation.contact_email}`}
                                                                title={`Contact Email`}
                                                                aria-label={'Contact Email'}
                                                            >
                                                                <i className={'fa-solid fa-envelope'}></i>
                                                            </a>
                                                        )}
                                                        {fetchedOrganisation.website_url && (
                                                            <a
                                                                href={fetchedOrganisation.website_url}
                                                                target="_blank"
                                                                title={`Website URL`}
                                                                aria-label={'Website URL'}
                                                                rel="noreferrer"
                                                            >
                                                                <i className={'fa-solid fa-arrow-pointer'}></i>
                                                            </a>
                                                        )}
                                                    </div>
                                                </TextSection>
                                            )}
                                            <div className={styles['share-container']}>
                                                <Sharer
                                                    language={{
                                                        share_now: getLanguageLabel('share_now'),
                                                        share_on_facebook: getLanguageLabel('share_on_facebook'),
                                                        tweet_about_this_event: getLanguageLabel('tweet_about_this_event'),
                                                        share_on_linkedin: getLanguageLabel('share_on_linkedin'),
                                                        send_via_email: getLanguageLabel('send_via_email'),
                                                        copy_link: getLanguageLabel('copy_link'),
                                                        copied: getLanguageLabel('copied'),
                                                        copy_failed: getLanguageLabel('copy_failed'),
                                                        qr_code: getLanguageLabel('qr_code'),
                                                    }}
                                                    showInitial={showShareBox}
                                                    buttonClassName={styles['share-button']}
                                                    buttonChildren={
                                                        <>
                                                            <i className="fa-solid fa-share-from-square"></i>
                                                            {getLanguageLabel('share')}
                                                        </>
                                                    }
                                                    onQRClick={onQRCodeModalClick}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                                {showQRCode && (
                                    <QRCodeModal
                                        title={getLanguageLabel('qr_code')}
                                        description={getLanguageLabel('this_qr_code_will_direct_users_to_this_page')}
                                        cookie={cookie}
                                        apiURL={ORGANISATIONS_API_URL}
                                        endpointURL={`/organisation/${fetchedOrganisation?.id}/qr`}
                                        onClose={onQRCodeModalClose}
                                    />
                                )}
                            </main>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default OrganisationPublicPage;
