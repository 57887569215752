import axios from "axios";
import { useCallback, useEffect, useState } from "react";

//@ts-ignore
const { CONNECTIONS_API } = window["runConfig"] ? window["runConfig"]
  : { CONNECTIONS_API: "https://connections.dev.haia.live/api" };

type ConnectionsProps = {
  cookie?: string;
  jwt?: string;
};

export const useConnections = ({ cookie }: ConnectionsProps) => {
  
  const [userConnections, setUserConnections] = useState<API_Haia_Connection[]>()
  const [userConnectionsLoading, setUserConnectionsLoading] = useState<boolean>();
  const [userConnectionsError, setUserConnectionsError] = useState()
  const [userConnectionActionLoading, setUserConnectionActionLoading] = useState<boolean>()
  const [userConnectionActionError, setUserConnectionActionError] = useState();

  const [totalItems, setTotalItems] = useState<number>()

  const [requestConnectionError, setRequestConnectionError] = useState();

  const [acceptConnectionError, setAcceptConnectionError] = useState();

  const [disconnectConnectionError, setDisconnectConnectionError] = useState();

  const [followLoading, setFollowLoading] = useState<boolean>();
  const [followError, setFollowError] = useState();

  const [fetchedFollow, setFetchedFollow] = useState<API_Connection_Follow>()
  const [fetchedFollowLoading, setFetchedFollowLoading] = useState<boolean>()
  const [fetchedFollowError, setFetchedFollowError] = useState()

  const fetchUserConnections = useCallback((filter?: ConnectionFilterType, pagination?: PaginationParams) => {
    const params = new URLSearchParams();
    if(filter){
      params.append("filter", filter);
    }
    if(pagination){
      if(pagination.count){
        params.append("count", `${pagination.count}`);
      }
      if(pagination.offset){
        params.append("offset", `${pagination.offset}`);
      }
    }
    
    let config = {
      params: params,
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    };

    setUserConnectionsLoading(true);
    axios
      .get(`${CONNECTIONS_API}/connection`, config)
      .then(({ data, headers }) => {
        if(data){
          setUserConnections(data);
        } else {
          setUserConnections([]);
        }
        setTotalItems(parseInt(headers["x-total-items"]));
      })
      .catch((err) => {
        setUserConnectionsError(err);
      })
      .finally(() => {
        setUserConnectionsLoading(false);
      });
  }, [cookie, CONNECTIONS_API]);

  const requestConnection = useCallback((id: string) => {

    let config = {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    };

    const payload = {
      profile: id
    }
    setUserConnectionActionLoading(true);

    axios
      .post(`${CONNECTIONS_API}/connection`, payload, config)
      .catch((err) => {
        setRequestConnectionError(err);
        setUserConnectionActionError(err);
      })
      .finally(() => {
        setUserConnectionActionLoading(false);
        fetchUserConnections();
      });
  }, [cookie, CONNECTIONS_API])

   const acceptConnection = useCallback(
     (id: string) => {
       let config = {
         headers: {
           Authorization: `Bearer ${cookie}`,
         },
       };

       setUserConnectionActionLoading(true);

       axios
         .post(`${CONNECTIONS_API}/connection/${id}`, null, config)
         .catch((err) => {
           setAcceptConnectionError(err);
           setUserConnectionActionError(err);
         })
         .finally(() => {
            setUserConnectionActionLoading(false)
           fetchUserConnections();
         });
     },
     [cookie, CONNECTIONS_API]
   );

   const disconnectConnection = useCallback(
     (id: string) => {
       let config = {
         headers: {
           Authorization: `Bearer ${cookie}`,
         },
       };

       setUserConnectionActionLoading(true);

       axios
         .delete(`${CONNECTIONS_API}/connection/${id}`, config)
         .catch((err) => {
           setDisconnectConnectionError(err);
           setUserConnectionActionError(err);
         })
         .finally(() => {
          setUserConnectionActionLoading(false);
           fetchUserConnections();
         });
     },
     [cookie, CONNECTIONS_API]
   );

   const follow = useCallback(
     (id: string, data?: API_Follow_Additional_Data) => {
       let config = {
         headers: {
           Authorization: `Bearer ${cookie}`,
         },
       };

       setFollowLoading(true);

       axios
         .post(`${CONNECTIONS_API}/organisation/${id}/follow`, data, config)
         .catch((err) => {
           setFollowError(err);
         })
         .finally(() => {
            setFollowLoading(false)
           fetchUserConnections();
         });
     },
     [cookie, CONNECTIONS_API]
   );

   const userFollow = useCallback(
     (id: string | number, accountID: string, event: number, data?: API_Follow_Additional_Data) => {
       let config = {
         headers: {
           Authorization: `Bearer ${cookie}`,
         },
       };

       setFollowLoading(true);

       axios
         .post(`${CONNECTIONS_API}/organisation/${id}/follow/${accountID}/event/${event}`, data, config)
         .catch((err) => {
           setFollowError(err);
         })
         .finally(() => {
           setFollowLoading(false);
           fetchUserConnections();
         });
     },
     [cookie, CONNECTIONS_API]
   );

   const getFollow = useCallback((id: string) => {
    let config = {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    };

    setFetchedFollowLoading(true);

    axios
      .get(`${CONNECTIONS_API}/follow/organisation/${id}`, config)
      .then(({data}) => {
        const follow: API_Connection_Follow = {
          id: data.id,
          owner_id: data.owner_id,
          organisation_id: data.organisation_id,
          created: data.created,
          additional_data: JSON.parse(data.additional_data)
        }
        setFetchedFollow(follow);
      })
      .catch((err) => {
        setFetchedFollowError(err);
      })
      .finally(() => {
        setFetchedFollowLoading(false);
      });
   }, [cookie, CONNECTIONS_API])

   const getUserFollow = useCallback(
     (id: string | number, accountID: string, eventID?: string | number) => {
       let config = {
         headers: {
           Authorization: `Bearer ${cookie}`,
         },
       };

       setFetchedFollowLoading(true);

       let url = `${CONNECTIONS_API}/follow/organisation/${id}/account/${accountID}`;

       if(eventID){
        url = `${CONNECTIONS_API}/follow/organisation/${id}/account/${accountID}/event/${eventID}`;
       }


       axios
         .get(url, config)
         .then(({ data }) => {
           const follow: API_Connection_Follow = {
             id: data.id,
             owner_id: data.owner_id,
             organisation_id: data.organisation_id,
             created: data.created,
             additional_data: JSON.parse(data.additional_data),
           };
           setFetchedFollow(follow);
         })
         .catch((err) => {
           setFetchedFollowError(err);
         })
         .finally(() => {
           setFetchedFollowLoading(false);
         });
     },
     [cookie, CONNECTIONS_API]
   );

  return {
    fetchUserConnections,
    userConnections,
    requestConnection,
    requestConnectionError,
    acceptConnection,
    acceptConnectionError,
    disconnectConnection,
    disconnectConnectionError,
    userConnectionsLoading,
    setUserConnections,
    totalItems,
    userConnectionsError,
    userConnectionActionError,
    userConnectionActionLoading,

    follow,
    followError,
    followLoading,
    userFollow,

    getFollow,
    fetchedFollow,
    fetchedFollowError,
    fetchedFollowLoading,
    getUserFollow
  };
};
