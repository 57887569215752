const isValid = (toValidate: string, service: SocialService) => {
  const regExpString = validator.get(service);
  if (regExpString) {
    const re = new RegExp(regExpString);

    return re.test(toValidate);
  }
  return false;
};

const validator = new Map<SocialService, string>([
  [
    'facebook',
    '(?:(?:http|https)://)?(?:www.)?(facebook|fb).com/(?:(?:w)*#!/)?(?:pages/)?(?:[?w-]*/)?(?:profile.php?id=(?=d.*))?([w-]*)?',
  ],
  [
    'twitter',
    '(?:http://)?(?:www.)?twitter.com/(?:(?:w)*#!/)?(?:pages/)?(?:[w-]*/)*([w-]*)',
  ],
  ['linkedIn', '(http(s)?://)?([w]+.)?linkedin.com/(pub|in|profile)'],
  ['youtube', '(?:(?:http|https)://)?(?:www.)?youtube.com/(channel|user)/[S]+'],
  [
    'website',
    '(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
  ],
]);

export const socialValidator = {
  isValid,
};
