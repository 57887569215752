import React, { useEffect, useRef, useState } from 'react';
import './style.scss'

const PopUp = ({
  style,
  subtitle,
  title,
  description,
  children,
  position = "top"
}: PopUpProps) => {
  const popUpRef = useRef<HTMLDivElement>(null);
  const [positionClass, setPositionClass] = useState('normal');
  const renderIcon = () => {
    switch (style) {
      case 'none':
        return <i className='fad fa-info-circle popup-icon'></i>;
      case 'time':
      default:
        return (
          <i className='fa-solid fa-timer popup-icon' data-cy='style-icon'></i>
        );
    }
  };
  useEffect(() => {
    const { left } = popUpRef.current?.getBoundingClientRect() as DOMRect;
    if (left < 10) {
      setPositionClass('close-left');
    }
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };
  return (
    <section
      className={`popup ${positionClass} ${position}`}
      ref={popUpRef}
      data-cy='popup'
    >
      <section className={`popup-content ${style}`}>
        {subtitle && <h3 data-cy='subtitle'>{subtitle}</h3>}
        {title && <h2 data-cy='title'>{title}</h2>}
        <p data-cy='description'>{description}</p>
        {renderIcon()}
      </section>

      {children && <section className='popup-interactions' onClick={handleClick}>
        {children}
      </section>}
      <i className='fa-solid fa-xmark close-icon' data-cy='close-icon'></i>
    </section>
  );
};

export default PopUp;
