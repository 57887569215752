import { DismissModal, useLanguage } from '@haia/haia-components';
import React, { useEffect, useRef, useState } from 'react';
import style from './websiteIntegration.module.scss';

type EventsListProps = { userID: string; };
const EventsList = ({ userID }: EventsListProps) => {

  const { getLanguageLabel } = useLanguage();

  type WindowConfig = Window & {
    runConfig: any
  };
  const currentWindow = window as unknown as WindowConfig;
  const { DOMAIN } = currentWindow['runConfig']
    ? currentWindow['runConfig']
    : { DOMAIN: 'dev.haia.live' };
  const codeRef = useRef<HTMLTextAreaElement>(null);
  const copyIDRef = useRef<HTMLParagraphElement>(null);
  const widget = useRef<HTMLDivElement>(null);
  const [showExample, setShowExample] = useState(false);
  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (codeRef.current) {
      codeRef.current.select();
      codeRef.current.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(codeRef.current.value);
      copyIDRef.current?.classList.add(style.copied);
      setTimeout(() => {
        copyIDRef.current?.classList.remove(style.copied);
      }, 1000);
    }
  };
  useEffect(()=>{
    if(showExample){
      console.log('addign script')
      const script = document.createElement('script');

      script.src =
        `https://haia-integration.s3.eu-west-2.amazonaws.com/events-list/latest/index.js?${(new Date()).toISOString()}`;
      script.async = true;

      document.body.appendChild(script);
    }
  },[showExample])

  const code = `<div class="haia-widget" data-organiser-id="${userID}" data-lang="${getLanguageLabel('lang_code')}"></div>
<script
  type="text/javascript"
  src="https://haia-integration.s3.eu-west-2.amazonaws.com/events-list/latest/index.js"
></script>
<link
  rel="stylesheet"
  type="text/css"
  href="https://haia-integration.s3.eu-west-2.amazonaws.com/events-list/latest/index.css"
/>`;
  const handleExampleClick = (e:React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowExample(true);
  }
  return (
    <>
      <h3>{getLanguageLabel('events_list')}</h3>
      <p>{getLanguageLabel('events_list_description')}</p>
      <p
        className={style['events-list']}
        data-after-text={getLanguageLabel('copied')}
        ref={copyIDRef}
      >
        <textarea
          className={style['events-list-code']}
          ref={codeRef}
          value={code}
          spellCheck={false}
        />
        <button
          className={style.button}
          onClick={handleCopy}
          title={getLanguageLabel('copy_to_clipboard')}
        >
          <i className='fa-regular fa-copy'></i>
        </button>
      </p>
      <p className={style.preview}>
        <a href='#' onClick={handleExampleClick}>
          {getLanguageLabel('preview')}
        </a>
      </p>
      {showExample && (
        <DismissModal
          title={getLanguageLabel('example')}
          onClose={() => {
            setShowExample(false);
          }}
          buttonLabel={getLanguageLabel('dismiss')}
        >
          <div
            className='haia-widget'
            data-organiser-id={`${userID}`}
            data-domain={DOMAIN}
            data-lang={getLanguageLabel('lang_code')}
            ref={widget}
          ></div>

          <link
            rel='stylesheet'
            type='text/css'
            href='https://haia-integration.s3.eu-west-2.amazonaws.com/events-list/latest/index.css'
          />
        </DismissModal>
      )}
    </>
  );
}

export default EventsList;

