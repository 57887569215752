import { DismissModal, useLanguage } from '@haia/haia-components';
import React, { useEffect, useRef, useState } from 'react';
import style from './websiteIntegration.module.scss';

type SingleEventProps = { userID: string; };

const SingleEvent = ({ userID }: SingleEventProps) => {

  const { getLanguageLabel } = useLanguage();


  type WindowConfig = Window & {
    runConfig: any;
  };
  const currentWindow = window as unknown as WindowConfig;
  const { EVENT_API_URL } = currentWindow['runConfig']
    ? currentWindow['runConfig']
    : { EVENT_API_URL: 'https://events-api.dev.haia.live/api' };

  const { DOMAIN } = currentWindow['runConfig']
    ? currentWindow['runConfig']
    : { DOMAIN: 'dev.haia.live' };
  const codeRef = useRef<HTMLTextAreaElement>(null);
  const copyIDRef = useRef<HTMLParagraphElement>(null);

  const [events, setEvents] = useState<HaiaEvent[]>();
  const [currentEvent, setCurrentEvent] = useState<string>();
  const [showExample, setShowExample] = useState(false);
  const [code, setCode] = useState('');
  useEffect(() => {
    fetch(`${EVENT_API_URL}/integrations/organiser/${userID}?pastEvents=true`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setEvents(data);
        } else {
          // error
        }
      });
  }, []);

  useEffect(() => {
    setCode(`<div class="haia-widget" data-event-id="${
      !currentEvent ? "EVENT_ID" : currentEvent
    }" data-lang="${getLanguageLabel('lang_code')}"></div>
 <script
  type="text/javascript"
  src="https://haia-integration.s3.eu-west-2.amazonaws.com/events-list/latest/index.js"
></script>
<link
  rel="stylesheet"
  type="text/css"
  href="https://haia-integration.s3.eu-west-2.amazonaws.com/events-list/latest/index.css"
/>`);
  }, [currentEvent]);
  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (codeRef.current) {
      codeRef.current.select();
      codeRef.current.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(codeRef.current.value);
      copyIDRef.current?.classList.add(style.copied);
      setTimeout(() => {
        copyIDRef.current?.classList.remove(style.copied);
      }, 1000);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentEvent(e.target.value);
  };
  const renderEvents = () => {
    if(events && Array.isArray(events)){
      return events.map((event) => (
        <option value={event.public_id}>{event.name}</option>
      ));
    } else {
      return null;
    }
  }
  const handleShowPreveiwClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowExample(true);
  };
  useEffect(() => {
    if (showExample) {
      const script = document.createElement('script');

      script.src = `https://haia-integration.s3.eu-west-2.amazonaws.com/events-list/latest/index.js?${new Date().toISOString()}`;
      script.async = true;

      document.body.appendChild(script);
    }
  }, [showExample]);

  return (
    <div className={style["singleevent"]} data-cy="singleevent">
      <h3>{getLanguageLabel("single_event")}</h3>
      <p>{getLanguageLabel("single_event_description")}</p>
      {events && Array.isArray(events) && (
        <>
          <p>{getLanguageLabel("please_select_event")}</p>{" "}
          <select onChange={handleChange}>{renderEvents()}</select>
        </>
      )}
      <p
        className={style["events-list"]}
        data-after-text={getLanguageLabel("copied")}
        ref={copyIDRef}
      >
        <textarea
          className={style["events-list-code"]}
          ref={codeRef}
          value={code}
          spellCheck={false}
        />
        <button
          className={style.button}
          onClick={handleCopy}
          title={getLanguageLabel("copy_to_clipboard")}
        >
          <i className="fa-regular fa-copy"></i>
        </button>
      </p>
      <p className={style.preview}>
        <a href="#" onClick={handleShowPreveiwClick}>
          {getLanguageLabel("preview")}
        </a>
      </p>
      {showExample && (
        <DismissModal
          title={getLanguageLabel("example")}
          onClose={() => {
            setShowExample(false);
          }}
          buttonLabel={getLanguageLabel("dismiss")}
        >
          <div
            className="haia-widget"
            data-event-id={`${!currentEvent ? 501 : currentEvent}`}
            data-domain={DOMAIN}
            data-lang={getLanguageLabel("lang_code")}
          ></div>

          <link
            rel="stylesheet"
            type="text/css"
            href="https://haia-integration.s3.eu-west-2.amazonaws.com/events-list/latest/index.css"
          />
        </DismissModal>
      )}
    </div>
  );
};

export default SingleEvent;
