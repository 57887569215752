import { ProfileImage, Tooltip, useLanguage } from "@haia/haia-components";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "../organisationmembersmodal.module.scss";

type MemberProps = {
  member: API_Organisation_Member;
  ownerID?: string;
  isOwner?: boolean;
  handleTooltipClick?: (type: string, member: API_Organisation_Member) => void;
  accountID?: string;
};

const Member = ({
  member,
  ownerID,
  isOwner = false,
  accountID,
  handleTooltipClick
}: MemberProps) => {

  const { getLanguageLabel } = useLanguage();

  const [showMenu, setShowMenu] = useState(false);
  const [unidentified, setUnidentified] = useState(false)

  useEffect(() => {
     if (member.profile) {
       if (member.profile.display_name) {
        setUnidentified(false)
       } else {
         setUnidentified(true)
       }
     } else if (member.invited_email) {
      setUnidentified(false)
     } else {
       setUnidentified(true)
     }
  }, [member.profile])

  const onOptionClick = () => {
    setShowMenu(!showMenu);
  };

  const onTooltipClick = (type: string) => () => {
    if(handleTooltipClick){
      handleTooltipClick(type, member);
    }
  };

  // const handleTooltipClick = (type: string) => () => {
  //   switch (type) {
  //     case 'remove_member':
  //       break;
  //     case 'remove_invite':
  //       break;
  //   }
  // };

  const hideToolTip = () => {
    setShowMenu(false);
  };

  const getName = () => {
    if(member.profile){
      if(member.profile.display_name){
        return member.profile.display_name
      } else {
        return `${getLanguageLabel('unidentified_user')}`;
      }
    } else if(member.invited_email){
      return member.invited_email
    } else {
      return `${getLanguageLabel('unidentified_user')}`
    }
  }

  return (
    <div
      key={`member-list-${member.invited_email}`}
      className={styles["member"]}
    >
      <ProfileImage type={"circle"} image={member.profile?.profile_pic} displayName={member.profile?.display_name} backgroundKey={member.profile?.account_id}/>
      <div className={styles["data"]}>
        <p className={`${styles["title"]} ${unidentified ? styles['unidentified'] : ''}`}>
          {getName()}
          {ownerID && member.account_id && ownerID == member.account_id && (
            <span className={styles["owner-tag"]}>{getLanguageLabel('owner')}</span>
          )}
          {!member.profile && !member.account_id && (
            <span className={styles["pending-tag"]}>
              {getLanguageLabel('pending_invite')}
            </span>
          )}
        </p>
        <p className={styles["subtitle"]}>{member.profile?.short_bio}</p>
      </div>
      {(ownerID !== member.account_id && accountID !== member.account_id && isOwner) && (
        <div className={styles["options-button"]} onClick={onOptionClick} title={'Options'}>
          <i className="fa-solid fa-ellipsis"></i>
        </div>
      )}
      {showMenu && (
        <Tooltip
          customStyle={{ width: "fit-content", right: "40px" }}
          pointerSide={"right"}
          hideToolTip={hideToolTip}
        >
          <div className={styles["tooltip"]}>
            {/* <div className={`${styles['tooltip-item']}`}>
                <i className="fa-duotone fa-flag"></i> {getLanguageLabel('report_an_issue')}
                </div> */}
                {isOwner && (
                  <>
                    {member.account_id ? 
                      (
                        <div
                          className={`${styles["tooltip-item"]} ${styles["red"]}`}
                          onClick={onTooltipClick("remove_member")}
                        >
                          <i className="fa-duotone fa-trash-can"></i> {getLanguageLabel('remove_member')}
                        </div>
                      ) : (
                        <div
                          className={`${styles["tooltip-item"]} ${styles["red"]}`}
                          onClick={onTooltipClick("remove_invite")}
                        >
                          <i className="fa-duotone fa-trash-can"></i> {getLanguageLabel('remove_invite')}
                        </div>
                      )
                    }
                  </>
                )}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default Member;
