import React, { CSSProperties, useEffect, useRef, useState } from "react";
import tinycolor from "tinycolor2";
import styles from "./organisationpublicpage.module.scss";

type PublicPageHeaderImageProps = {
  image?: string;
};

const PublicPageHeaderImage = ({
  image
}: PublicPageHeaderImageProps) => {

  const defaultImage = "https://storage.googleapis.com/haia-live-profile-live-public/cards/light-4.jpg";

  return (
    <>
      {image ? (
        <>
          {image?.includes("http") ? (
            <img className={styles["header-image"]} style={{background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5)), url(${image})`}}/>
          ) : (
            <div
              className={styles["header-image"]}
              style={{ background: image }}
            ></div>
          )}
        </>
      ) : (
        <>
          <img className={styles["header-image"]} src={defaultImage} />
        </>
      )}
    </>
  );
};

export default PublicPageHeaderImage;
