import { ProfileImage, Tooltip, useLanguage } from "@haia/haia-components";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { handleEnterDown } from "../../../hooks/actions";
import styles from "./organisationlist.module.scss";


type OrganisationListItemProps = {
  organisation: API_Organisation;
  onShowMembers?: (org: API_Organisation) => void;
  onOrgLeave?: (org: API_Organisation) => void;
  accountID?: string;
  onOrgEdit?: (org: API_Organisation) => void;
  onOrgView?: (org: API_Organisation) => void;
  onOrgLinkPayment?: (org: API_Organisation) => void;
};

const OrganisationListItem = ({organisation, onShowMembers, onOrgLeave, accountID, onOrgEdit, onOrgLinkPayment, onOrgView}: OrganisationListItemProps) => {

  const { getLanguageLabel } = useLanguage();

  const [showMenu, setShowMenu] = useState(false)
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if(organisation.owner_id && accountID && organisation.owner_id == accountID){
      setIsOwner(true);
    } else {
      setIsOwner(false);
    }
  }, [organisation.owner_id, accountID])

  const handleShowMembersClick = () => {
    if(onShowMembers){
      onShowMembers(organisation);  
    }
  }

  const onOptionClick = () => {
    setShowMenu(!showMenu);
  }

  const handleTooltipClick = (type: string) => () => {
    switch(type){
      case 'leave':
        if(onOrgLeave && organisation){
          onOrgLeave(organisation);
        }
        break;
      case 'edit':
        if(onOrgEdit && organisation){
          onOrgEdit(organisation)
        }
        break;
      case 'view':
        if(onOrgView && organisation){
          onOrgView(organisation)
        }
        break;
      case 'payment':
        if(onOrgLinkPayment && organisation){
          onOrgLinkPayment(organisation)
        }
        break;
    }
  }

  const hideToolTip = () => {
    setShowMenu(false);
  }

  return (
    <div className={styles['organisation-list-item']} tabIndex={0}>
        <ProfileImage type={"sqaure"} image={organisation.logo_url} backgroundColour={organisation.tertiary_colour}/>
        <div className={styles['data']}>
            <p className={styles['title']}>{organisation.name} {organisation.owner_id == accountID && <span className={styles['owner-tag']} title={getLanguageLabel('owner')}>{getLanguageLabel('owner')}</span>} {organisation.payment_account && <span className={styles['payment-complete-tag']} title={getLanguageLabel('has_payment_account')}>{getLanguageLabel('has_payment_account')}</span>}{!organisation.payment_account && <span className={styles['payment-pending-tag']} title={getLanguageLabel('needs_payment_account')}>{getLanguageLabel('needs_payment_account')}</span>}</p>
            <p className={styles['subtitle']}>{organisation.members_count !== undefined && (<><span className={styles['underlined']} onClick={handleShowMembersClick} onKeyDown={handleEnterDown(handleShowMembersClick)} tabIndex={0}>{organisation.members_count !== undefined ? organisation.members_count : 0} {getLanguageLabel('members')}</span></>)}</p>
        </div>
        <div className={styles['options-button']} onClick={onOptionClick} onKeyDown={handleEnterDown(onOptionClick)} title={`Options`} tabIndex={0}>
            <i className="fa-solid fa-ellipsis"></i>
        </div>
        {showMenu && (
          <Tooltip customStyle={{width: 'fit-content', right: '80px'}} pointerSide={'right'} hideToolTip={hideToolTip}>
            <div className={styles['tooltip']}>
              {/* <div className={`${styles['tooltip-item']}`}>
                <i className="fa-duotone fa-flag"></i> {getLanguageLabel('report_an_issue}
              </div> */}
              <div className={`${styles['tooltip-item']}`} onClick={handleTooltipClick("view")}>
                <i className="fa-duotone fa-memo"></i> {getLanguageLabel('view')}
              </div>
              {isOwner && (
                <div className={`${styles['tooltip-item']}`} onClick={handleTooltipClick("edit")}>
                  <i className="fa-duotone fa-pen-to-square"></i> {getLanguageLabel('edit_details')}
                </div>
              )}
              {!organisation.payment_account && (
                  <div className={`${styles['tooltip-item']}`} onClick={handleTooltipClick("payment")}>
                    <i className="fa-duotone fa-money-bill"></i> {getLanguageLabel('link_payment_account')}
                  </div>
              )}
              <div className={`${styles['tooltip-item']} ${styles['red']}`} onClick={handleTooltipClick("leave")}>
                <i className="fa-duotone fa-trash-can"></i> {isOwner ? getLanguageLabel('delete_organisation') : getLanguageLabel('leave_organisation')}
              </div>
            </div>
          </Tooltip>
        )}
    </div>
  );
};

export default OrganisationListItem;
