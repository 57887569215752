import { useEffect, useState, useRef } from "react";
import { SketchPicker } from "react-color";
import axios from "axios";
import info from "../Images/info.svg";
import id from "../Images/id.svg";
import briefcase from "../Images/briefcase.svg";
import thank_you from "../Images/thank_you.jpg";
import SliderStyles from "../CSS-Modules/slider.module.css";
import SubscriptionStyles from "../CSS-Modules/subscription.module.css";
import SubscriptionModal from "../Components/subscriptionModal";
// import { navigate, useParams } from "@reach/router";
import {useParams, useNavigate, Routes, Route} from "react-router-dom"
import user_0 from "../Images/user0.jpg";
import user_1 from "../Images/user1.jpg";
import user_2 from "../Images/user2.jpg";
import user_3 from "../Images/user3.jpg";
import user_4 from "../Images/user4.jpg";
import user_5 from "../Images/user5.jpg";
import user_6 from "../Images/user6.jpg";
import user_7 from "../Images/user7.jpg";
import user_8 from "../Images/user8.jpg";
import user_9 from "../Images/user9.jpg";
import Loader from "../Components/loader";
import SubscriptionDetails from "../Components/Subscription/SubscriptionDetails";
import {getTimezoneAbbreviationFromName, getBrowserTimezone} from "../utils/utils";
import ProfileStyles from '../CSS-Modules/profile.module.scss';
import { socialValidator } from "../utils/socialValidator";
import ValidatedInput from "../Components/input/ValidatedInput";
// import DismissModal from "../Components/Modal/DismissModal";
import { DismissModal, HaiaCard, ImageCrop, SuggestionDropDown, useLanguage } from "@haia/haia-components";
// import ImageCrop from "../Components/ImageCrop";
import { cropImage } from "../Components/ImageCrop/utils";
import AvatarSection from "../Components/AvatarSection";
import WebsiteIntegration from "../Components/WebsiteIntegration";
import BackgroundPicker from "../Components/BackgroundPicker";
import { useExternalNavigate } from "../hooks/externalNavigate";
import OrganisationTab from "./Tabs/OrganisationTab";
import OrganiserContextProvider from "../Context/Organiser/OrganiserContextProvider";
const users = {
  0: user_0,
  1: user_1,
  2: user_2,
  3: user_3,
  4: user_4,
  5: user_5,
  6: user_6,
  7: user_7,
  8: user_8,
  9: user_9,
};

const STRIPE_KEY = window["runConfig"]?.STRIPE_KEY || "pk_test_51JCL50J695ZhmcLkRDcIidZCGOL74f8ZtI8LwR5vW2PQLGTaZlB1IPDFua8qJLPQywpPrz0MJrkrgtGAXP2IA0nc00oRNGJbwy"

// const stripePromise = loadStripe(STRIPE_KEY);

const getFormattedDate = function(d) {
  const end_date = new Date(d);
  const end = new Date(end_date);
  const day = end.getDate();
  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][end.getMonth()];
  const year = end.getFullYear();
  console.log(day, typeof(day));

  let nth = "";
  switch (day) {
    case 1:
    case 21:
    case 31:
      nth = "st";
      break;

    case 2:
    case 22:
      nth = "nd";
      break;

    case 3:
    case 23:
      nth = "rd";
      break;

    default:
      nth = "th"
  }

  return day+nth + " " +month+" "+year;
};

const Profile = (props) => {
  const user = props.user;
  const [profileEdited, setProfileEdited] = useState();
  const [currentUser, setCurrentUser] = useState({ ...user });
  const [socialDetails, setSocialDetails] = useState({
    owner_id: user.account_id,
  });

  const [general, setGeneral] = useState(true);
  const [organisation, setOrganisation] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [settings, setSettings] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [integrations, setIntegrations] = useState(false);
  const [card, setCard] = useState(false);
  const [loader, setLoader] = useState(true);
  const [inputDisplay, setInputDisplay] = useState(false);

  const [suggestedInterests, setSuggestedInterests] = useState();
  const [confirmedInterests, setConfirmedInterests] = useState([]);
  const [interestsSubmitted, setInterestsSubmitted] = useState();
  const [interestTag, setInterestTag] = useState("");

  const [suggestedSectors, setSuggestedSectors] = useState();
  const [confirmedSectors, setConfirmedSectors] = useState([]);
  const [sectorsSubmitted, setSectorsSubmitted] = useState();
  const [sectorTag, setSectorTag] = useState("");

  const [suggestedLanguages, setSuggestedLanguages] = useState();
  const [confirmedLanguages, setConfirmedLanguages] = useState([]);
  const [languagesSubmitted, setLanguagesSubmitted] = useState();
  const [languageTag, setLanguageTag] = useState("");
  const [subscriptionModal, setSubscriptionModal] = useState();
  const [subscriptionType, setSubscriptionType] = useState();
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [renewal, setRenewal] = useState();
  const [renewalChange, setRenewalChange] = useState();
  const [allSubs, setAllSubs] = useState();
  const [thankYouModal, setThankYouModal] = useState(false);
  const [subsLoaded, setSubsLoaded] = useState(false);
  const [currentSubscrptionDetails, setCurrentSubscrptionDetails] = useState(null);
  const [nextSubscriptionDetails, setNextSubscrptionDetails] =
    useState(null);

  const {tab, modal, plan} = useParams();
  const [stateTab, setstateTab] = useState(tab);
  const [stateModal, setstateModal] = useState(modal);
  const [showBadDetailsModal, setShowBadDetailsModal] = useState(false);

  const [timezones, setTimezones] = useState([]);

  const [showCropModal, setShowCropModal] = useState(false);

  const profilePictureInput = useRef(null);

  const [profileImageLoading, setProfileImageLoading] = useState(false)

  const [profileLanguageSuggestions, setProfileLanguageSuggestions] = useState()
  const [profileLanguages, setProfileLanguages] = useState([])
  const [filteredLanguageSuggestions, setFilteredLanguageSuggestions] = useState()

  const [showBackgroundPicker, setShowBackgroundPicker] = useState(false)

  const [selectedImage, setSelectedImage] = useState();
  const [imageToCrop, setImageToCrop] = useState();
  const [currentImageKey, setCurrentImageKey] = useState();


  const MaxBioLength = 300

  const navigate = useNavigate();

  const {externalNavigate} = useExternalNavigate();

  const {getLanguageLabel} = useLanguage();

  const { EVENT_API_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://event-api.dev.haia.live/api";

  const { API_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://profile-api.dev.haia.live/api";

  const { EVENT_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://event.dev.haia.live";

  const { SUBSCRIPTION_API_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://subscription-api.dev.haia.live/api";

  const { DEFAULT_TIMEZONE } = window["runConfig"]
    ? window["runConfig"]
    : "Europe/London";

  let config = {
    headers: {
      Authorization: `Bearer ${props.cookie}`,
    },
  };

  useEffect(() => {
    setSubsLoaded(
      allSubs?.length > 0 && subscriptionDetails && subscriptionType
    );
  }, [allSubs, subscriptionDetails, subscriptionType]);

  useEffect(() => {
    if (window["runConfig"]["DISABLE_SUBSCRIPTION_LOOKUP"] === false) {
      axios
        .get(`${SUBSCRIPTION_API_URL}/subscription`, config)
        .then(({ data }) => {
          setAllSubs(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    axios
        .get(`${API_URL}/timezones`, config)
        .then(({ data }) => {
          if(data !== null){
            setTimezones(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    
  }, []);

  const TAB_NAMES = {
    profile: "profile",
    organisation: "organisation",
    subscription: "subscription",
    settings: "settings",
    notifications: "notifications",
    integrations: "integrations"
  }

  useEffect(() => {
    if (tab) {
      setGeneral(false);
      setOrganisation(false);
      setSubscription(false);
      setSettings(false);
      setNotifications(false);
      setIntegrations(false);
      switch (tab) {
        case TAB_NAMES.profile:
          setGeneral(true);
          break;
        case TAB_NAMES.organisation:
          setOrganisation(true);
          break;
        case TAB_NAMES.subscription:
          setSubscription(true);
          break;
        case TAB_NAMES.settings:
          setSettings(true);
          break;
        case TAB_NAMES.notifications:
          setNotifications(true);
          break;
        case TAB_NAMES.integrations:
          setIntegrations(true);
          break;
        default:
          setGeneral(true);
      }
    }
  }, [tab]);

  useEffect(() => {
    if (window["runConfig"]["DISABLE_SUBSCRIPTION_LOOKUP"] === false) {
      axios
        .get(
          `${SUBSCRIPTION_API_URL}/account/${props.jwt}/subscription`,
          config
        )
        .then(({ data }) => {
          setSubscriptionType(data.sub_type);

          setSubscriptionDetails(data);
          
          setRenewal(data.auto_renew);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [subscriptionModal]);

  //GET "/api/account/:account_id/subscription"

  //profile deets submitted -> interest tags submitted  language tags submitted ->  sector tags submitted -> social details submitted -> navigate to events
  useEffect(() => {
    if (user !== undefined) {
      //GET("api/social/id/:OwnerID" - GET SOCIAL BY OWNER ID

      axios
        .get(`${EVENT_API_URL}/social/id/${user.account_id}`, config)
        .then(({ data }) => {
          const newSocials = Object.fromEntries(
            Object.entries(data).map(([key, value]) => {
              if (key === 'id' || key === 'owner_id') return [key, value];
              return [key, { value, valid: true }];
            })
          );
          setSocialDetails(newSocials);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  useEffect(() => {
    axios
      .get(`${API_URL}/account/hobbies`, config)
      .then(({ data }) => {
        if (data !== null && data.length > 0) {
          data.forEach((tag) => {
            tag.exists = true;
          });
          setConfirmedInterests(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${API_URL}/account/languages`, config)
      .then(({ data }) => {
        if(data){
          setProfileLanguages(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${API_URL}/account/sector`, config)
      .then(({ data }) => {
        if (data !== null && data.length > 0) {
          data.forEach((tag) => {
            tag.exists = true;
          });
          setConfirmedSectors(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (event) => {
    let val = event.target.value;
    let name = event.target.name;
    if (val === "false" && (name === "opt_in_haia_marketing" || name === "opt_in_event_reminders" || name === "opt_in_event_feedback" || name === "opt_in_event_suggestions" || name === "opt_in_haia_connections")) {
      val = false;
    } else if (val === "true" && (name === "opt_in_haia_marketing" || name === "opt_in_event_reminders" || name === "opt_in_event_feedback" || name === "opt_in_event_suggestions" || name === "opt_in_haia_connections")) {
      val = true;
    }

    let copyUser = { ...currentUser };
    copyUser[name] = val;

    setCurrentUser({ ...copyUser });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    /*post request to api with edited user*/
    axios
      .post(`${API_URL}/account/profile`, currentUser, config)
      .then(() => {
        window.a.SendEvent("update_profile_submit", {
          account_id: currentUser.account_id,
        });
        setProfileEdited(Date.now());
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .post(`${API_URL}/account/languages`, profileLanguages, config)
      .catch((err) => {
        console.log(err);
      });
    
  };
  useEffect(() => {
    if (profileEdited !== undefined && confirmedInterests.length > 0) {
      let promises = [];
      confirmedInterests.forEach((tag) => {
        const dataToSend = {
          tag_id: tag.id,
        };
        if (!tag.hasOwnProperty("exists")) {
          promises.push(
            axios.post(`${API_URL}/account/hobbies`, dataToSend, config)
          );
        }
      });
      Promise.all(promises)
        .then(() => {
          setInterestsSubmitted(Date.now());
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (profileEdited !== undefined) {
      setInterestsSubmitted(Date.now());
    }
  }, [profileEdited]);

  useEffect(() => {
    if (interestsSubmitted !== undefined && confirmedLanguages.length > 0) {
      //can only send one at a time
      let promises = [];
      confirmedLanguages.forEach((tag) => {
        const dataToSend = {
          tag_id: tag.id,
        };
        if (!tag.hasOwnProperty("exists")) {
          promises.push(
            axios.post(`${API_URL}/account/languages`, dataToSend, config)
          );
        }
      });
      Promise.all(promises)
        .then(() => {
          setLanguagesSubmitted(Date.now());
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (interestsSubmitted !== undefined) {
      setLanguagesSubmitted(Date.now());
    }
  }, [interestsSubmitted]);

  useEffect(() => {
    if (languagesSubmitted !== undefined && confirmedSectors.length > 0) {
      //can only send one at a time
      let promises = [];
      confirmedSectors.forEach((tag) => {
        const dataToSend = {
          tag_id: tag.id,
        };
        if (!tag.hasOwnProperty("exists")) {
          promises.push(
            axios.post(`${API_URL}/account/sector`, dataToSend, config)
          );
        }
      });
      Promise.all(promises)
        .then(() => {
          setSectorsSubmitted(Date.now());
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (languagesSubmitted !== undefined) {
      setSectorsSubmitted(Date.now());
    }
  }, [languagesSubmitted]);

  useEffect(() => {
    let canSubmit = true;

    //submit social details after ammended profile details & tags
    if (sectorsSubmitted !== undefined && socialDetails) {
      const detailsToSend = Object.fromEntries(
        Object.entries(socialDetails).map(([k, v]) => {
          if (v.hasOwnProperty('valid')) {
            if (v.valid) {
              return [k, v.value];
            }
            canSubmit = false;
            return [k, v.value];
          }
          return [k, v];
        })
      );
      if (detailsToSend.hasOwnProperty('id')) {
        if (canSubmit) {
          axios
            .post(`${EVENT_API_URL}/social/update`, detailsToSend, config)
            .then(() => {
              externalNavigate(`${EVENT_URL}/events`);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setShowBadDetailsModal(true);
        }
      } else if (!detailsToSend.hasOwnProperty('id')) {
        axios
          .post(`${EVENT_API_URL}/social`, detailsToSend, config)
          .then(() => {
            externalNavigate(`${EVENT_URL}/events`);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (sectorsSubmitted !== undefined) {
        externalNavigate(`${EVENT_URL}/events`);
      }
    }
  }, [sectorsSubmitted]);

  const checkKey = (firstName, lastName, lang) => {
    if (
      currentUser.default_language &&
      currentUser.display_name &&
      currentUser.default_language.length > 0 &&
      currentUser.display_name.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleSocialChange = (event) => {
    let val = event.target.value;
    let name = event.target.name;

    setSocialDetails({ ...socialDetails, [name]: val });
  };

  useEffect(() => {
    if (currentUser !== undefined) {
      setLoader(false);
    }
  }, [currentUser]);

  useEffect(()=>{
    if(allSubs && subscriptionDetails){
      setCurrentSubscrptionDetails({
        name: getLanguageLabel(getSubDetails('name').toLowerCase()),
        cost: Number(Math.round((getSubDetails('cost_gb') * 1.2) / 100)),
        endDate: getFormattedDate(subscriptionDetails.end_date),
        tagline: getSubDetails('tagline'),
      });

      setNextSubscrptionDetails(
        subscriptionType !== subscriptionDetails.next_type
          ? {
              name: getSubDetails('name', subscriptionDetails.next_type),
              tagline: getSubDetails('tagline', subscriptionDetails.next_type),
              cost: Number(
                Math.round(
                  (getSubDetails('cost_gb', subscriptionDetails.next_type) *
                    1.2) /
                    100
                )
              ),
            }
          : null
      );
    }
  }, [allSubs, subscriptionDetails]);

  const closeBadDetailsModal = () => {
    setShowBadDetailsModal(false);
  }

  const handleFileChange = (e) => {
    let profileFile = e.target.files[0];
    if(profileFile){
      const reader = new FileReader()
      reader.addEventListener("load", () =>
        setSelectedImage(reader.result.toString() || "")
      );
      reader.readAsDataURL(profileFile)
      setShowCropModal(true)
    }
  };
  const handleChangeComplete = (colour) => {
    let copyUser = { ...currentUser };
    copyUser.haia_card_colour = colour.hex;
    setCurrentUser(copyUser);
  };
  const handleInterestsChange = (e) => {
    ///api/tags/hobbies?search="value"
    setInterestTag(e.target.value);
    axios
      .get(`${API_URL}/tags/hobbies?search=${e.target.value}`, config)
      .then(({ data }) => {
        if (data.length > 0) {
          setSuggestedInterests(data.splice(0, 5));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleLanguageChange = (e) => {
    setLanguageTag(e.target.value);
    axios
      .get(`${API_URL}/tags/languages?search=${e.target.value}`, config)
      .then(({ data }) => {
        if (data.length > 0) {
          setSuggestedLanguages(data.splice(0, 5));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSectorChange = (e) => {
    setSectorTag(e.target.value);
    axios
      .get(`${API_URL}/tags/sector?search=${e.target.value}`, config)
      .then(({ data }) => {
        if (data.length > 0) {
          setSuggestedSectors(data.splice(0, 5));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const capitaliseTag = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const submitNewTag = (tag, name) => {
    let tagVal = tag.toLowerCase();
    if (name === "interests_and_hobbies") {
      let exists = false;
      if (suggestedInterests !== undefined) {
        suggestedInterests.forEach((suggested) => {
          if (suggested.value.toLowerCase() === tagVal.toLowerCase()) {
            exists = true;

            let copyConfirmedInterests = [...confirmedInterests];
            copyConfirmedInterests.push(suggested);
            setConfirmedInterests(copyConfirmedInterests);
            setSuggestedInterests();
            setInterestTag("");
          }
        });
      }
      if (!exists) {
        axios
          .post(
            `${API_URL}/tags/hobbies`,
            { value: capitaliseTag(tagVal) },
            config
          )
          .then(({ data }) => {
            let copyConfirmedInterests = [...confirmedInterests];
            copyConfirmedInterests.push(data);
            setConfirmedInterests(copyConfirmedInterests);
            setSuggestedInterests();
            setInterestTag("");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (name === "sector") {
      let exists = false;
      if (suggestedSectors !== undefined) {
        suggestedSectors.forEach((suggested) => {
          if (suggested.value.toLowerCase() === tagVal.toLowerCase()) {
            exists = true;

            let copyConfirmedSectors = [...confirmedSectors];
            copyConfirmedSectors.push(suggested);
            setConfirmedSectors(copyConfirmedSectors);
            setSuggestedSectors();
            setSectorTag("");
          }
        });
      }
      if (!exists) {
        axios
          .post(
            `${API_URL}/tags/sector`,
            { value: capitaliseTag(tagVal) },
            config
          )
          .then(({ data }) => {
            let copyConfirmedSectors = [...confirmedSectors];
            copyConfirmedSectors.push(data);
            setConfirmedSectors(copyConfirmedSectors);
            setSuggestedSectors();
            setSectorTag("");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (name === "languages") {
      let exists = false;
      if (suggestedLanguages !== undefined) {
        suggestedLanguages.forEach((suggested) => {
          if (suggested.value.toLowerCase() === tagVal.toLowerCase()) {
            exists = true;

            let copyConfirmedLanguages = [...confirmedLanguages];
            copyConfirmedLanguages.push(suggested);
            setConfirmedLanguages(copyConfirmedLanguages);
            setSuggestedLanguages();
            setLanguageTag("");
          }
        });
      }
      if (!exists) {
        axios
          .post(
            `${API_URL}/tags/languages`,
            { value: capitaliseTag(tagVal) },
            config
          )
          .then(({ data }) => {
            let copyConfirmedLanguages = [...confirmedLanguages];
            copyConfirmedLanguages.push(data);
            setConfirmedLanguages(copyConfirmedLanguages);
            setSuggestedLanguages();
            setLanguageTag("");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const checkValidTag = (tag) => {
    if (tag.length === 0) {
      return false;
    } else {
      const pattern = /[^a-zA-Z]/g;

      return pattern.test(tag) ? false : true;
    }
  };

  const handleRenewalChange = (event) => {
    let val = event.target.value;
    if (val === "false") {
      val = false;
    } else if (val === "true") {
      val = true;
    }
    setRenewal(val);
    setRenewalChange(Date.now());
  };

  useEffect(() => {
    if (renewalChange !== undefined) {
      axios
        .post(
          `${SUBSCRIPTION_API_URL}/account/${props.jwt}/auto-renew/${renewal}`,
          null,
          config
        )
        .then(() => {
          axios
            .get(
              `${SUBSCRIPTION_API_URL}/account/${props.jwt}/subscription`,
              config
            )
            .then(({ data }) => {
              setSubscriptionType(data.sub_type);
              setSubscriptionDetails(data);
              setRenewal(data.auto_renew);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [renewalChange]);

  const getSubDetails = (key, type = subscriptionType) => {
    const filtered = allSubs.filter((sub) => {
      return sub.id === type;
    });

    if(filtered && filtered[0] && filtered[0][key] !== undefined)
    {
      return filtered[0][key];
    }

    return ""
  };

  const checkBrowserTimezone = () => {
    return getBrowserTimezone() ? getBrowserTimezone() : DEFAULT_TIMEZONE
  }
  
  const urlValidator = {
    isValid: (value) =>
      socialValidator.isValid(value, 'website') || value.length === 0,
    validatorTimeout: 500
  
  };

  const setSocialValue = (value, valid, name) => {
    let re = new RegExp(/^(http|https)/);
    let newValue = value;
    if (!re.test(value) && value != "") {
      newValue = `https://${newValue}`
    }

    setSocialDetails({
      ...socialDetails,
      [name]: {
        ...socialDetails[name],
        value: newValue,
        valid
      },
    });
  };
  

  const onCropCompletion = (blob) => {
    if (!blob) {
      setSelectedImage(undefined);
    } else {
      // if (currentUser && currentUser.name && uploadOrgFile) {
      //   uploadOrgFile(blob, localOrg.name, currentUploadKey);
      // }
      setProfileImageLoading(true);
      const file = new File([blob], `${user.id}.png`);
      let formData = new FormData();
      formData.append("file", file);
      axios
        .post(`${API_URL}/file`, formData, config)
        .then(({ data }) => {
          let copyUser = { ...currentUser };
          copyUser.profile_pic = data.url;
          setCurrentUser(copyUser);
          setShowCropModal(false);
          setProfileImageLoading(false);
          setSelectedImage(undefined);
        })
        .catch((err) => {
          console.log(err);
          setShowCropModal(false);
          setProfileImageLoading(false);
          setSelectedImage(undefined);
        });
    }
  };

  const onLanguageValueChange = (value) => {
    if(value && value.length > 2){
       axios
      .get(`${API_URL}/language/${value}`, config)
      .then(({ data }) => {
        setProfileLanguageSuggestions(data)
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
      setProfileLanguageSuggestions()
    }
  }

  const onLanguageSelectionClick = (selection) => {
    profileLanguageSuggestions.forEach((lang) => {
      if(lang.name === selection){
        setProfileLanguages(oldLanguages => [...oldLanguages, lang])
      }
    })
    setProfileLanguageSuggestions();
  }

  const onLanguageRemove = (language) => () => {
    setProfileLanguages(profileLanguages.filter((lang) => {return lang.name !== language.name}))
  }

  useEffect(() => {
    setFilteredLanguageSuggestions(profileLanguageSuggestions?.filter((suggestion) => {return !profileLanguages.some((lang) => {return lang.short_name === suggestion.short_name})}))
  }, [profileLanguageSuggestions, profileLanguages])

  const toggleBackgroundPickerClick = () => {
    setShowBackgroundPicker(!showBackgroundPicker)
  }

  const onBackgroundSelection = (selection) => {
    const copy = currentUser;
    copy.haia_card_colour = selection;
    setCurrentUser(copy);
    setShowBackgroundPicker(false);
  }

  const onBackgroundPickerClose = () => {
    setShowBackgroundPicker(false);
  }

  const onTabClick = (tab) => () => {
    navigate(tab);
    window.scrollTo(0, 0);
  }

  const cropCompletion = (blob) => {
    setShowCropModal(false);
    if (onCropCompletion) {
      onCropCompletion(blob);
    }
  };

  const onCropModalClose = () => {
    setShowCropModal(false);
    setSelectedImage(undefined);
  };

  const onProfileImageSelection = (selectedImage, key) => {
    setImageToCrop(selectedImage)
    // setCurrentImageKey(key);
    setShowCropModal(true);
  }

  useEffect(() => {
    if(general){
      window.scrollTo(0, 0)
    }
  }, [general])
  
  return (
    <main>
      {!loader && (
        <div className="content">
          <h1 className="vertical-modal-h1">
            {/* {getLanguageLabel('hi')}, {currentUser.first_name} */}
            {getLanguageLabel("my_account")}
          </h1>
          {/* <h2>{getLanguageLabel('please_tell_us_about_yourself')}</h2> */}

          <div className="vertical-modal">
            <nav className="side-nav" aria-label="profile edit">
              <button
                className={general ? "nav-item nav-item-selected" : "nav-item"}
                onClick={onTabClick("/profile")}
              >
                <i className="fad fa-user-circle"></i>

                <span>{getLanguageLabel("profile")}</span>
              </button>
              <button
                className={
                  organisation ? "nav-item nav-item-selected" : "nav-item"
                }
                onClick={onTabClick("/organisation")}
              >
                <i className="fad fa-briefcase"></i>

                <span>{getLanguageLabel("organisation")}</span>
              </button>

              {window["runConfig"]["DISABLE_SUBSCRIPTION_LOOKUP"] === false && (
                <button
                  className={
                    subscription ? "nav-item nav-item-selected" : "nav-item"
                  }
                  onClick={onTabClick("/subscription")}
                >
                  <i className="fad fa-credit-card"></i>

                  <span>{getLanguageLabel("plan")}</span>
                </button>
              )}
              <button
                className={settings ? "nav-item nav-item-selected" : "nav-item"}
                onClick={onTabClick("/settings")}
              >
                <i className="fad fa-sliders-h"></i>

                <span>{getLanguageLabel("settings")}</span>
              </button>
              <button
                className={
                  notifications ? "nav-item nav-item-selected" : "nav-item"
                }
                onClick={onTabClick("/notifications")}
              >
                <i className="fa-solid fa-bell-on"></i>

                <span>{getLanguageLabel("notifications")}</span>
              </button>
              <button
                className={
                  integrations ? "nav-item nav-item-selected" : "nav-item"
                }
                onClick={onTabClick("/integrations")}
              >
                <i className="fa-solid fa-code"></i>

                <span>{getLanguageLabel("website_integration")}</span>
              </button>
            </nav>
            <form className="vertical-modal-content" onSubmit={handleSubmit}>
              <Routes>
                <Route
                  path={"/organisation"}
                  element={
                    <OrganiserContextProvider>
                      <OrganisationTab
                        cookie={props.cookie}
                        accountID={props.jwt}
                      />
                    </OrganiserContextProvider>
                  }
                />
              </Routes>
              {general && (
                <>
                  <section>
                    <h2>{getLanguageLabel("profile")}</h2>
                    <p className="modal-prompt">
                      {getLanguageLabel(
                        "your_profile_will_be_shared_across_haia_events_dont_want_to_share_simply_dont_fill_in_the_information_you_dont_wish_to_share"
                      )}
                    </p>

                    {/* <div className="form-grid">
                      <div>
                        <label htmlFor="firstName">
                          {getLanguageLabel("first_name")} *
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          name="first_name"
                          onChange={handleChange}
                          required
                          value={currentUser.first_name}
                        ></input>
                      </div>
                      <div>
                        <label htmlFor="lastName">
                          {getLanguageLabel("last_name")} *
                        </label>
                        <input
                          type="text"
                          id="lastName"
                          name="last_name"
                          onChange={handleChange}
                          required
                          value={currentUser.last_name}
                        ></input>
                      </div>
                    </div> */}
                    <div className="form-grid">
                      <div>
                        <label htmlFor="display_name">
                          {getLanguageLabel("display_name")} *
                        </label>
                        <input
                          id="dislay_name"
                          name="display_name"
                          onChange={handleChange}
                          value={
                            currentUser.display_name === null
                              ? ""
                              : currentUser.display_name
                          }
                        ></input>

                        <label htmlFor="location">
                          {getLanguageLabel("location")}
                        </label>
                        <input
                          id="location"
                          name="location"
                          onChange={handleChange}
                          value={
                            currentUser.location === null
                              ? ""
                              : currentUser.location
                          }
                        ></input>
                      </div>
                      <div>
                        <label htmlFor="profile_picture">
                          {getLanguageLabel("avatar")}
                        </label>
                        <AvatarSection
                          currentUser={currentUser}
                          loading={profileImageLoading}
                          selectedImage={selectedImage}
                          setSelectedImage={setSelectedImage}
                          onImageSelection={onProfileImageSelection}
                          imageKey={"profile_pic"}
                          initials={true}
                        />
                      </div>
                    </div>
                    <div className="form-grid">
                      <div>
                        <label htmlFor="languages_I_speak">
                          {getLanguageLabel("languages_I_speak")}
                        </label>
                        <p className="modal-prompt">
                          {getLanguageLabel("select_as_many_as_you_want")}...
                        </p>
                      </div>
                      <div>
                        <SuggestionDropDown
                          labels={{
                            start_typing: getLanguageLabel("start_typing"),
                            no_suggestion_please_try_another_entry:
                              getLanguageLabel(
                                "no_suggestion_please_try_another_entry"
                              ),
                          }}
                          valueChange={onLanguageValueChange}
                          onSuggestionClick={onLanguageSelectionClick}
                          suggestions={filteredLanguageSuggestions?.map(
                            (lang) => {
                              return { text: lang.name };
                            }
                          )}
                          showImage={false}
                        />
                        <ul className="event-tag-container">
                          {profileLanguages?.map((lang, i) => {
                            return (
                              <li key={i} className="event-tag">
                                {lang.name}
                                <button
                                  type="button"
                                  onClick={onLanguageRemove(lang)}
                                >
                                  x
                                </button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="form-grid">
                      <div>
                        <label htmlFor="interests_and_hobbies">
                          {getLanguageLabel("interests_and_hobbies")}
                        </label>
                        <p className="modal-prompt">
                          {getLanguageLabel(
                            "enter_your_interests_or_hobbies_and_press_enter_there_is_no_limit"
                          )}
                        </p>
                      </div>
                      <div>
                        <input
                          style={{
                            border:
                              interestTag.length === 0
                                ? "#ccc 1px solid"
                                : !checkValidTag(interestTag)
                                ? "red 1px solid"
                                : "#ccc 1px solid",
                          }}
                          id="interests_and_hobbies"
                          value={interestTag}
                          name="interests_and_hobbies"
                          onChange={handleInterestsChange}
                          onKeyDown={(e) => {
                            if (
                              e.key === "Enter" &&
                              checkValidTag(interestTag)
                            ) {
                              e.preventDefault();

                              submitNewTag(
                                interestTag,
                                "interests_and_hobbies"
                              );
                            } else if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                          placeholder={`+ ${getLanguageLabel(
                            "add_a_tag_and_press_enter"
                          )}`}
                        ></input>
                        {suggestedInterests !== undefined && (
                          <ul className="event-tag-container">
                            {suggestedInterests.map((tag, i) => {
                              return (
                                <li
                                  tabIndex="0"
                                  key={i}
                                  className="suggested-tag"
                                  onClick={() => {
                                    let copyTags = [...confirmedInterests];
                                    copyTags.push(tag);
                                    setConfirmedInterests(copyTags);
                                    setSuggestedInterests();
                                    setInterestTag("");
                                  }}
                                >
                                  {tag.value}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        <ul className="event-tag-container">
                          {confirmedInterests.map((tag, i) => {
                            return (
                              <li key={i} className="event-tag">
                                {tag.value}
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    axios
                                      .delete(
                                        `${API_URL}/account/hobbies/${tag.id}`,
                                        config
                                      )
                                      .then(() => {
                                        let copyConfirmedInterests = [
                                          ...confirmedInterests,
                                        ];
                                        copyConfirmedInterests.splice(i, 1);
                                        setConfirmedInterests(
                                          copyConfirmedInterests
                                        );
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  }}
                                >
                                  x
                                </button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </section>
                  <section>
                    <label htmlFor="status">{getLanguageLabel("status")}</label>
                    <div className={ProfileStyles["bio-container"]}>
                      <input
                        type="text"
                        id="shortBio"
                        name="short_bio"
                        value={currentUser.short_bio ? currentUser.short_bio : ""}
                        onChange={handleChange}
                        placeholder={getLanguageLabel("job_title_or_status")}
                      ></input>
                    </div>
                    <label htmlFor="bio">{getLanguageLabel("bio")}</label>
                    <div
                      className={ProfileStyles["bio-container"]}
                      data-count={`(${
                        currentUser.bio ? currentUser.bio.length : 0
                      }/${MaxBioLength})`}
                    >
                      <textarea
                        id="bio"
                        name="bio"
                        onChange={handleChange}
                        rows={6}
                        className={ProfileStyles.bio}
                        value={currentUser.bio === null ? "" : currentUser.bio}
                        maxLength={MaxBioLength}
                      ></textarea>
                    </div>
                    <div className="form-grid">
                      <div>
                        <label htmlFor="contact_email">
                          {getLanguageLabel("email_address")}
                        </label>
                        <input
                          id="contact_email"
                          name="contact_email"
                          onChange={handleChange}
                          value={
                            currentUser.contact_email === null
                              ? ""
                              : currentUser.contact_email
                          }
                          type="email"
                        ></input>
                      </div>
                      <div>
                        <label htmlFor="contact_phone">
                          {getLanguageLabel("phone")}
                        </label>
                        <input
                          id="contact_phone"
                          name="contact_phone"
                          onChange={handleChange}
                          value={
                            currentUser.contact_phone === null
                              ? ""
                              : currentUser.contact_phone
                          }
                          type="tel"
                        ></input>
                      </div>
                    </div>
                    {socialDetails && socialDetails.facebook_url && (
                      <div>
                        <div className="form-grid">
                          <ValidatedInput
                            title="Facebook URL"
                            validator={urlValidator}
                            className={`facebook-url`}
                            id={`facebook_url`}
                            setValue={setSocialValue}
                            message={getLanguageLabel(
                              "badly_formed_url_message"
                            ).replace("XX", "Facebook")}
                            value={socialDetails.facebook_url.value}
                          />
                          <ValidatedInput
                            title="Twitter URL"
                            validator={urlValidator}
                            className={`twitter-url`}
                            id={`twitter_url`}
                            setValue={setSocialValue}
                            message={getLanguageLabel(
                              "badly_formed_url_message"
                            ).replace("XX", "Twitter")}
                            value={socialDetails.twitter_url.value}
                          />
                        </div>

                        <div className="form-grid">
                          <ValidatedInput
                            title="LinkedIn URL"
                            validator={urlValidator}
                            className={`linkedin-url`}
                            id={`linkedin_url`}
                            setValue={setSocialValue}
                            message={getLanguageLabel(
                              "badly_formed_url_message"
                            ).replace("XX", "LinkedIn")}
                            value={socialDetails.linkedin_url.value}
                          />

                          <ValidatedInput
                            title="YouTube URL"
                            validator={urlValidator}
                            className={`yoututbe-url`}
                            id={`youtube_url`}
                            setValue={setSocialValue}
                            message={getLanguageLabel(
                              "badly_formed_url_message"
                            ).replace("XX", "YouTube")}
                            value={socialDetails.youtube_url.value}
                          />
                        </div>
                      </div>
                    )}

                    <div className="modal-block">
                      <h3 className="haia-card-preview-text">
                        {getLanguageLabel("haia_card_preview")}
                      </h3>
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px",
                          marginBottom: "20px",
                        }}
                      >
                        {getLanguageLabel(
                          "your_haia_card_can_be_shared_with_other_attendees_at_events_youre_attending_or_participating_in_heres_what_it_looks_like_with_information_from_your_profile"
                        )}
                      </p>
                      {currentUser && socialDetails && (
                        <HaiaCard
                          cardDetails={{
                            id: currentUser.account_id,
                            title: currentUser.display_name,
                            subtitle: currentUser.short_bio,
                            image: currentUser.profile_pic,
                            ...(currentUser.bio && {
                              about: currentUser.bio,
                            }),
                            contacts: {
                              email: currentUser.contact_email,
                              website: currentUser.website,
                            },
                            socials: {
                              facebook: socialDetails.facebook_url?.value,
                              twitter: socialDetails.twitter_url?.value,
                              linkedIn: socialDetails.linkedin_url?.value,
                              instagram: socialDetails.instagram_url?.value,
                            },
                            backgroundColor: currentUser.haia_card_colour
                              ? currentUser.haia_card_colour
                              : "#FFF",
                            tags: confirmedInterests?.map((val) => {
                              return val.value;
                            }),
                            size: "small",
                          }}
                          hideCard={() => {}}
                        />
                      )}
                      <div className={ProfileStyles["choose-background"]}>
                        <p>{getLanguageLabel("choose_a_background")}</p>
                        {currentUser && currentUser.haia_card_colour ? (
                          <>
                            {currentUser.haia_card_colour.includes("http") ? (
                              <>
                                <img
                                  src={currentUser.haia_card_colour}
                                  className={
                                    ProfileStyles["choose-background-indicator"]
                                  }
                                  onClick={toggleBackgroundPickerClick}
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  className={
                                    ProfileStyles["choose-background-indicator"]
                                  }
                                  style={{
                                    background: currentUser.haia_card_colour,
                                  }}
                                  onClick={toggleBackgroundPickerClick}
                                ></div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div
                              className={
                                ProfileStyles["choose-background-indicator"]
                              }
                              onClick={toggleBackgroundPickerClick}
                            ></div>
                          </>
                        )}
                        {showBackgroundPicker && (
                          <BackgroundPicker
                            labels={{
                              image: getLanguageLabel("image"),
                              colour: getLanguageLabel("colour"),
                              cancel: getLanguageLabel("cancel"),
                              apply: getLanguageLabel("apply"),
                              custom: getLanguageLabel("custom"),
                              enter_a_hex_value:
                                getLanguageLabel("enter_a_hex_value"),
                            }}
                            onSelection={onBackgroundSelection}
                            onClose={onBackgroundPickerClose}
                          />
                        )}
                      </div>
                    </div>

                    <div className="button-container">
                      <i className="fad fa-lightbulb-on"></i>
                      <p>
                        {getLanguageLabel(
                          "dont_worry_you_can_change_all_these_details_later_too"
                        )}
                      </p>
                      <button
                        style={{ margin: "20px 5px" }}
                        disabled={checkKey(
                          "first_name",
                          "last_name",
                          "default_language"
                        )}
                        className={
                          checkKey(
                            "first_name",
                            "last_name",
                            "default_language"
                          )
                            ? "button-save disabled"
                            : "button-save"
                        }
                      >
                        {getLanguageLabel("save_and_close")}
                      </button>
                      <button
                        disabled={checkKey(
                          "first_name",
                          "last_name",
                          "default_language"
                        )}
                        className={
                          checkKey(
                            "first_name",
                            "last_name",
                            "default_language"
                          )
                            ? "button-save disabled"
                            : "button-save"
                        }
                        type="button"
                        onClick={() => {
                          navigate("/organisation");
                        }}
                      >
                        {getLanguageLabel("next")}
                      </button>
                    </div>
                  </section>
                </>
              )}
              {settings && (
                <section>
                  <h2>{getLanguageLabel("settings")}</h2>
                  <div className="form-grid">
                    <div>
                      <label htmlFor="language">
                        {getLanguageLabel("preferred_language")} *
                      </label>
                      <p className="modal-prompt">
                        {getLanguageLabel(
                          "select_your_preferred_language_to_display_platform_content"
                        )}
                      </p>
                    </div>
                    <div>
                      <select
                        id="language"
                        name="default_language"
                        value={currentUser.default_language}
                        onChange={handleChange}
                      >
                        <option value="en">
                          {getLanguageLabel("english")}
                        </option>
                        <option value="cy">{getLanguageLabel("welsh")}</option>
                      </select>
                    </div>

                    <div>
                      <label htmlFor="timezone">
                        {getLanguageLabel("profile_timezone")}
                      </label>
                      <p className="modal-prompt">
                        {getLanguageLabel("profile_timezone_description")}
                      </p>
                    </div>
                    <div>
                      <select
                        id="timezone"
                        name="timezone"
                        onChange={handleChange}
                        value={currentUser.timezone}
                      >
                        +
                        <option value={DEFAULT_TIMEZONE}>{`${getLanguageLabel(
                          "default"
                        )}: ${DEFAULT_TIMEZONE} (${getTimezoneAbbreviationFromName(
                          DEFAULT_TIMEZONE
                        )})`}</option>
                        <option
                          value={checkBrowserTimezone()}
                        >{`${getLanguageLabel(
                          "suggestion"
                        )}: ${checkBrowserTimezone()} (${getTimezoneAbbreviationFromName(
                          checkBrowserTimezone()
                        )}) `}</option>
                        {timezones &&
                          timezones.map((timezone, t) => {
                            return (
                              <option value={timezone}>
                                {timezone} (
                                {getTimezoneAbbreviationFromName(timezone)})
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="button-container">
                    <i className="fad fa-lightbulb-on"></i>
                    <p>
                      {getLanguageLabel(
                        "dont_worry_you_can_change_all_these_details_later_too"
                      )}
                    </p>
                    <button
                      disabled={checkKey(
                        "first_name",
                        "last_name",
                        "default_language"
                      )}
                      className={
                        checkKey("first_name", "last_name", "default_language")
                          ? "button-save disabled"
                          : "button-save"
                      }
                    >
                      {getLanguageLabel("save_and_close")}
                    </button>
                    <button
                      className="button-save"
                      type="button"
                      onClick={() => {
                        navigate("/notifications");
                      }}
                    >
                      {getLanguageLabel("next")}
                    </button>
                  </div>
                </section>
              )}
              {notifications && (
                <section>
                  <h2>{getLanguageLabel("notifications")}</h2>
                  <div className="form-grid">
                    <div>
                      <div>
                        <label htmlFor="opt_in_haia_marketing">
                          {getLanguageLabel("receive_marketing")}
                        </label>
                      </div>
                      <div>
                        <label className={SliderStyles.switch}>
                          <input
                            type="checkbox"
                            id="opt_in_haia_marketing"
                            name="opt_in_haia_marketing"
                            value={
                              currentUser.opt_in_haia_marketing ? false : true
                            }
                            checked={
                              currentUser.opt_in_haia_marketing ? true : false
                            }
                            onChange={handleChange}
                          />
                          <span className={SliderStyles.slider}></span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label htmlFor="opt_in_event_reminders">
                          {getLanguageLabel(
                            "would_you_like_to_recieve_event_reminders"
                          )}
                        </label>
                      </div>
                      <div>
                        <label className={SliderStyles.switch}>
                          <input
                            type="checkbox"
                            id="opt_in_event_reminders"
                            name="opt_in_event_reminders"
                            value={
                              currentUser.opt_in_event_reminders ? false : true
                            }
                            checked={
                              currentUser.opt_in_event_reminders ? true : false
                            }
                            onChange={handleChange}
                          />
                          <span className={SliderStyles.slider}></span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label htmlFor="opt_in_event_feedback">
                          {getLanguageLabel(
                            "would_you_like_to_recieve_post_event_feedback_emails"
                          )}
                        </label>
                      </div>
                      <div>
                        <label className={SliderStyles.switch}>
                          <input
                            type="checkbox"
                            id="opt_in_event_feedback"
                            name="opt_in_event_feedback"
                            value={
                              currentUser.opt_in_event_feedback ? false : true
                            }
                            checked={
                              currentUser.opt_in_event_feedback ? true : false
                            }
                            onChange={handleChange}
                          />
                          <span className={SliderStyles.slider}></span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label htmlFor="opt_in_event_suggestions">
                          {getLanguageLabel(
                            "would_you_like_to_recieve_event_suggestions"
                          )}
                        </label>
                      </div>
                      <div>
                        <label className={SliderStyles.switch}>
                          <input
                            type="checkbox"
                            id="opt_in_event_suggestions"
                            name="opt_in_event_suggestions"
                            value={
                              currentUser.opt_in_event_suggestions
                                ? false
                                : true
                            }
                            checked={
                              currentUser.opt_in_event_suggestions
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className={SliderStyles.slider}></span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label htmlFor="opt_in_haia_connections">
                          {getLanguageLabel(
                            "would_you_like_to_recieve_haia_connection_requests"
                          )}
                        </label>
                      </div>
                      <div>
                        <label className={SliderStyles.switch}>
                          <input
                            type="checkbox"
                            id="opt_in_haia_connections"
                            name="opt_in_haia_connections"
                            value={
                              currentUser.opt_in_haia_connections ? false : true
                            }
                            checked={
                              currentUser.opt_in_haia_connections ? true : false
                            }
                            onChange={handleChange}
                          />
                          <span className={SliderStyles.slider}></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="button-container">
                    <i className="fad fa-lightbulb-on"></i>
                    <p>
                      {getLanguageLabel(
                        "dont_worry_you_can_change_all_these_details_later_too"
                      )}
                    </p>
                    <button
                      disabled={checkKey(
                        "first_name",
                        "last_name",
                        "default_language"
                      )}
                      className={
                        checkKey("first_name", "last_name", "default_language")
                          ? "button-save disabled"
                          : "button-save"
                      }
                    >
                      {getLanguageLabel("save_and_close")}
                    </button>
                  </div>
                </section>
              )}
              {integrations && <WebsiteIntegration userID={user.account_id} />}
              {subscription && subsLoaded && (
                <section>
                  <h2>{getLanguageLabel("plan")}</h2>

                  <h3 style={{ fontSize: "16px", textAlign: "left" }}>
                    {getLanguageLabel("current_plan")}
                  </h3>
                  <SubscriptionDetails
                    className={
                      SubscriptionStyles["subscription-type-container"]
                    }
                    subscription={currentSubscrptionDetails}
                    callBack={() => {
                      externalNavigate("https://www.haia.live/plans/");
                      // navigate("https://www.haia.live/plans/");
                    }}
                    nextSubscription={nextSubscriptionDetails}
                    renewal={renewal}
                  />
                  <div></div>
                  {subscriptionType !== 1 && (
                    <div
                      className="flex-row"
                      style={{
                        alignItems: "center",
                        paddingTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <label
                        htmlFor="enable_auto_renew"
                        style={{
                          color: "var(--primary)",
                          marginRight: "20px",
                          fontSIze: "18px",
                        }}
                      >
                        {getLanguageLabel("auto_renew")}
                      </label>

                      <div>
                        <label className={SliderStyles.switch}>
                          <input
                            type="checkbox"
                            id="enable_auto_renew"
                            name="auto_renew"
                            value={renewal ? false : true}
                            checked={renewal ? true : false}
                            onChange={handleRenewalChange}
                          />
                          <span className={SliderStyles.slider}></span>
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="modal-block">
                    <h3>{getLanguageLabel("not_happy")}?</h3>
                    <p>
                      {getLanguageLabel(
                        "we_want_you_to_enjoy_your_haia_subscription_and_there_would_be_nothing_worse_than_seeing_you_unhappy"
                      )}{" "}
                      <a href="mailto:post@haia.live">
                        {getLanguageLabel("please_get_in_touch")}
                      </a>{" "}
                      {getLanguageLabel("to_see_what_we_can_do")}
                    </p>
                  </div>
                  <div className="button-container">
                    <i className="fad fa-lightbulb-on"></i>
                    <p>
                      {getLanguageLabel(
                        "dont_worry_you_can_change_all_these_details_later_too"
                      )}
                    </p>
                    <button
                      disabled={checkKey(
                        "first_name",
                        "last_name",
                        "default_language"
                      )}
                      className={
                        checkKey("first_name", "last_name", "default_language")
                          ? "button-save disabled"
                          : "button-save"
                      }
                    >
                      {getLanguageLabel("save_and_close")}
                    </button>
                    <button
                      className="button-save"
                      type="button"
                      onClick={() => {
                        navigate("/settings");
                      }}
                    >
                      {getLanguageLabel("next")}
                    </button>
                  </div>
                </section>
              )}{" "}
              {subscription && !subsLoaded && <Loader />}
            </form>
          </div>

          {(subscriptionModal || stateModal === "plans") && subsLoaded && (
            <div>
              {/* <Elements stripe={stripePromise}>
                <SubscriptionModal
                  subscriptionType={subscriptionType}
                  subscriptionDetails={subscriptionDetails}
                  setSubscriptionModal={() => {
                    setstateModal(undefined);
                    navigate("/subscription");
                  }}
                  cookie={props.cookie}
                  jwt={props.jwt}
                  setThankYouModal={setThankYouModal}
                  allSubs={allSubs
                    .filter(
                      ({ name, id }) => !(name.includes("Annual") || id === 11)
                    )
                    .sort((a, b) =>
                      a.cost_gb < b.cost_gb ? -1 : a.cost_gb > b.cost_gb ? 1 : 0
                    )}
                  user={user}
                  plan={plan}
                />
              </Elements> */}
            </div>
          )}
          {thankYouModal && (
            <div>
              <div
                className="modal-backdrop"
                onClick={() => setThankYouModal(false)}
              ></div>
              <div
                className="modal-popup modal"
                style={{ boxSizing: "border-box", padding: "20px 40px" }}
              >
                <h2
                  style={{
                    color: "var(--primary)",
                    fontSize: "28px",
                    textAlign: "center",
                  }}
                >
                  {getLanguageLabel("thank_you")}!
                </h2>
                <p style={{ color: "var(--primary)", textAlign: "center" }}>
                  {getLanguageLabel(
                    "your_order_is_now_complete_you_should_receive_an_email_with_your_receipt_and_all_of_the_details"
                  )}
                </p>
                <img
                  src={thank_you}
                  alt=""
                  style={{
                    width: "80%",
                    display: "block",
                    margin: "20px auto",
                  }}
                ></img>
                <button
                  onClick={() => externalNavigate(`${EVENT_URL}/events`)}
                  type="button"
                  style={{
                    background: "white",
                    color: "var(--primary)",
                    padding: "20px 30px",
                    borderRadius: "5px",
                    border: "none",
                    position: "absolute",
                    top: "50%",
                    fontSize: "20px",
                    fontWeight: "600",
                    width: "fit-content",
                    left: "calc(50% - 101px)",
                  }}
                >
                  {getLanguageLabel("lets_get_started")}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {showBadDetailsModal && (
        <DismissModal
          onClose={closeBadDetailsModal}
          title={getLanguageLabel("errors_in_details_title")}
          className={ProfileStyles.dismiss}
        >
          <p>{getLanguageLabel("errors_in_details_message")}</p>
        </DismissModal>
      )}
      {showCropModal && (
        <ImageCrop
          selectedImage={imageToCrop}
          onModalClose={onCropModalClose}
          onCropCompletion={cropCompletion}
          labels={{
            continue: getLanguageLabel("continue"),
            cancel: getLanguageLabel("cancel"),
            crop_image: getLanguageLabel("crop_image"),
          }}
        />
      )}
    </main>
  );
};
export default Profile;
