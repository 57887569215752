import { ErrorPage, HaiaLoader, ProfileImage, useLanguage, Warning } from "@haia/haia-components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useOrganisation } from "../../../API/organisation";
import { useGetUser } from "../../../API/profile";
import styles from './organisationmemberinvitepage.module.scss'

//@ts-ignore
const { ORGANISATIONS_API_URL } = window["runConfig"] ? window["runConfig"] : "https://event.dev.haia.live";
//@ts-ignore
const { EVENT_URL } = window["runConfig"] ? window["runConfig"] : "https://event.dev.haia.live";


type OrganisationMemberInvitePageProps = {
  cookie: string;
  user: API_Public_Profile;
};

const OrganisationMemberInvitePage = ({ cookie, user }: OrganisationMemberInvitePageProps) => {
  

    const { getLanguageLabel } = useLanguage();

    const [searchParams, setSearchParams] = useSearchParams();
    const orgID = searchParams.get("organisation_id");

    const navigate = useNavigate();

    const {fetchUser, user: owner} = useGetUser({cookie: cookie})
    const {fetchOrganisation, 
      fetchedOrganisation, 
      fetchedOrganisationError, 
      fetchedOrganisationLoading, 
      acceptMemberInvite, 
      acceptMemberInviteError, 
      acceptMemberInviteLoading,
      memberInvite,
      memberInviteError,
      memberInviteLoading,
      fetchMemberInvite,
    } = useOrganisation({cookie: cookie});

    const [paramError, setParamError] = useState(false)
    const [accepted, setAccepted] = useState(false)
    const [hasInvite, setHasInvite] = useState(false)


    useEffect(() => {
      if(orgID){
        fetchOrganisation(orgID)
      }
    }, [orgID])

    useEffect(() => {
      if(!orgID){
        setParamError(true);
      }
    }, [orgID])

    useEffect(() => {
      if(orgID){
        fetchMemberInvite(orgID);
      }
    }, [orgID])

    useEffect(() => {
      if(memberInvite){
        setHasInvite(true);
        if(memberInvite.account_id){
          setAccepted(true);
        } else {
          setAccepted(false);
        }
      } else {
        setHasInvite(false)
      }
    }, [memberInvite])

    useEffect(() => {
      if(fetchedOrganisation){
        if(fetchedOrganisation.owner_id){
          fetchUser(fetchedOrganisation.owner_id);
        }
      }
    }, [fetchedOrganisation])

    const onCancelHandler = () => {
      navigate("/organisation")
    }

    const onContinueHandler = () => {
      if(orgID){
        acceptMemberInvite(orgID);
      }
    }

    useEffect(() => {
      if(acceptMemberInviteLoading === false && !acceptMemberInviteError){
        navigate("/organisation")
      }
    }, [acceptMemberInviteLoading, acceptMemberInviteError])

  return (
    <div className={styles["content"]}>
      <main>
        {fetchedOrganisation &&
          !paramError &&
          !memberInviteError &&
          !accepted &&
          hasInvite && (
            <>
              <div className={styles["inner"]}>
                <h2>
                  {getLanguageLabel(
                    "you_have_been_invited_to_join_an_organisation"
                  )}
                  :
                </h2>
                <div className={styles["organisation"]}>
                  <ProfileImage
                    type={"sqaure"}
                    image={fetchedOrganisation.logo_url}
                    backgroundColour={fetchedOrganisation.tertiary_colour}
                  />
                  <div className={styles["organisation-inner"]}>
                    <div className={styles["header"]}>
                      <p className={styles["title"]}>
                        {fetchedOrganisation.name}
                      </p>
                      {owner && (
                        <div className={styles["owner"]}>
                          <p>
                            {getLanguageLabel("by")} {owner?.display_name}
                          </p>
                          <ProfileImage
                            image={owner?.profile_pic}
                            type={"circle"}
                            displayName={owner.display_name}
                            backgroundKey={owner.account_id}
                          />
                        </div>
                      )}
                    </div>
                    {fetchedOrganisation.bio && (
                      <p className={styles["bio"]}>{fetchedOrganisation.bio}</p>
                    )}
                  </div>
                </div>
                {/* <h3>
                <a
                  href={`/organisation/${fetchedOrganisation?.id}`}
                  target={"_blank"}
                >
                  {fetchedOrganisation?.name}
                </a>
                <span> {getLanguageLabel('managed_by} {owner?.display_name}</span>
              </h3> */}
                <p>
                  {
                    getLanguageLabel('accepting_this_invite_will_allow_you_to_create_events_on_behalf_of_this_organisation')
                  }
                </p>
              </div>
              {acceptMemberInviteError && (
                <Warning>
                  <span>{getLanguageLabel("an_error_occured")}</span>
                </Warning>
              )}
              <footer>
                <button onClick={onCancelHandler} className={styles["red"]}>
                  {getLanguageLabel("decline_invite")}
                </button>
                <button className={styles["green"]} onClick={onContinueHandler}>
                  {getLanguageLabel("confirm")}
                </button>
              </footer>
            </>
          )}
        {fetchedOrganisationLoading === true && <HaiaLoader />}
        {paramError && (
          <div className={styles["error"]}>
            <ErrorPage
              firstImage={
                "https://haia-icons.s3.eu-west-2.amazonaws.com/error/error-oops.svg"
              }
              title={getLanguageLabel("missing_url_parameters")}
            />
          </div>
        )}
        {memberInviteError ||
          (!hasInvite && (
            <div className={styles["error"]}>
              <ErrorPage
                firstImage={
                  "https://haia-icons.s3.eu-west-2.amazonaws.com/error/error-404.svg"
                }
                title={
                  getLanguageLabel('unable_to_find_invite_are_you_logged_in_to_the_correct_account')
                }
              />
            </div>
          ))}
        {accepted && (
          <div className={styles["error"]}>
            <ErrorPage
              firstImage={
                "https://haia-icons.s3.eu-west-2.amazonaws.com/error/error-404.svg"
              }
              title={getLanguageLabel("invite_already_accepted")}
            />
          </div>
        )}
      </main>
    </div>
  );
};

export default OrganisationMemberInvitePage;
