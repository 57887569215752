import React from "react";

export const formatURL = (url?: string) => {
  if (url) {
    let newURL = url;
    if (!url.includes("www.")) {
      newURL = `www.${url}`;
    }
    if (!url.includes("http://") && !url.includes("https://")) {
      newURL = `https://${newURL}`;
    }

    return newURL;
  }
  return undefined;
};

export const handleEnterDown = (func?: Function) => (e: React.KeyboardEvent<HTMLDivElement>) => {
  if(e.key === 'Enter' && func){
    func();
  }
}