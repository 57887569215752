import { HaiaLoader, ImageCrop, useExternalNavigate, useLanguage, Warning } from "@haia/haia-components";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useOrganisation } from "../../API/organisation";
import AddMemberModal from "../../Components/Organisation/AddMemberModal";
import OrganisationCard from "../../Components/Organisation/OrganisationCard";
import OrganisationList from "../../Components/Organisation/OrganisationList";
import OrganisationMembersModal from "../../Components/Organisation/OrganisationMembersModal";
import OrganisationModal from "../../Components/Organisation/OrganisationModal";
import EditOrganisationModal from "../../Components/Organisation/OrganisationModal/EditOrganisationModal";
import OrganiserContext from "../../Context/Organiser/OrganiserContext";
import { changeMembersChanged } from "../../Context/Organiser/reducer";
import styles from "./tabstyles.module.scss";
import { useParams, useSearchParams } from "react-router-dom";

// @ts-ignore
const { PAYMENT_API_URL } = window["runConfig"] ? window["runConfig"]
    : "https://payments.dev.haia.live/api";

type OrganisationTabProps = {
    cookie: string
    accountID?: string;
}

const OrganisationTab = ({cookie, accountID}: OrganisationTabProps) => {

    const { dispatch } = useContext(OrganiserContext);

    const {getLanguageLabel} = useLanguage();

    const navigate = useNavigate()
    const {externalNavigate} = useExternalNavigate()

    const [searchParams, setSearchParams] = useSearchParams();
    const stage = searchParams.get("stage");
    const encodedRedirect = searchParams.get("redirect_url");
    
    const {
      getUserOrganisations, 
      userOrganisations,
      userOrganisationsError,
      userOrganisationsLoading,
      createOrganisation, 
      createOrganisationError, 
      createOrganisationLoading,
      addMemberToOrganisation,
      addMemberLoading,
      removeMemberFromOrganisation,
      removeInviteFromOrganisation,
      removeMemberLoading, 
      uploadOrgFile, 
      fileUploadLoading,
      setFileUploadResponse,
      fileUploadResponse,
      deleteOrganisation,
      deleteOrganisationLoading,
      updateOrg,
      updateOrganisationLoading,
      updateOrganisationError
    } = useOrganisation({cookie: cookie})
    const [combinedOrganisations, setCombinedOrganisations] = useState<API_Organisation[]>();
    
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState<API_Organisation>()


    const [showOrgMembersModal, setShowOrgMembersModal] = useState<API_Organisation>()


    useEffect(() => {
       getUserOrganisations();
    }, [cookie])

    useEffect(() => {
      if((userOrganisations && (userOrganisations.member_of || userOrganisations.owned))){
        let orgs: API_Organisation[] = []
        if(userOrganisations.member_of){
          orgs = [...userOrganisations.member_of];
        }
        if(userOrganisations.owned){
          orgs = [...orgs, ...userOrganisations.owned]
        }
        setCombinedOrganisations(orgs);
      }
    }, [userOrganisations])

    const handleShowCreateModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowCreateModal(true);
    }

    const handleCloseCreateModal = () => {
        setShowCreateModal(false)
        setShowEditModal(undefined);
        setFileUploadResponse(undefined);
    }

    useEffect(() => {
        if(createOrganisationLoading === false && !createOrganisationError){
          if(stage){
            if(encodedRedirect){
              externalNavigate(`${encodedRedirect}`);
            } else {
               navigate("/organisation");
            }
          }
          setShowCreateModal(false);
          setFileUploadResponse(undefined);
          getUserOrganisations();
        }  
    }, [createOrganisationLoading])

    useEffect(() => {
        if(removeMemberLoading !== undefined && removeMemberLoading === false){
            getUserOrganisations();
        }
    }, [removeMemberLoading])

    useEffect(() => {
      if (deleteOrganisationLoading !== undefined && deleteOrganisationLoading === false) {
        getUserOrganisations();
      }
    }, [deleteOrganisationLoading])

     useEffect(() => {
        if(updateOrganisationLoading === false && !updateOrganisationError){
          setShowEditModal(undefined);
          setFileUploadResponse(undefined);
          getUserOrganisations();
        }  
    }, [updateOrganisationLoading])

    useEffect(() => {
      if(addMemberLoading === false){
        dispatch(changeMembersChanged(Date.now()))
      }
    }, [addMemberLoading])

    useEffect(() => {
      if (removeMemberLoading === false) {
        dispatch(changeMembersChanged(Date.now()));
      }
    }, [removeMemberLoading]);


    const onCreateOrg = (org: API_Organisation) => {
      createOrganisation(org);
    }

    const onShowMembers = (org: API_Organisation) => {
      setShowOrgMembersModal(org);
    }

    const onCloseMembersModal = () => {
      setShowOrgMembersModal(undefined);
    }

    const onOrgLeave = (org: API_Organisation) => {
      if(org){
        if(org.owner_id && accountID && org.owner_id == accountID && org.id){
          deleteOrganisation(org.id);
        } else if(org.id && accountID){
          removeMemberFromOrganisation(org.id, accountID)
        }
      }
    }

    const onOrgEditClicked = (org: API_Organisation) => {
      setShowEditModal(org);
    }

    const onOrgViewClicked = (org: API_Organisation) => {
      navigate(`/organisation/${org.id}`)
    }

    const onOrgLinkPayment = (org: API_Organisation) => {
        window.location.href = `${PAYMENT_API_URL}/link/${org.id}`;
    }

    const onUpdateOrg = (org: API_Organisation) => {
      updateOrg(org);
    }

    const closeRoutedModal = () => {
      if (encodedRedirect) {
        externalNavigate(`${encodedRedirect}`);
      } else {
        navigate("/organisation");
      }
    }
 
  return (
    <section className={styles["organisation-tab"]}>
      <h2>{getLanguageLabel("organisation")}</h2>
      <div>
        <p>
          {getLanguageLabel(
            "adding_organisations_allows_you_to_share_the_admin_work_of_creating_events_with_other_members_of_your_team"
          )}
        </p>
        {userOrganisationsLoading === true && <HaiaLoader />}
        {userOrganisationsError && (
          <Warning>
            {getLanguageLabel("an_error_occurred_while_loading_organisations")}
          </Warning>
        )}
        {!userOrganisationsError && !combinedOrganisations && (
          <div className={styles["inner-block"]}>
            <h4>
              {getLanguageLabel(
                "you_are_not_a_member_of_any_organisations_yet"
              )}
              .
            </h4>
            <button
              className={`${styles["button"]} ${styles["green"]}`}
              onClick={handleShowCreateModal}
            >
              <i className="fa-solid fa-plus"></i>{" "}
              {getLanguageLabel("add_organisation")}
            </button>
          </div>
        )}
        {!userOrganisationsError && combinedOrganisations && (
          <>
            <OrganisationList
              organisations={combinedOrganisations}
              onShowMembers={onShowMembers}
              onOrgLeave={onOrgLeave}
              accountID={accountID}
              onOrgEdit={onOrgEditClicked}
              onOrgView={onOrgViewClicked}
              onOrgLinkPayment={onOrgLinkPayment}
            />
            <br />
            <div className={styles["list-button-container"]}>
              <button
                className={`${styles["button"]} ${styles["green"]}`}
                onClick={handleShowCreateModal}
              >
                <i className="fa-solid fa-plus"></i>{" "}
                {getLanguageLabel("add_organisation")}
              </button>
            </div>
          </>
        )}
      </div>
      {showCreateModal && (
        <OrganisationModal
          onClose={handleCloseCreateModal}
          uploadOrgFile={uploadOrgFile}
          fileUploadLoading={fileUploadLoading}
          fileUploadResponse={fileUploadResponse}
          cookie={cookie}
          userID={accountID}
          handleCreateOrg={onCreateOrg}
        />
      )}
      {showEditModal && (
        <EditOrganisationModal
          organisation={showEditModal}
          onClose={handleCloseCreateModal}
          uploadOrgFile={uploadOrgFile}
          fileUploadLoading={fileUploadLoading}
          fileUploadResponse={fileUploadResponse}
          cookie={cookie}
          userID={accountID}
          handleUpdateOrg={onUpdateOrg}
        />
      )}
      {showOrgMembersModal && (
        <OrganisationMembersModal
          onClose={onCloseMembersModal}
          organisation={showOrgMembersModal}
          cookie={cookie}
          accountID={accountID}
          addMemberToOrg={addMemberToOrganisation}
          removeMember={removeMemberFromOrganisation}
          removeInvite={removeInviteFromOrganisation}
        />
      )}
      {stage === "new" && (
        <OrganisationModal
          onClose={closeRoutedModal}
          uploadOrgFile={uploadOrgFile}
          fileUploadLoading={fileUploadLoading}
          fileUploadResponse={fileUploadResponse}
          cookie={cookie}
          userID={accountID}
          handleCreateOrg={onCreateOrg}
        />
      )}
    </section>
  );
};

export default OrganisationTab;
