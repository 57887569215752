import React, { useEffect, useState, useRef } from "react";
import styles from './backgroundpicker.module.scss';
import PickerSelectionList from "./PickerSelectionList";

type BackgroundPickerLabels = {
    image: string;
    colour: string;
    cancel: string;
    apply: string;
    custom: string;
    enter_a_hex_value: string;
}

export type BackgroundPickerProps = {
    onSelection: (selection: string) => void;
    allowCustom?: boolean;
    onClose: () => void;
    labels?: BackgroundPickerLabels;
};

const BackgroundPicker = ({
    onSelection,
    allowCustom = false,
    onClose,
    labels
}: BackgroundPickerProps) => {

    const images = [
        "https://storage.googleapis.com/haia-live-profile-live-public/cards/dark-1.jpg",
        "https://storage.googleapis.com/haia-live-profile-live-public/cards/dark-2.jpg",
        "https://storage.googleapis.com/haia-live-profile-live-public/cards/dark-3.jpg",
        "https://storage.googleapis.com/haia-live-profile-live-public/cards/dark-4.jpg",
        "https://storage.googleapis.com/haia-live-profile-live-public/cards/light-1.jpg",
        "https://storage.googleapis.com/haia-live-profile-live-public/cards/light-2.jpg",
        "https://storage.googleapis.com/haia-live-profile-live-public/cards/light-3.jpg",
        "https://storage.googleapis.com/haia-live-profile-live-public/cards/light-4.jpg",
    ];

    const colours = [
        "#5C2482",
        "#00AF88",
        "#E02828",
        "#292C3E",
        "#2891BF", 
        "#D8629D",
        "#E5B852",
        "#D4ABF5",
        "#94EFD6",
        "#F2A49B",
        "#E3E5EF",
        "#C1ECFF",
        "#FDD5E9",
        "#FFF"
    ]

    const [selected, setSelected] = useState<string>('#FFF');

    const onSelectionHandler = (selection: string, type: SelectionType) => {
        setSelected(selection)
    }

    const onApplyClick = () => {
        onSelection(selected)
    }

  return (
    <div className={styles["background-picker-container"]}>
      <div className={styles["background-picker"]}>
        <h3>{labels?.image}</h3>
        <PickerSelectionList selectionList={images} type={"background"} currentSelection={selected} onSelection={onSelectionHandler}/>
      </div>
      <div className={styles["background-picker"]}>
        <h3>{labels?.colour}</h3>
        <PickerSelectionList selectionList={colours} type={"colour"} currentSelection={selected} onSelection={onSelectionHandler}/>
      </div>
      {allowCustom && (
        <div className={styles["background-picker"]}>
        <h3>{labels?.custom} <span>({labels?.enter_a_hex_value})</span></h3>
        <div className={styles['custom-selection']}>
            <input defaultValue={'#'}/>
            {selected?.includes('http') ? (
                <img className={styles['indicator']} src={selected}/>
            ) : (
                <div className={styles['indicator']} style={{background: selected}}></div>
            )}
        </div>
      </div>
      )}
      <div className={styles["buttons"]}>
         <div className={styles["cancel-button"]} onClick={onClose}>{labels?.cancel}</div>
         <div className={styles["apply-button"]} onClick={onApplyClick}>{labels?.apply}</div>
      </div>
    </div>
  );
};

export default BackgroundPicker;
