import { useLanguage } from '@haia/haia-components';
import React, { useRef } from 'react';
import style from './websiteIntegration.module.scss';

type AccountIDProps = { userID: string; };
const AccountID = ({ userID }: AccountIDProps) => {

  const { getLanguageLabel } = useLanguage();

  const idRef = useRef<HTMLInputElement>(null);
  const copyIDRef = useRef<HTMLParagraphElement>(null);
  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (idRef.current) {
      idRef.current.select();
      idRef.current.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(idRef.current.value);
      copyIDRef.current?.classList.add(style.copied);
      setTimeout(() => {
        copyIDRef.current?.classList.remove(style.copied);
      }, 1000);
    }
  };

  
  return (
    <>
      <h3>{getLanguageLabel("user_id")}</h3>
      <p>{getLanguageLabel("user_id_description")}</p>
      <p
        className={style["user-id"]}
        data-after-text={getLanguageLabel("copied")}
        ref={copyIDRef}
      >
        <input className={style["user-id-input"]} ref={idRef} value={userID} />
        <button
          className={style.button}
          onClick={handleCopy}
          title={getLanguageLabel("copy_to_clipboard")}
        >
          <i className="fa-regular fa-copy"></i>
        </button>
      </p>
    </>
  );
}

export default AccountID;