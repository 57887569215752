import React, { ReactNode, useState, useReducer, useEffect } from "react";
import OrganiserContext from "./OrganiserContext";
import { initialOrganiserContextState, organiserContextReducer } from "./reducer";

type ProviderProps = {
  children: ReactNode;
};
const OrganiserContextProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(organiserContextReducer, initialOrganiserContextState);

  return (
    <OrganiserContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </OrganiserContext.Provider>
  );
};

export default OrganiserContextProvider;
