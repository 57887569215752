import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "./addmembermodal.module.scss";
import tinycolor from "tinycolor2";
import { ConfirmModal, EmailInput, useLanguage } from "@haia/haia-components";


type AddMemberModalProps = {
    onClose: () => void;
    onComplete: (invitee: string) => void;
    orgName?: string;
};

const AddMemberModal = ({
  onClose,
  onComplete,
  orgName,
}: AddMemberModalProps) => {

    const { getLanguageLabel } = useLanguage();

    const [invitee, setInvitee] = useState("")
    const [validInput, setValidInput] = useState<boolean>()


    const onConfirmHandle = () => {
        if(validInput){
            onComplete(invitee);
        }
    }

  return (
    <ConfirmModal showHeader={false} title={''} onConfiirm={onConfirmHandle} onClose={onClose} confiirmLabel={getLanguageLabel('invite_member')} cancelLabel={getLanguageLabel('cancel')} shouldCloseOnAction={false}>
        <section
            className={`${styles["add-organisation-modal-content"]} ${styles["padded"]}`}
        >
            <h2>{getLanguageLabel('invite_a_member')} {orgName && `to ${orgName}`}</h2>
            <p>{getLanguageLabel('enter_member_email_address')}</p>
            <EmailInput value={invitee} setValue={setInvitee} setValidInput={setValidInput} placeholder={`john@haia.live`} validInput={validInput}/>
        </section>
    </ConfirmModal>
  );
};

export default AddMemberModal;
