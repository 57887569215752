import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "./organisationlist.module.scss";
import OrganisationListItem from "./OrganisationListItem";

type OrganisationListProps = {
  organisations: API_Organisation[];
  onShowMembers?: (org: API_Organisation) => void;
  onOrgLeave?: (org: API_Organisation) => void;
  accountID?: string;
  onOrgEdit?: (org: API_Organisation) => void;
  onOrgView?: (org: API_Organisation) => void;
  onOrgLinkPayment?: (org: API_Organisation) => void;
};

const OrganisationList = ({organisations, onShowMembers, onOrgLeave, accountID, onOrgEdit, onOrgView, onOrgLinkPayment}: OrganisationListProps) => {
  return (
    <div className={styles['organisation-list']}>
        {organisations && organisations.map((org, i) => {
            return <OrganisationListItem organisation={org} key={`organisation-list-item-${org.id}`} onShowMembers={onShowMembers} onOrgLeave={onOrgLeave} accountID={accountID} onOrgEdit={onOrgEdit} onOrgView={onOrgView} onOrgLinkPayment={onOrgLinkPayment}/>
        })}
    </div>
  );
};

export default OrganisationList;
