import "./App.css";

import Profile from "./Pages/profile";
import CookieBanner from "./Components/cookiebanner";
import DevWarning from "./Components/devWarning";
import CreateUser from "./Pages/createUser";
import Nav from "./Components/nav";
import Loader from "./Components/loader";
import axios from "axios";
import { useState, useEffect } from "react";
// import { Router } from "@reach/router";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import jwtDecode from "jwt-decode";
// import { Link } from "@reach/router";
import {AuthModal, LanguageContextProvider, NavBar, NavLink, PrivateRoute, useExternalNavigate, RedirectContextProvider, useJWT, ThemeProvider} from '@haia/haia-components';
import { faUserHeadset, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { faTicket } from '@fortawesome/pro-duotone-svg-icons';
import ErrorPage from "./Pages/route-error-page";
import OrganisationPublicPage from "./Pages/Organisation/OrganisationPublicPage";
import { ErrorBoundary } from "react-error-boundary";
import error from "../src/Images/error.png";
import logo from '../src/Images/error-logo.png';
import OrganisationInvitePage from "./Pages/Organisation/OrganisationInvitePage";
import OrganisationMemberInvitePage from "./Pages/Organisation/OrganisationMemberInvitePage";
import UserProfilePage from "./Pages/UserProfile/UserProfilePage";
import { ContentContainer } from "./Pages/ContentContainer";
const languages = require('./languages.json');
const theme = require('./theme.json')

function App() {
  const { PROFILE_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://profile.dev.haia.live/";
  const { API_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://profile-api.dev.haia.live/api";
  const { EVENT_API_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://event-api.dev.haia.live/api";
  const { ACCOUNT_URL } = window["runConfig"]
    ? window["runConfig"]
    : "https://accounts.dev.haia.live";

  const { EVENT_URL } = window["runConfig"] 
    ? window["runConfig"] 
    : "https://event.dev.haia.live";

  const [user, setUser] = useState();
  const [language, setLanguage] = useState();
  const [availableLanguages, setAvailableLanguages] = useState();
  const [cookie, setCookie] = useState();
  const [jwt, setJwt] = useState();
  const [loading, setLoading] = useState(true);
  const [edited, setEdited] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [cookieAdded, setCookieAdded] = useState();
  const [displayCookieBanner, setDisplayCookieBanner] = useState();
  const [profileProps, setProfileProps] = useState();
  const [profileError, setProfileError] = useState();

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [propsLoaded, setPropsLoaded] = useState(false)

  const [loggedIn, setLoggedIn] = useState(false)
  const [appSlide, setAppSlide] = useState();

  const [displayAuth, setDisplayAuth] = useState(false);

  const profileLivePattern = /profile\.haia\.live/g;

  const {externalNavigate} = useExternalNavigate();
  const {getToken, token} = useJWT({});

  let config = {
    headers: {
      Authorization: `Bearer ${cookie}`,
    },
  };

  //cookie banner:
  useEffect(() => {
    if (document.cookie.includes("haia-cookie")) {
      setDisplayCookieBanner(false);
    } else {
      setDisplayCookieBanner(true);
    }
  }, [cookieAdded]);

  useEffect(() => {
    if (document.cookie.includes("haia-token")) {
      let token = "";
      document.cookie.split("; ").forEach((cookie) => {
        let key = cookie.split("=")[0];
        let val = cookie.split("=")[1];
        if (key === "haia-token") {
          token = val;
          setCookie(token);
          setJwt(jwtDecode(token).id);
          setIsAuthenticated(true);
        }
      });
    } else {
      // window.location = `${ACCOUNT_URL}/login?redirect_url=${window.location.href}`;
      setPropsLoaded(true);
      setIsAuthenticated(false);
    }
    axios.get(`${EVENT_API_URL}/available-languages`).then(({data}) => {
      const langsObj = {};
      data.forEach((el) => (langsObj[el.language] = el.name));
      setAvailableLanguages(langsObj);
    });
  }, []);

  useEffect(() => {
    if (cookie !== undefined) {
      axios
        .get(`${API_URL}/account/profile`, config)
        .then(({ data, status }) => {

          setProfileError(false);
          setLoggedIn(true)
          if(status === 204){
            setUserNotFound(true);
            setLanguage("en");
            setLoading(false);
          } else {
            setUser(data);
            setLanguage(data.default_language);
            setUserNotFound(false);
            setLoading(false);
          }
        })
        .catch((err) => {
          setProfileError(true);
          console.log(err);
          if (err.response && err.response.status === 404) {
            //if 404 not found create user
            const params = new URLSearchParams(window.location.search);
            if (params.get("lang") === "cy") {
              setLanguage("cy");
            } else {
              axios
                .get(`${EVENT_API_URL}/locale`)
                .then(({ data }) => {
                  const langPreference = data.locale[0].split("-")[0];
                  setLanguage(langPreference);
                })
                .catch((err) => {
                  console.log(err);
                  if (err) {
                    setLanguage("en");
                  }
                });
            }
            setUserNotFound(true);
          } else if (err.response && err.response.status === 401) {
            setIsAuthenticated(false);
            setPropsLoaded(true);
            // window.location = `${ACCOUNT_URL}/login?redirect_url=${window.location.href}`;
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLanguage("en");
      setLoading(false)
    }
  }, [edited, cookie]);

  useEffect(() => {
    const props = {
      user,
      cookie,
      handleUpdate,
      language,
      jwt,
    };

    setProfileProps(props);
  }, [user, cookie, language, jwt])

  useEffect(() => {
    if(profileProps && profileProps.user){
      setPropsLoaded(true);
    }
  }, [profileProps])


  const handleUpdate = (editedUser) => {
    setEdited(Date.now());
  };

  const resolveUserNotFound = () => {
    setUserNotFound(false);
  };
  const updateLang = (lang) => {
    let copyUser = { ...user };
    copyUser.default_language = lang;
    axios
      .post(`${API_URL}/account/profile`, copyUser, config)
      .then(() => {
        setLanguage(lang);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onAuthModalClose = () => {
    setDisplayAuth(false);
  };

  const onAuthLogin = () => {
    getToken();
    setDisplayAuth(false);
  };

  const onRedirect = (target, newTab = false) => {
    switch (target) {
      case "_self":
        setDisplayAuth(true);
        break;
      case "_blank":
        externalNavigate(
          `${ACCOUNT_URL}/authenticate?redirect_url=${window.location.href}`, newTab
        );
        break;
      default:
        externalNavigate(
          `${ACCOUNT_URL}/authenticate?redirect_url=${window.location.href}`, newTab
        );
        break;
    }
    // window.location.href = `${ACCOUNT_URL}/login?redirect_url=${window.location.href}`;
  };


  return (
    <>
      <LanguageContextProvider
        currentLanguage={language}
        languageObject={languages}
      >
        <ThemeProvider currentTheme={theme}>
          <RedirectContextProvider onRedirectFn={onRedirect}>
            <ContentContainer
              noUser={userNotFound}
              user={user}
              profileProps={profileProps}
              cookie={cookie}
              jwt={jwt}
              propsLoaded={propsLoaded}
              isAuthenticated={isAuthenticated}
              resolveUserNotFound={resolveUserNotFound}
              updateLang={updateLang}
              languages={languages}
              language={language}
              availableLanguages={availableLanguages}
            />
          </RedirectContextProvider>
        </ThemeProvider>
        {displayAuth && (
          <AuthModal
            onClose={onAuthModalClose}
            frameURL={`${ACCOUNT_URL}/authenticate`}
            onLogin={onAuthLogin}
          />
        )}
      </LanguageContextProvider>
    </>
  );
}

export default App;
