import React, { useEffect, useState, useRef } from "react";
import sectionStyles from "./style.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import { ImageCrop } from "@haia/haia-components";
import { generateRandomAvatar } from "../../utils/utils";
import { ProfileImage } from "@haia/haia-components";
import Loader from "../loader";

type AvatarData = {
  profile_pic?: string;
  display_name?: string;
  account_id?: string;
}

type AvatarType = "circle" | "square";

type AvatarSectionProps = {
  currentUser: User | AvatarData;
  // onCropCompletion?: (blob: Blob) => void;
  loading?: boolean;
  type?: AvatarType;
  onImageSelection?: (selectedImage?: string, key?: string) => void;
  imageKey?: string;
  selectedImage?: string;
  setSelectedImage: React.Dispatch<React.SetStateAction<string | undefined>>;
  backgroundColour?: string;
  initials?: boolean;
};

const AvatarSection = ({currentUser, loading = false, type = "circle", onImageSelection, imageKey, selectedImage, setSelectedImage, backgroundColour, initials = false}: AvatarSectionProps) => {

    const profilePictureInput = useRef<HTMLInputElement>(null);
    // const [selectedImage, setSelectedImage] = useState<string>()
    const [isDark, setIsDark] = useState(true);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.files){
             let profileFile = e.target.files[0];
             if (profileFile) {
               const reader = new FileReader();
               reader.addEventListener("load", () =>
                 setSelectedImage(reader.result?.toString() || "")
               );
               reader.readAsDataURL(profileFile);
              //  setShowCropModal(true);
             }
        }
    };

    useEffect(() => {
      if(selectedImage){
        if (onImageSelection) {
          onImageSelection(selectedImage, imageKey);
        }
      } else {
        if (profilePictureInput.current) {
            //@ts-ignore
            profilePictureInput.current.value = null;
        }
      }
    }, [selectedImage])

    // const cropCompletion = (blob: Blob) => {
    //   setShowCropModal(false);
    //   if(onCropCompletion){
    //      onCropCompletion(blob);
    //   }
    // }

    // const onCropModalClose = () => {
    //     setShowCropModal(false)
    //     setSelectedImage(undefined)
    //     if (profilePictureInput.current) {
    //         //@ts-ignore
    //         profilePictureInput.current.value = null;
    //     }
    // }
  


  return (
    <div className={sectionStyles["avatar-container"]}>
      <div className={sectionStyles["image-selector-container"]}>
        <div className={sectionStyles["profile-img-container"]}>
          <ProfileImage
            image={currentUser.profile_pic}
            size={"large"}
            type={type}
            defaultImage={
              "https://storage.googleapis.com/haia-live/org-avatar.png"
            }
          />
        </div>
        {!loading && (
          <label
            className={`${sectionStyles["avatar-label"]} ${
              sectionStyles[type]
            } ${isDark ? sectionStyles["dark"] : ""}`}
          >
            <FontAwesomeIcon
              icon={faCamera}
              className={sectionStyles["avatar-label-icon"]}
            />
            <p>Change</p>
            <input
              type="file"
              id="profile_picture"
              onChange={handleFileChange}
              ref={profilePictureInput}
              className={sectionStyles["hidden"]}
              accept={"image/*"}
            ></input>
          </label>
        )}
        {loading && (
          <span className={sectionStyles["loader-container"]}>
            <Loader />
          </span>
        )}
        {/* {showCropModal && (
          <ImageCrop
            selectedImage={selectedImage}
            onModalClose={onCropModalClose}
            onCropCompletion={cropCompletion}
          />
        )} */}
      </div>
      <div className={sectionStyles["avatar-preview"]}>
        <ProfileImage
          image={currentUser.profile_pic}
          size={"medium"}
          type={type}
          defaultImage={
            "https://storage.googleapis.com/haia-live/org-avatar.png"
          }
          backgroundColour={backgroundColour}
          displayName={initials ? currentUser.display_name : undefined}
          backgroundKey={initials ? currentUser.account_id : undefined}

        />
        <div className={sectionStyles["avatar-preview-text"]}>
          <p className={sectionStyles["user"]}>{currentUser.display_name}</p>
          <p className={sectionStyles["message"]}>Hello everyone!</p>
        </div>
      </div>
    </div>
  );
};

export default AvatarSection;
