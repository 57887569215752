import {
  HaiaCard,
  HaiaCardModal,
  HaiaLoader,
  Toaster,
  useLanguage,
  Warning,
} from "@haia/haia-components";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styles from "./userprofilepage.module.scss";
import {useGetUser} from '../../API/profile'
import { useConnections } from "../../API/connections";
import LeadContainer from "./LeadContainer";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHand, faHandWave } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { useSearchParams } from "react-router-dom";

//@ts-ignore
const { ORGANISATIONS_API_URL } = window["runConfig"] ? window["runConfig"] : "https://event.dev.haia.live";
//@ts-ignore
const { EVENT_URL } = window["runConfig"] ? window["runConfig"] : "https://event.dev.haia.live";

const STATUS_CHOICES: StatusChoice[] = [
  { label: "red", colour: "red" },
  { label: "amber", colour: "amber" },
  { label: "green", colour: "green" },
];

type UserProfilePageProps = {
  cookie: string;
  currentUser: API_Public_Profile;
};

const UserProfilePage = ({ cookie, currentUser }: UserProfilePageProps) => {
  const { getLanguageLabel } = useLanguage();

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const eventID = searchParams.get("event_id");

  const navigate = useNavigate();

  const {user, fetchUser, userLoading, userError} = useGetUser({cookie: cookie})

  const {requestConnection, disconnectConnection, acceptConnection, fetchUserConnections, userConnections, follow, userFollow, followError, followLoading, userConnectionsLoading, userConnectionsError, userConnectionActionError, userConnectionActionLoading} = useConnections({cookie: cookie})

  const [showModal, setShowModal] = useState(false)

  const [details, setDetails] = useState()
  const [isConnectable, setIsConnectable] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState(0);

  const [showToaster, setShowToaster] = useState(false)
  const [toasterIcon, setToasterIcon] = useState<IconProp>()
  const [toasterMessage, setToasterMessage] = useState<string>()
  const [toasterTheme, setToasterTheme] = useState<string>("green")

  useEffect(() => {
    if(id){
      fetchUser(id);
      fetchUserConnections();
    }
  }, [id])

  useEffect(() => {
    if(user){
      setDetails({
        //@ts-ignore
        id: user.account_id,
        title: user.display_name,
        subtitle: user.short_bio,
        //@ts-ignore
        image: user.profile_pic,
        ...(user.bio && {
          about: user.bio,
        }),
        //@ts-ignore
        contacts: {
          email: user.contact_email,
          website: user.website,
        },
        socials: {
          facebook: user.socials?.facebook_url,
          twitter: user.socials?.twitter_url,
          linkedIn: user.socials?.linkedin_url,
          instagram: user.socials?.instagram_url,
        },
        backgroundColor: user.haia_card_colour ? user.haia_card_colour : "#FFF",
        tags: user?.hobbies?.map((val) => {
          return val.value;
        }),
        //@ts-ignore
        size: "minimised",
      });
    }
  }, [user])

  const onViewClick = () => {
    setShowModal(true)
  }

  const onModalClose = () => {
    setShowModal(false)
  }

  useEffect(() => {
    if (user && currentUser) {
      if (user.account_id == currentUser.account_id) {
        setIsConnectable(false);
      } else {
        setIsConnectable(true);
      }
    }
  }, [user, currentUser]);

  useEffect(() => {
    if (userConnections && user && Array.isArray(userConnections)) {
      const filtered = userConnections.filter((c) => {
        return c.profile == user.account_id || c.owner == user.account_id;
      });
      if (filtered && filtered.length > 0) {
        if (filtered[0].accepted) {
          setConnectionStatus(2);
        } else {
          if (filtered[0].profile == currentUser.account_id) {
            setConnectionStatus(3);
          } else {
            setConnectionStatus(1);
          }
        }
      } else {
        setConnectionStatus(0);
      }
    } else {
      setConnectionStatus(0);
    }
  }, [userConnections, user, currentUser]);

  useMemo(() => {
    if (userConnectionActionLoading === false && !userConnectionActionError && !showToaster) {
      setShowToaster(true);
    }
  }, [userConnectionActionLoading, userConnectionActionError])

  useMemo(() => {
    if(followLoading === false && !followError){
      setShowToaster(true);
    }
  }, [followError, followLoading])

  const onConnect = (status: number) => {
    if (user?.account_id) {
      switch (status) {
        case 0:
          setToasterIcon(faHandWave)
          setToasterMessage(getLanguageLabel('connection_request_sent'))
          setToasterTheme("green")
          requestConnection(user.account_id);
          break;
        case 2:
          setToasterIcon(faHand);
          setToasterMessage(getLanguageLabel("removed_request"));
          setToasterTheme("red");
          disconnectConnection(user.account_id);
          break;
        case 3:
          setToasterTheme("green");
          setToasterMessage(getLanguageLabel("accepted_request"));
          acceptConnection(user.account_id);
      }
    }
  }

  const onFollow = (id: string | number, data: API_Follow_Additional_Data, event: number) => {
    if(data && id){
      setToasterIcon(faCheckCircle);
      setToasterMessage(getLanguageLabel("successfully_created_lead"));
      setToasterTheme("green");
      if(user && user.account_id){
        userFollow(id, user.account_id, event, data);
      }
    }
  }

  const closeToaster = () => {
    setShowToaster(false);
  }

  return (
    <div className={styles["content"]}>
      {showToaster && (
        <Toaster theme={toasterTheme} duration={2000} onClose={closeToaster}>
          {toasterIcon && (
            <FontAwesomeIcon icon={toasterIcon}/>
          )}
          {toasterMessage && (
            <p>{toasterMessage}</p>
          )}
        </Toaster>
      )}
      <main>
        {(userError || (userLoading === false && !user)) && (
          <Warning>
            {getLanguageLabel("error_loading_data_try_again_later")}
          </Warning>
        )}
        {userLoading && <HaiaLoader />}
        {user && details && (
          <HaiaCard
            cardDetails={details}
            hideCard={() => {}}
            labels={{
              connect: getLanguageLabel("connect"),
              pending: getLanguageLabel("pending"),
              disconnect: getLanguageLabel("disconnect"),
              accept: getLanguageLabel("accept"),
              connected: getLanguageLabel("connected"),
              cancel: getLanguageLabel("cancel"),
              view: getLanguageLabel("view"),
            }}
            dynamic={false}
            onViewClick={onViewClick}
          />
        )}
        {user && currentUser && currentUser.account_id !== user.account_id && (
          <LeadContainer
            choices={STATUS_CHOICES}
            cookie={cookie}
            currentUser={currentUser}
            onConnect={onConnect}
            onFollow={onFollow}
            connectionStatus={connectionStatus}
            targetUser={user}
            eventID={eventID}
          />
        )}
      </main>
      {showModal && details && (
        <HaiaCardModal
          details={details}
          closeModal={onModalClose}
          closeOnBlur={false}
        />
      )}
    </div>
  );
};

export default UserProfilePage;
